import {useNavigate} from 'react-router-dom'
import ButtonPrimary from './ButtonPrimary'
import PropTypes from 'prop-types'
import CountDown from 'count-down-react'

const Benefits = ({className = ''}) => {
	const navigate = useNavigate()
	const lastDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)

	const renderer = ({days, hours, minutes, seconds}) => {
		return (
			<div className="flex gap-2 items-center mt-4">
				<div className="text-sm text-red-600 font-bold animate-pulse whitespace-nowrap">Limited Time Offer Expires In:</div>
				<div className="flex gap-1">
					<span className="bg-brand-400 text-white px-2 py-1 rounded w-[3rem] text-center">{days}d</span>
					<span className="bg-brand-400 text-white px-2 py-1 rounded w-[3rem] text-center">{hours}h</span>
					<span className="bg-brand-400 text-white px-2 py-1 rounded w-[3rem] text-center">{minutes}m</span>
					<span className="bg-brand-400 text-white px-2 py-1 rounded w-[3rem] text-center">{seconds}s</span>
				</div>
			</div>
		)
	}

	return (
		<section className={`self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-12 box-border max-w-full shrink-0 text-left text-63xl font-plus-jakarta-sans mq450:pb-5 mq450:box-border mq1150:pb-[31px] mq1150:box-border ${className}`}>
			<div className="h-[750px] lg:h-[614px] flex-1 bg-gradient-to-br from-[#f8f9fe] via-[#f3f4fd] to-[#eef0fc] overflow-hidden flex flex-col lg:flex-row items-start justify-start py-[60px] pr-0 pl-[2rem] lg:pl-[140px] md:pl-[110px] box-border gap-[107px] max-w-full z-[0] mq450:gap-[27px] mq450:pl-5 mq450:box-border mq800:gap-[53px] mq800:pl-[70px] mq800:pt-[26px] mq800:pb-[26px] mq800:box-border mq1150:pt-10 mq1150:pb-10 mq1150:box-border mq1350:pt-[61px] mq1350:pb-[61px] mq1350:box-border">
				<div className="w-full lg:w-[568px] flex flex-col items-start justify-start gap-[24px] shrink-0 mq1150:max-w-full">
					<div className="flex items-center gap-2 mb-2">
						<span className="bg-green-500 text-white px-3 py-1 rounded-full text-sm font-bold animate-pulse">SPECIAL OFFER</span>
						<span className="text-gray-600 text-sm">Save 50% Today!</span>
					</div>
					<h1 className="m-0 text-[2.5em] lg:text-[3em] self-stretch relative tracking-[-1px] leading-[3.5rem] lg:leading-[4rem] font-bold mq450:text-6xl mq450:leading-[42px] mq800:text-22xl mq800:leading-[60px]">
						<span className="text-gray-900">Reach PMF faster with</span>
						<br />
						<span className="text-transparent !bg-clip-text [background:linear-gradient(98.81deg,_#825bf8,_#5f44f6_44.79%,_#5caff9_96.35%)] border-1 ml-[-8px] p-2 rounded-full  [-webkit-background-clip:text] [-webkit-text-fill-color:transparent]">
							Derisking Intelligence
						</span>
					</h1>

					<div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2 box-border max-w-full">
						<div className="flex-1 text-xl leading-[150%] font-medium text-gray-600">Join 10,000+ leaders accelerating innovation with data-driven risk management. Start your free trial today.</div>
					</div>

					<div className="flex flex-col gap-2">
						<ButtonPrimary className="text-white bg-[#6145F7] hover:!bg-[#422db6] rounded-3xl text-lg px-8 py-4 shadow-lg transform hover:scale-105 transition-all" onClick={() => navigate('/signup')}>
							Start Free Trial - No Credit Card Required →
						</ButtonPrimary>

						<CountDown date={lastDayOfMonth} renderer={renderer} />
						<div className="flex flex-col items-start justify-start py-4 gap-[24px] text-sm">
							<div className="flex items-center gap-4">
								<div className="relative inline-block">
									<iframe
										src="https://embed-v2.testimonial.to/badge/derisky-ai?starColor=825bf8&amp;fontColor=000000&amp;fontFamily=Plus+Jakarta+Sans&amp;reviewTerm=review&amp;fontSize=16&amp;reviewTermPlural=reviews&amp;alignment=left&amp;customTemplate=%7Baverage%7D+stars+from+8+%7BreviewTerm%7D"
										className="border-0 w-full h-auto pointer-events-none"
										scrolling="no"></iframe>
									<a href="http://testimonial.to/derisky/all" target="_blank" rel="noopener noreferrer" className="absolute top-0 left-0 w-full h-full z-10 block"></a>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="flex flex-col lg:flex-row mt-12 justify-center gap-8 w-full">
					<img className="w-full lg:w-1/2 max-w-[964.9px]" alt="Innovation Dashboard" src="/Union3.svg" />
					<div className="w-full lg:w-1/3 flex flex-col items-start justify-start gap-8">
						<div className="transform hover:scale-105 transition-all duration-300 bg-white p-6 rounded-xl shadow-lg border-l-4 border-green-500">
							<div className="flex items-center gap-3">
								<svg className="w-8 h-8 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z"></path>
								</svg>
								<div className="text-[2.5rem] font-bold text-green-600">5X</div>
							</div>
							<div className="text-lg font-semibold text-gray-700">Faster Time-to-Market</div>
						</div>
						<div className="transform hover:scale-105 transition-all duration-300 bg-white p-6 rounded-xl shadow-lg border-l-4 border-purple-500">
							<div className="flex items-center gap-3">
								<svg className="w-8 h-8 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
								</svg>
								<div className="text-[2.5rem] font-bold text-purple-600">15X</div>
							</div>
							<div className="text-lg font-semibold text-gray-700">ROI Over 3 Years</div>
						</div>
						<div className="transform hover:scale-105 transition-all duration-300 bg-white p-6 rounded-xl shadow-lg border-l-4 border-blue-500">
							<div className="flex items-center gap-3">
								<svg className="w-8 h-8 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 8h6m-5 0a3 3 0 110 6H9l3 3m-3-6h6m6 1a9 9 0 11-18 0 9 9 0 0118 0z"></path>
								</svg>
								<div className="text-[2.5rem] font-bold text-blue-600">80%</div>
							</div>
							<div className="text-lg font-semibold text-gray-700">Cost Savings</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

Benefits.propTypes = {
	className: PropTypes.string
}

export default Benefits
