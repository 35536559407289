import React, { Fragment, useEffect, useState, useContext } from 'react'
import { Grid, SubNavbar, Form, Card, Chart, useLocation, Loader, MultiSelect, Select, Button, ViewContext } from 'components/lib'
import { DashboardCard, LogoCard } from 'components/card/projectcard'
import Spacer from 'components/spacer/spacer'
import axios from 'axios'
import moment from 'moment'
import Canvas from 'components/Canvas/Canvas'
import FocusComponent from './megaWizard'

export function SingleProject(props) {
	const router = useLocation()
	const [product, setProduct] = useState()
	let id = router.pathname.replace('/singleproject/', '')
	const [loading, setLoading] = useState(false)
	const [dashBoardStats, setDashboardStats] = useState([])
	const [chartData, setChartData] = useState([])
	const [quarters, setQuarters] = useState()
	const user = JSON.parse(localStorage.getItem('user'))
	const context = useContext(ViewContext)
	const [openWizard, setOpenWizard] = useState(false)


	function getQuarter(d) {
		d = d || new Date();
		var m = Math.floor(d.getMonth() / 3) + 2;
		m -= m > 4 ? 4 : 0;
		var y = d.getFullYear() + (m == 1 ? 1 : 0);
		return [y, m].join('Q');
	}
	let yearly = [
		{ value: 'This Year', label: 'This Year' },
		{ value: 'This Year - 1', label: 'This Year - 1' },
		{ value: 'This Year - 2', label: 'This Year - 2' }
	]
	let monthly = [
		{ value: 'This Month', label: 'This Month' },
		{ value: 'This Month - 1', label: 'This Month - 1' },
		{ value: 'This Month - 2', label: 'This Month - 2' },
		{ value: 'This Month - 3', label: 'This Month - 3' }
	]
	let quarterly = [
		{ value: 'This Quarterly', label: getQuarter() },
		{ value: 'This Quarterly - 1', label: getQuarter() },
		{ value: 'This Quarterly - 2', label: 'This Quarterly - 2' },
		{ value: 'This Quarterly - 3', label: 'This Quarterly - 3' }
	]

	const [businessCase, setBusinessCase] = useState(yearly)
	const [optionSelect, setSelectedOption] = useState('Yearly')
	const [selectedOptionValue, setSelectedOptionValue] = useState(optionSelect == 'Monthly ' ? monthly[0].label : optionSelect == 'Quarterly ' ? quarterly[0].label : yearly[0].label)

	useEffect(() => {
		if (id) {
			getAllData()
		}
	}, [])

	useEffect(() => {
		if (optionSelect == 'Monthly') {
			setBusinessCase(monthly)
			setSelectedOptionValue(monthly[0].label)
		}

		if (optionSelect == 'Yearly') {
			setBusinessCase(yearly)
			setSelectedOptionValue(yearly[0].label)
		}
		if (optionSelect == 'Quarterly') {
			setBusinessCase(quarterly)
			setSelectedOptionValue(quarterly[0].label)
		}
	}, [optionSelect])

	useEffect(() => {
		console.log('selectedOptionValue', selectedOptionValue)
		if (optionSelect) {
			getAllHypothesis(optionSelect)
		}
	}, [optionSelect])

	const getAllData = async () => {
		await getProduct()
		await getAllHypothesis(optionSelect)
	}

	const getProduct = async () => {
		if (id) {
			await axios({
				method: 'get',
				url: `/api/product/${id}`
			})
				.then((res) => {
					if (res.data) {
						setProduct(res.data.data)
					}
				})
				.catch((err) => {
					console.log('err', err)
				})
		}
	}

	// all_filter_hypothesis
	const getAllHypothesis = async (type) => {
		setLoading(true)
		await axios({
			method: 'post',
			url: `/api/dasboard-stats/${id}`,
			data: { query: type }
		})
			.then((res) => {
				setLoading(false)
				if (res.data) {
					setDashboardStats(res.data.data)
					let chartStatData = []
					let risk = []
					let riskReduction = []
					let experimentCost = []
					res.data?.data?.map((e) => {
						if (e?.chart_stats) {
							risk.push(...e?.chart_stats?.risk)
							riskReduction.push(...e?.chart_stats?.riskReduction)
							experimentCost.push(...e?.chart_stats?.experimentCosts)
							// chartStatData.push(...e?.chart_stats)
						}
					})
					setQuarters(res.data?.data[1])
					setChartData({ risk: risk, experimentCost, riskReduction })
				}
			})
			.catch((err) => {
				setLoading(false)
			})
	}

	const insertData = async () => {
		setLoading(true)
		await axios({
			method: 'post',
			url: `/api/dasboard_stats/${id}`
		}).then(re => {
			setLoading(false)
			getAllHypothesis(optionSelect)
		}).catch(e => {
			setLoading(false); if (e?.response?.data?.message) {
				context.notification.show(e?.response?.data?.message, 'error', true)
			}
		})

	}

	const deleteData = async () => {
		setLoading(true)
		await axios({
			method: 'delete',
			url: `/api/dasboard_stats/${id}`
		}).then(re => {
			if (re?.data) {
				setLoading(false)
				getAllHypothesis(optionSelect)
			} else {
				setLoading(false)
			}
		}).catch(err => setLoading(false))
	}

	let datas = quarters?.map((item, i) => `${moment(item?.start).format('MM-YYYY')} / ${moment(item?.end).format('MM-YYYY')}`)
	console.log('datas', datas)
	let data = {
		labels: quarters?.map((item, i) => `${moment(item?.start).format('MM-YYYY')} / ${moment(item?.end).format('MM-YYYY')}`),
		// [

		// `Q1 ${moment().format('YYYY')}`,
		// `Q2 ${moment().format('YYYY')}`,
		// `Q3 ${moment().format('YYYY')}`,
		// `Q4 ${moment().format('YYYY')}`,
		// `Q5 ${moment().format('YYYY')}`,
		// `Q6 ${moment().format('YYYY')}`,
		// `Q7 ${moment().format('YYYY')}`,
		// `Q8 ${moment().format('YYYY')}`,
		// `Q10 ${moment().format('YYYY')}`
		// ],
		datasets: [
			{
				label: 'Risk',
				backgroundColor: '',
				borderColor: 'red',
				data: chartData?.risk
				// [
				// 	1500, 1200, 540
				// 	// 3520, 2510, 3652, 4555, 7850, 8850, 4000, 5000, 4520, 4457, 9200, 8750, 9500, 10000, 11010, 11432, 9850, 9000, 5000, 5240, 3520, 2510, 3652, 4555, 7850, 8850, 4000, 5000, 4520, 4457, 9200, 8750, 9500, 10000,
				// 	// 11010, 11432, 9850
				// ]
			},
			{
				label: 'Risk Reduction',
				backgroundColor: 'red',
				borderColor: 'green',
				data: chartData?.riskReduction
				//  [
				// 	900, 500, 700
				// 	//  3500, 3600, 8060, 9120, 8900, 9300, 10010, 9500, 6300, 7200, 3600, 4600, 5300, 5500, 6900, 5800, 4900, 3000, 4000, 6000, 3500, 3600, 8060, 9120, 8900, 9300, 10010, 9500, 6300, 7200, 3600, 4600, 5300, 5500, 6900,
				// 	// 5800, 4900
				// ]
			},
			{
				label: 'Costs',
				backgroundColor: 'blue',
				borderColor: 'blue',
				data: chartData?.experimentCost
				//  [
				// 	1000, 600, 500
				// 	//  300, 3600, 8060, 9120, 8900, 9300, 10010, 9500, 6300, 7200, 3600, 4600, 5300, 5500, 6900, 5800, 4900, 3000, 4000, 6000, 3500, 3600, 8060, 9120, 8900, 9300, 10010, 9500, 6300, 7200, 3600, 4600, 5300, 5500, 6900,
				// 	// 5800, 4900
				// ]
			}
		]
	}

	function roundNumber(labelValue) {
		// Nine Zeroes for Billions
		return Math.abs(Number(labelValue)) >= 1.0e9
			? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + 'B'
			: // Six Zeroes for Millions
			Math.abs(Number(labelValue)) >= 1.0e6
				? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + 'M'
				: // Three Zeroes for Thousands
				Math.abs(Number(labelValue)) >= 1.0e3
					? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + 'K'
					: Math.abs(Number(labelValue))
	}

	return (
		<Fragment>

			{product ? <LogoCard title={product?.name} subtitle={product?.description} logo={product?.logo} wizardClick={() => setOpenWizard(!openWizard)} /> : ''}
			<Spacer height="h-[1.188rem]" />
			<SubNavbar active="dashboard" id={id} />
			{loading && <Loader className='margin-auto' />}

			<Spacer height="h-[1.125rem] flex" />
			{openWizard ? <FocusComponent /> : <>

				{product?.isTeamLead && false && <div className="flex justify-end ">
					<Button
						className="inline-block rounded bg-red-500 px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] mb-2 mr-2"
						text="Delete Data"
						color="blue"
						action={() => deleteData()}
						rounded
					/>
					<Button
						className="inline-block rounded bg-primary px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] mb-2"
						text="Insert Data"
						color="blue"
						action={() => insertData()}
						rounded
					/>
				</div>}

				<Select
					className='flex bg-white rounded-md'
					label="Time perspective"
					options={[
						{ value: 'Yearly', label: 'Years' },
						{ value: 'Quarterly', label: 'Quarters' },
						{ value: 'Monthly', label: 'Months' }
					]}
					default={optionSelect}
					name={'Quarterly option'}
					// valid={caseId !== '' && isToggle ? true : isToggle == false ? true : false}
					onChange={(name, value, valid) => {
						setSelectedOption(value)
					}}
				/>

				<Card>
					<Chart data={data} type="bar" />
				</Card>

				<Grid cols={3}>

					<DashboardCard title={dashBoardStats[0]?.projectRisk ? dashBoardStats[0]?.projectRisk?.toFixed(2) + ' %' : 0 + ' %'} subtitle="Project Risk Level" component="large" />
					<DashboardCard title={dashBoardStats[0]?.experiment_costs ? roundNumber(dashBoardStats[0]?.experiment_costs) : 0} subtitle="Spent on experiments" component="large" />
					<DashboardCard title={dashBoardStats[0]?.riskReduction ? dashBoardStats[0]?.riskReduction?.toFixed(2) + ' %' : 0 + ' %'} subtitle="Overall Risk Reduction" component="large" />

					<DashboardCard title={dashBoardStats[0]?.cost_structure ? roundNumber(dashBoardStats[0]?.cost_structure) : 0} subtitle="Cost Structure" component="large" />
					<DashboardCard title={dashBoardStats[0]?.killed_hypothesis ? dashBoardStats[0]?.killed_hypothesis : 0} subtitle="Killed Hypothesis" component="large" />
					<DashboardCard title={dashBoardStats[0]?.finished_hypothesis ? dashBoardStats[0]?.finished_hypothesis : 0} subtitle="Finished Experiments" component="large" />
					<DashboardCard title={dashBoardStats[0]?.insightful_hypothesis ? dashBoardStats[0]?.insightful_hypothesis : 0} subtitle="Insightful Experiments" component="large" />
					<DashboardCard title={dashBoardStats[0]?.unInsightful_hypothesis ? dashBoardStats[0]?.unInsightful_hypothesis : 0} subtitle="Uninsightful Experiments" component="large" />
					<DashboardCard title={dashBoardStats[0]?.ebitida ? roundNumber(dashBoardStats[0]?.ebitida) : 0} subtitle="EBITDA" component="large" />
				</Grid>
			</>}
		</Fragment>
	)
}
