import ButtonPrimary from './ButtonPrimary'
import FeatureText1 from './FeatureText1'
import FeatureText from './FeatureText'
import PropTypes from 'prop-types'
import {useNavigate} from 'react-router-dom'
import {features} from 'features'
import {useState, useRef, useEffect} from 'react'
import {Badge, Button, Grid} from 'components/lib'

const FrameComponent1 = ({className = ''}) => {
	const navigate = useNavigate()
	const [selectedItem, selectItem] = useState(null)
	const videoRef = useRef(null)

	const onSelectItem = function (index) {
		selectItem(features[index])
	}

	useEffect(() => {
		if (selectedItem?.video && videoRef.current) {
			videoRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
		}
	}, [selectedItem])

	return (
		<section className={`relative overflow-visible bg-gradient-to-b from-[#F7F7F7] to-[#F2F4F7] py-24 w-full ${className}`}>
			<div className={`w-full px-6 sm:px-8 lg:px-12 ${selectedItem?.video ? 'min-h-[87rem]' : 'min-h-[980px]'}`}>
				{/* Elegant decorative background */}
				<div className="hidden lg:block absolute top-0 right-0 -translate-y-1/4 translate-x-1/4">
					<img className="w-[968px] opacity-40 animate-pulse" alt="" src="/union-1.svg" />
				</div>

				<div className="relative grid lg:grid-cols-2 gap-20 items-start">
					{/* Left Column - Elegant Content */}
					<div className="space-y-14">
						{!selectedItem?.video && (
							<div className="space-y-10">
								<h1 className="text-5xl lg:text-7xl font-light leading-tight bg-gradient-to-r from-gray-900 via-gray-700 to-gray-500 bg-clip-text text-transparent">
									How Does Derisky.ai Work
								</h1>
								<p className="text-xl text-gray-600 leading-relaxed max-w-2xl font-light tracking-wide">
									Implement actionable innovation metrics. Measure & visualize your impact, derisk with smart experiments, make data-driven portfolio decisions.
								</p>
							</div>
						)}

						<div className="flex gap-6">
							<ButtonPrimary 
								className="rounded-full bg-gradient-to-r from-[#6145F7] via-[#5038c5] to-[#422db6] hover:from-[#422db6] hover:via-[#2a1c75] hover:to-[#1a1147] text-white shadow-2xl hover:shadow-[0_10px_40px_rgba(97,69,247,0.4)] transform hover:-translate-y-1 transition-all duration-300" 
								onClick={() => navigate('/signup')} 
							/>
							{selectedItem?.video && (
								<button
									className="flex items-center gap-3 px-8 py-4 rounded-full bg-gray-100 hover:bg-gray-200 text-gray-700 font-light tracking-wide shadow-2xl hover:shadow-[0_10px_40px_rgba(0,0,0,0.1)] transform hover:-translate-y-1 transition-all duration-300"
									onClick={() => selectItem(null)}
								>
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
										<path strokeLinecap="round" strokeLinejoin="round" d="M5.25 7.5A2.25 2.25 0 0 1 7.5 5.25h9a2.25 2.25 0 0 1 2.25 2.25v9a2.25 2.25 0 0 1-2.25 2.25h-9a2.25 2.25 0 0 1-2.25-2.25v-9Z" />
									</svg>
									Stop
								</button>
							)}
						</div>

						{/* Elegant Video Player */}
						{selectedItem?.video && (
							<div ref={videoRef} className="relative backdrop-blur-2xl bg-white/95 rounded-[2rem] overflow-hidden shadow-[0_30px_70px_rgba(97,69,247,0.25)] border border-white/60 p-10 max-w-2xl transform hover:scale-[1.02] transition-transform duration-500">
								<div className="absolute inset-0 bg-gradient-to-br from-[#6145F7]/10 to-transparent"></div>
								<video 
									className="w-full rounded-2xl shadow-2xl max-h-[700px] object-contain relative z-10"
									autoPlay 
									src={selectedItem.video} 
									aria-description={selectedItem.title + ': ' + selectedItem.description} 
								/>
								<div className="relative z-10 mt-8 space-y-4">
									<h3 className="text-3xl font-light text-gray-800">{selectedItem.title}</h3>
									<p className="text-gray-600 leading-relaxed font-light tracking-wide">{selectedItem.description}</p>
								</div>
							</div>
						)}
					</div>

					{/* Right Column - Elegant Feature List */}
					<div className={`space-y-6 overflow-visible pr-8 styled-scrollbar h-full`}>
						{features && features.map((res, i) => (
							<div
								key={i}
								className={`rounded-[2rem] py-6 px-8 transition-all duration-500 transform hover:-translate-y-2 hover:scale-[1.02] flex items-start gap-10 backdrop-blur-xl ${
									selectedItem?.video == res?.video 
										? 'bg-gradient-to-r from-[#6145F7] via-[#5038c5] to-[#422db6] text-white shadow-[0_20px_50px_rgba(97,69,247,0.3)]' 
										: 'bg-white/90 hover:bg-gradient-to-r hover:from-[#6145F7] hover:via-[#5038c5] hover:to-[#422db6] group hover:text-white border border-white/60'
								}`}
							>
								<div className={`rounded-2xl shadow-2xl transition-all duration-500 p-5 flex-shrink-0 ${
									selectedItem?.video == res?.video ? 'bg-white/95 scale-110 rotate-6' : 'bg-white lg:bg-[#F7F7F7]'
								} hover:scale-110 hover:rotate-6`}>
									<img src={res.icon} alt="" className="w-10 h-10" />
								</div>

								<div className="flex-1">
									<h3 className="font-light text-2xl tracking-wide">{res.title}</h3>
									<p className={`mt-3 transition-colors duration-500 leading-relaxed tracking-wide ${
										selectedItem?.video == res?.video ? 'text-white/95' : 'text-gray-600 group-hover:text-white/95'
									}`}>
										{res.description}
									</p>
									<button 
										onClick={() => onSelectItem(i)}
										className={`mt-5 backdrop-blur-xl p-4 ${
											selectedItem?.video == res?.video ? 'bg-white/20' : 'bg-gray-50'
										} hover:bg-white/30 rounded-full transition-all duration-300 shadow-xl hover:shadow-2xl transform hover:scale-110`}
									>
										<img src="/play.svg" alt="Play" className={`w-6 h-6 ${
											selectedItem?.video == res?.video ? '' : 'invert'
										} group-hover:invert-0`} />
									</button>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</section>
	)
}

FrameComponent1.propTypes = {
	className: PropTypes.string
}

export default FrameComponent1
