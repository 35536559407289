import React, { useState, useEffect } from 'react';
import axios from 'axios';

const targetUrl = 'https://lab.derisky.ai/wp-json/wp/v2/posts/';
const proxyUrl = 'https://corsproxy.io/?url='
const dividerColor = '#6145f7'; // Turquoise color variable

const RSSIntegration = () => {
  const [articles, setArticles] = useState([]);
  const [showAllArticles, setShowAllArticles] = useState(false);
  const carouselSize = 4; // Number of items to show
  const moreArticlesInitialSize = 12; // Initial number of more articles to show

  useEffect(() => {
    const fetchRSS = async () => {
      try {
          let page = 1;
          let allPosts = [];
          let hasMore = true;
        
          while (hasMore) {
            const response = await axios.get(`${proxyUrl}${targetUrl}?page=${page}&per_page=10`, {
              headers: {
                  'Content-Type': 'application/json'
              }
            });
            const posts = response.data;
        
            if (posts && posts.length > 0) {
              // Check if we've already seen these posts
              const newPosts = posts.filter(post => 
                !allPosts.some(existingPost => existingPost.id === post.id)
              );
              
              if (newPosts.length > 0) {
                allPosts = allPosts.concat(newPosts);
                setArticles(allPosts);
                page++;
              } else {
                hasMore = false;
              }
            } else {
              hasMore = false;
            }
          }
        
          console.log(allPosts);        
      } catch (error) {
        console.error('Error fetching RSS feed:', error);
      }
    };

    fetchRSS();
  }, []);

  const carouselArticles = articles.slice(0, carouselSize);
  const remainingArticles = articles.slice(carouselSize);
  const displayedRemainingArticles = showAllArticles ? remainingArticles : remainingArticles.slice(0, moreArticlesInitialSize);

  return (
    <div className="w-full py-16 bg-gradient-to-b from-gray-50 to-white">
      <div className="max-w-7xl mx-auto px-4">
        <h2 className="text-4xl font-bold text-gray-900 mb-12 text-center">
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-indigo-600">
            Latest Articles from Derisky Lab
          </span>
        </h2>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {carouselArticles.map((article, index) => (
            <a
              key={index}
              href={article.link}
              target="_blank"
              className="group bg-white rounded-xl shadow-sm hover:shadow-2xl transform hover:-translate-y-1 transition-all duration-300"
            >
              <div className="relative overflow-hidden">
                <img 
                  src={article.featured_media_src_url} 
                  alt={article.title}
                  className="w-full h-48 object-cover transform group-hover:scale-105 transition-transform duration-500"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/30 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
              </div>
              <div style={{height: '4px', backgroundColor: dividerColor}} />
              <div className="p-6">
                <div className="flex items-center space-x-2 mb-3">
                  <svg className="w-4 h-4 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                  </svg>
                  <span className="text-sm text-gray-500 font-medium">
                    {(() => {
                      const date = new Date(article.date);
                      const now = new Date();
                      const diffTime = Math.abs(now - date);
                      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
                      const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
                      const diffMinutes = Math.floor(diffTime / (1000 * 60));
                      
                      if (diffDays > 0) return `${diffDays} day${diffDays > 1 ? 's' : ''} ago`;
                      if (diffHours > 0) return `${diffHours} hour${diffHours > 1 ? 's' : ''} ago`;
                      if (diffMinutes > 0) return `${diffMinutes} minute${diffMinutes > 1 ? 's' : ''} ago`;
                      return 'Just now';
                    })()}
                  </span>
                </div>
                <h3 className="text-lg font-semibold text-gray-900 group-hover:text-blue-600 transition-colors duration-200 line-clamp-3">
                  {new DOMParser().parseFromString(article.title.rendered, "text/html").body.textContent}
                </h3>
              </div>
            </a>
          ))}
        </div>
        {remainingArticles.length > 0 && (
          <div className="mt-12">
            <div className="flex items-center justify-between mb-6">
              <h3 className="text-xl font-bold text-gray-900">More Articles</h3>
              <div className="h-0.5 flex-1 bg-gradient-to-r from-gray-200 to-transparent ml-4"></div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              {displayedRemainingArticles.map((article, index) => (
                <a
                  key={index + carouselSize}
                  href={article.link}
                  target="_blank"
                  className="group bg-white rounded-lg shadow-sm border border-gray-200 hover:shadow-lg transition-all duration-300 flex flex-row items-center h-12 overflow-hidden"
                >
                  <div className="relative w-12 h-12 flex-shrink-0">
                    <img 
                      src={article.featured_media_src_url} 
                      alt={article.title}
                      className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-500"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/30 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                  </div>
                  <div className="px-3 flex-1">
                    <span className="text-xs text-gray-900 group-hover:text-blue-600 transition-colors duration-200 line-clamp-3">
                      {new DOMParser().parseFromString(article.title.rendered, "text/html").body.textContent}
                    </span>
                  </div>
                </a>
              ))}
            </div>
            {remainingArticles.length > moreArticlesInitialSize && !showAllArticles && (
              <div className="flex justify-center mt-8">
                <button
                  onClick={() => setShowAllArticles(true)}
                  className="px-6 py-2 text-white rounded-lg hover:opacity-80 transition-colors duration-200 flex items-center gap-2" style={{backgroundColor: dividerColor}}
                >
                  More Articles
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 14l-7 7m0 0l-7-7m7 7V3" />
                  </svg>
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default RSSIntegration;
