import axios from 'axios';
import Items from 'components/items/items';
import { Card, Form, Grid, Icon, Loader, SubNavbar, Tooltip, ViewContext, useLocation } from 'components/lib';
import Spacer from 'components/spacer/spacer';
import React, { Fragment, useEffect, useState } from 'react';
import { LogoCard } from "../../components/card/projectcard";
import { Button } from "reactstrap";
import FocusComponent from './megaWizard';
import { useContext } from 'react';

export const Documents = () => {
	const [documents, setDocuments] = useState([]);
	const [vectorizedDocuments, setVectorizedDocuments] = useState([]);
	const [product, setProduct] = useState(null);
	const router = useLocation();
	const [loading, setLoading] = useState(false);
	const product_id = router.pathname.replace('/documents/', '');
	const [showDocument, setShowDocument] = useState(false);
	const [showLanding, setLanding] = useState(false);
	const [selectedDocumentIds, setSelectedDocumentIds] = useState([]);
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [landingPage, setLandingPage] = useState('');
	const [previews, setPreviews] = useState([]);
	const [openWizard, setOpenWizard] = useState(false)
	const context = useContext(ViewContext)

	useEffect(() => {
		const fetchData = async () => {
			await getProduct();
			await getDocuments();
			await getVectorizedDocuments();
		};
		fetchData();
	}, []);


	const getVectorizedDocuments = async () => {
		if (product_id) {
			setLoading(true);
			try {
				const res = await axios.get(`/api/document/vectorized/${product_id}`);
				if (res.data) {
					if (res.data?.data?.length) {
						setVectorizedDocuments(res.data?.data);
					}

				}
			} catch (err) {
				console.error('Error fetching documents', err);
			} finally {
				setLoading(false);
			}
		}
	};
	const getProduct = async () => {
		if (product_id) {
			setLoading(true);
			try {
				const res = await axios.get(`/api/product/${product_id}`);
				if (res.data) {
					setProduct(res.data.data);
				}
			} catch (err) {
				console.error('Error fetching product', err);
			} finally {
				setLoading(false);
			}
		}
	};

	const getDocuments = async () => {
		if (product_id) {
			setLoading(true);
			try {
				const res = await axios.get(`/api/document/all/${product_id}`);
				if (res.data) {

					if (res.data?.data?.length)
						setDocuments(res.data?.data);
				}
			} catch (err) {
				console.error('Error fetching documents', err);
			} finally {
				setLoading(false);
			}
		}
	};

	const deleteDocument = async (document_id) => {
		try {
			setLanding(true)
			await axios.delete(`/api/document/${document_id}/${product_id}`).then((res) => {
				console.log('=++++===', res.data)
				setLanding(false)
				setDocuments(documents.filter(doc => doc.id !== document_id));
				context.notification.show(res?.data?.message, 'success', true)
			}).catch(err => {
				context.notification.show('Failed to delete document', 'error', true)
				setLanding(false)
			});


		} catch (err) {
			setLanding(false)
			context.notification.show('Error deleting document', 'error', true)
			console.error('Error deleting document', err);
		}
	};

	const handleFileChange = (event) => {
		const files = Array.from(event.target.files);
		setSelectedFiles(files);
		const filePreviews = files.map(file => ({
			name: file.name,
			url: URL.createObjectURL(file)
		}));
		setPreviews(filePreviews);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		if (selectedFiles.length === 0) {
			alert('Please select documents to upload.');
			return;
		}

		const formData = new FormData();
		selectedFiles.forEach(file => {
			formData.append('documents', file);
		});

		setLoading(true);
		try {
			const res = await axios.post(`/api/document/${product_id}`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}).then((res) => {
				if (res.data) {
					getDocuments()
					setSelectedFiles([]);
					setPreviews([]);
					setLoading(false)
					setShowDocument(false);
					context.notification.show('Files uploaded successfully!', 'success', true)

				}
			}).catch(err => {
				context.notification.show('Error uploadind document!', 'error', true)
				setLoading(false)
			});


		} catch (err) {
			setLoading(false)
			context.notification.show('Error uploading files.', 'error', true)
			console.error('Error uploading files', err);
			alert('Error uploading files.');
		} finally {
			setLoading(false);
		}
	};

	function isValidURL(url) {
		const regex = new RegExp('^(https?:\\/\\/)?' + // protocol
			'((([a-zA-Z0-9\\-]+\\.)+[a-zA-Z]{2,})|' + // domain name and extension
			'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-zA-Z0-9@:%_\\+.~#?&//=]*)?' + // port and path
			'(\\?[;&a-zA-Z0-9%_\\+.~#?&//=]*)?' + // query string
			'(\\#[-a-zA-Z0-9_]*)?$', 'i'); // fragment locator
		return regex.test(url);
	}

	const handleUpdate = () => {
		// Update logic here
	};

	const handleLandingSubmit = async (event) => {
		event.preventDefault();
		if (!landingPage || !isValidURL(landingPage)) {
			alert('Add a valid URL.');
			return;
		}

		setLoading(true);
		try {
			const res = await axios.post(`/api/landing/${product_id}`, { url: landingPage });
			console.log(res);
		} catch (err) {
			console.error('Error adding the landing page', err);
			context.notification.show('Error adding the landing page', 'error', true)
			alert('Error adding the landing page', err);
		} finally {
			setLoading(false);
		}
	};

	const handleDocumentClick = (document) => {
		if (selectedDocumentIds.includes(document.id)) {
			setSelectedDocumentIds(selectedDocumentIds.filter(id => id !== document.id));
		} else {
			setSelectedDocumentIds([...selectedDocumentIds, document.id]);
		}
	};

	async function vectorize() {
		try {
			// alert(selectedDocumentIds);
			console.log(selectedDocumentIds);
			setLoading(true);

			// Send the selected document IDs to the server
			const response = await axios.post(`/api/vector/${product_id}`, selectedDocumentIds);

			// Extract data from the server response
			const { message, processedDocuments, errors } = response.data;

			// Display the success message and details of processed documents
			if (processedDocuments?.length) {
				const successMessage = `${processedDocuments.length} document(s) vectorized successfully.`;
				context.notification.show(successMessage, 'success', true);
			}

			// Display errors, if any
			if (errors?.length) {
				const errorMessages = errors.map(
					(err) => `Document ID: ${err.doc_id}, Filename: ${err.filename}, Error: ${err.error}`
				).join('\n');
				context.notification.show(`Some documents failed:\n${errorMessages}`, 'error', false);
			}

			setLoading(false);
		} catch (error) {
			// Handle unexpected errors
			console.error('Error during vectorization:', error.message);
			context.notification.show('Error vectorizing files', 'error', false, '', '', 5000);
			setLoading(false);
		}
	}


	return (
		<Fragment>
			{loading && <Loader fullScreen />}
			{product && (
				<LogoCard
					title={product.name}
					subtitle={product.description}
					logo={product.logo}
					wizardClick={() => setOpenWizard(!openWizard)}
				/>
			)}
			<Spacer height="h-[1.188rem]" />
			<SubNavbar active="documents" id={product_id} />
			<Spacer height="h-[1.125rem]" />
			{openWizard ? <FocusComponent /> : <>


				<h1>Documents</h1>
				<hr />

				{!documents.length && <div>Upload your docs now.</div>}
				<div className='mt-5 w-full bg-gray-100 p-4 rounded-lg shadow-md'>
					{documents.map((document, index) => (
						<div
							className={`cursor-pointer flex items-center justify-between p-2 border-1 border-2 rounded-md transition duration-200 mb-2 shadow-sm hover:border-derisky-rose hover:bg-rose-100 hover:text-derisky-rose ${selectedDocumentIds.includes(document?.id) ? 'border-derisky-rose bg-rose-100 text-derisky-rose ' : 'bg-white'}`}
							// key={index}
							onClick={() => handleDocumentClick(document)}
						>
							<div className='flex items-center'>
								{selectedDocumentIds.includes(document?.id) ?
									<Icon image="check-square" size={20} className=' mr-3' /> :
									<Icon image="file" size={20} className=' mr-3' />
								}
								<h2 className='font-medium '>{document?.name ? document?.name : document?.aws_object.key}</h2>
								<a target='_blank' href={document?.aws_object?.Location} rel="noopener noreferrer">
									<Icon image="maximize" size={14} className=' ml-3' />
								</a>
							</div>

							<p className='text-gray-600'>{document?.description}</p>
							<span className='flex gap-2'>
								<a onClick={handleUpdate}><Icon image='edit' color='lightgray' /></a>
								<a onClick={(e) => {
									e.stopPropagation()
									deleteDocument(document.id)
								}}><Icon image='x' color='lightgray' /></a>
							</span>
						</div>
					))}
					<button
						className={`border border-brand-400 text-brand-500 text-sm rounded hover:shadow-inner full-h shadow-lg flex pt-2 p-2 pr-5 gap-3 self-center content-center`}
						disabled={selectedDocumentIds.length < 1}
						onClick={() => vectorize()}
					>
						<div className='flex gap-3'><Icon image='cpu' />Vectorize selected files.</div>
					</button>
				</div>

				<Spacer height="h-[10rem]" />
				<div className='flex gap-2'>
					<button
						className={`border border-brand-400 text-brand-500 text-sm rounded hover:shadow-inner full-h shadow-lg flex pt-2 p-2 pr-5 gap-3 self-center content-center`}
						onClick={() => setShowDocument(!showDocument)}
					>
						{showDocument ? 'No Document upload' :
							<div className='flex gap-3'><Icon image='file-text' />Add new Document</div>}
					</button>
					<button
						className={`border border-brand-400 text-brand-500 text-sm rounded hover:shadow-inner full-h shadow-lg flex pt-2 p-2 pr-5 gap-3 self-center content-center`}
						onClick={() => setLanding(!showLanding)}
					>
						{showLanding ? 'Landing page' :
							<div className='flex gap-3'><Icon image='layout' />Add landing page</div>}
					</button>
				</div>
				{showLanding && (
					<div className='w-full'>
						<h2>Add a landing page</h2>
						<Spacer />
						<form onSubmit={handleLandingSubmit} className="flex flex-col items-center">
							<input type="url" id="landingpage" className="border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500" onChange={(e) => setLandingPage(e.target.value)} />
							<button type="submit" className="mt-4 bg-blue-500 text-white py-2 px-4 rounded">
								Add landing page
							</button>
						</form>
					</div>
				)}
				{showDocument && (
					<div className='w-full'>
						<h2>Upload your Documents</h2>
						<Spacer />
						<form onSubmit={handleSubmit} className="flex flex-col items-center">
							<div
								className="w-full bg-gray-300 rounded-md p-5 text-center cursor-pointer text-brand-400 mx-auto"
								onClick={() => document.getElementById('fileInput').click()}
							>
								{previews.length > 0 ? (
									<div className="grid grid-cols-1 gap-4">
										{previews.map((preview, index) => (
											<div key={index} className="flex items-center">
												<Icon image='file' />
												<span className="ml-2">{preview.name}</span>
											</div>
										))}
									</div>
								) : (
									<p>Click to select files to upload</p>
								)}
							</div>
							<input
								type="file"
								id="fileInput"
								onChange={handleFileChange}
								className="hidden"
								multiple
								accept=".pdf,.doc,.docx,.txt,ppt,pptx"
							/>
							<button type="submit" disabled={loading} className="mt-4 bg-blue-500 text-white py-2 px-4 rounded">

								{loading ? 'Uploading...' : 'Upload'}
							</button>
						</form>
					</div>
				)}
			</>}
		</Fragment>
	);
};
