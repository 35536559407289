/***
 *   DERISKY MBA
 *   A premium educational platform for mastering venture building and innovation
 *
 **********/

import React, {useContext, useRef, useState} from 'react';
import {Row, Button, Loader, Grid, Icon, Badge, Card, Animate} from 'components/lib';
import {SocialRow} from "../../components/socialrow/socialrow";
import {Testimonialto} from "../../components/testimonialto/testimonialto";
import Style from "../commit/components/hero/hero.tailwind";
import {RWebShare} from "react-web-share";
import { AuthContext } from '../../app/auth';
import ButtonPrimary from "../home/components/ButtonPrimary";
import Media from "../home/components/Media";
import {useNavigate} from 'react-router-dom'

export function MBA(props) {
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();
    const cardRefs = useRef([]);

    // Premium course features showcasing key learning modules
    const features = [{
        title: "Master Business Model Hypotheses",
        description: "Learn to identify and validate critical business assumptions with precision.",
        video: "video/Hypothesize your business model.mp4",
        badge: "🧠 AI-Powered Insights",
        type: "video",
        poster: 'video/course/hypothesize.png'
    }, {
        title: "Strategic Risk Assessment",
        description: "Develop frameworks to evaluate and prioritize business risks effectively.",
        video: "video/Assess Risks.mp4", 
        badge: "🧠 AI-Driven Analysis",
        type: "video",
        poster: 'video/course/actions.png'
    }, {
        title: "Innovation Risk Management",
        description: "Transform uncertainty into opportunity through systematic experimentation.",
        video: "video/experiments.mp4",
        badge: "🧠 AI-Enhanced Strategy",
        type: "video",
        poster: 'video/course/risky.png'
    }, {
        title: "Data-Driven Portfolio Optimization",
        description: "Master portfolio management using advanced analytics and risk metrics.",
        video: "video/Make data driven portfolio.mp4",
        badge: "🧠 ML-Powered Forecasting",
        type: "video",
        poster: 'video/course/portfolio.png'
    }];

    const [playingStates, setPlayingStates] = useState(features.map(() => false));

    const handlePlayingState = (index) => {
        const newPlayingStates = [...playingStates];
        newPlayingStates[index] = true;
        setPlayingStates(newPlayingStates);
    };

    // Comprehensive curriculum structure with detailed modules
    const [videos, changeVideos] = useState([
        {
            title: "The Art of Derisking",
            description: "Master the delicate balance between innovation and risk management. Learn to embrace calculated risks while building robust business models that stand the test of market realities.",
            isOpen: true,
            icon: "compass",
            videos: [{
                title: "Fearless Innovation",
                description: "Develop the mindset and tools needed for confident business innovation.",
                video: "/video/course/deriskingvsbuilding.mp4",
                poster: 'video/course/fearlessderisking.png',
                free: true
            }, {
                title: "Strategic Execution",
                description: "Transform theoretical knowledge into practical business success.",
                image: "/video/course/deriskingvsbuilding.png",
                free: true
            }, {
                title: 'Collaborative Excellence',
                description: 'Leverage collective intelligence to triple your success rate.',
                free: false,
                image: '/video/course/collaborate.png'
            }],
        },
        {
            title: "Hypothesis Development",
            "description": "Developing strong hypotheses for business models and experiments.",
            "isOpen": true,
            "icon": "table",
            "videos": [
                {
                    "title": "Hypothesize!",
                    "description": "Derisky.ai uses Kanban, a lean scheduling system from Japan, to manage experiments. This system uses visual cues to guide production and imposes limits on the number of experiments in any workflow step at a time, known as Work in Progress (WIP) Limits, to ensure a steady flow of work. Kanban aids in managing daily development with minimum overhead and provides project visibility through customizable dashboards.",
                    video: '/video/course/Hypothesis.mp4',
                    free: true,
                    poster: 'video/course/hypothesize.png'
                },
                {
                    "title": "The secrets of a Strong Hypothesis",
                    "description": "A strong hypothesis should be precise, discrete, and verifiable. It should be formulated as specifically as possible and be testable. Multifaceted hypotheses can be broken down into multiple smaller ones. It's important to formulate hypotheses that address all uncertainties across all risk categories.",
                    free: false, video: '',
                    poster: 'video/course/comingsoon.png'
                },
                {
                    "title": "Prioritize Hypotheses",
                    "description": "Determining the order of importance for testing hypotheses.",
                    video: '', free: false,
                    poster: 'video/course/comingsoon.png'
                }
            ]
        },
        {
            title: "Experiments & Testing Business Models",
            description: "asdasd asd asd ",
            isOpen: false,
            icon: "table",
            videos: [{
                title: "Experiments.",
                description: "Derisky advocates for intelligent experiments to challenge risks and untested assumptions. It assists in selecting a meaningful experiment, whether it's building a prototype for technical feasibility or other options. Understanding the experiment's epistemology, identifying the goal, sample size, time required, and success criteria is crucial. Real-world experiments are superior to lab experiments and can unlock unlimited resources.",
                video: '/video/course/intelligentexperience.mp4',
                free: true,
                poster: 'video/course/intelligentexperimence.png'
            }, {
                title: "Performing Experiments.",
                description: "Consciously realizing on which assumptions your business idea relying on is crucial.",
                video: "",
                free: false,
                poster: 'video/course/comingsoon.png'
            }, {
                title: "Tracking & delegate Experiments.",
                description: "Derisky.ai uses Kanban, a lean scheduling system from Japan, to manage experiments. This system uses visual cues to guide production and imposes limits on the number of experiments in any workflow step at a time, known as Work in Progress (WIP) Limits, to ensure a steady flow of work. Kanban aids in managing daily development with minimum overhead and provides project visibility through customizable dashboards.",
                video: "",
                free: false,
                poster: 'video/course/comingsoon.png'
            }]
        }, {
            title: "Project metrics",
            description: "asdasd asd asd ",
            isOpen: true,
            videos: [{
                title: "Innovation metrics.",
                description: "When building innovative projects, it's more valuable to track assumptions and learnings rather than traditional metrics. Best practices include reducing uncertainty before building, opting for cheaper and faster methods in early stages, choosing experiments that provide the most evidence, and conducting multiple experiments for the same hypothesis to increase confidence.",
                video: "",
                free: false,
                poster: 'video/course/comingsoon.png'
            }, {
                title: "Project metrics.",
                description: "Innovation metrics for a project are divided into input metrics (resources invested) and output metrics (impact and value of resources). Derisking innovation involves measuring assumptions, risks, and experiment costs as inputs, and success rate, remaining risk, and reduced risk as outputs. The potential derisked revenue can be calculated by multiplying the impact with the expected revenue. Tracking these metrics over time can help forecast future innovation performance.",
                video: "",
                free: false,
                poster: 'video/course/comingsoon.png'
            }]
        }, {
            title: "Portfolio Management",
            description: " ",
            isOpen: true,
            videos: [{
                title: "Portfolio Metrics.",
                description: "Portfolio metrics categorize products into four types based on their financial potential and evidence of success. Promising Concepts have large financial potential but weak evidence of success, Rising Stars have large financial potential and strong evidence of success, Niche Opportunities have small financial potential and weak evidence of success, and Safe Play options offer small financial potential but strong evidence of success.",
                video: '',
                free: false,
                poster: 'video/course/comingsoon.png'
            }, {
                title: "Portfolio Actions",
                description: "The Explore portfolio's journey involves a process of discovery, validation, reality checks, change of direction, and acceleration. It begins with understanding the customer and their willingness to pay, followed by validation through mock sales. If evidence suggests the idea may not succeed, a re-evaluation of the business model is carried out, possibly leading to a change of direction. The final phase involves creating a working prototype to test the value proposition in a limited market, justifying larger investments for scaling.",
                free: false,
                video: '',
                poster: 'video/course/comingsoon.png'
            }]
        }, {
            title: 'Leveraging AI',
            isOpen: true,
            videos: [{
                title: "Leveraging Generative AI.",
                description: "Theory on its own holds no value. it is only when combined with derisking that its true potential is unlocked.",
                video: "",
                free: false,
                poster: 'video/course/comingsoon.png'
            }]
        },
        {
            title: 'Last chapter',
            isOpen: true,
            videos: [{
                title: "Certify your knowledge.",
                description: 'Certifying your knowledge proves expertise, boosts credibility, and enhances job market competitiveness. It opens doors to better opportunities & salaries, while continuous learning. It’s an investment in your future.',
                image: "/video/course/certify.png",
                free: false
            }, {
                title: "Show the world what you learned.",
                description: "Consciously realizing on which assumptions your business idea relying on is crucial.",
                image: "/video/course/shareit.png",
                free: true,
            }, {
                title: "One week only to bring your idea to the market.",
                description: "Derisky Confront Week: Startup speed, enterprise power.\n" +
                    "Many companies overanalyze and produce lengthy reports without validated info. Derisky Confront Week solves this by quickly developing, testing, and refining tangible solutions. We help you create and launch concepts, prototypes, or products rapidly, ensuring progress and customer focus.",
                video: "/video/course/corporateinnovation.mp4",
                free: true
            }, {
                title: "Something is not clear?.",
                description: "The Derisky MBA is a growing curriculum. If you need further clarification or have any questions, please feel free to contact me. I may also release a video to assist you.\n.",
                image: "/video/course/questions.png",
                free: true
            }]
        }
    ]);

    function changeOpen(i) {
        const newVideos = [...videos];
        newVideos[i].isOpen = !videos[i].isOpen;
        changeVideos(newVideos);
    }

    function closeAllExcept(i) {
        const newVideos = videos.map((vid, number) => ({
            ...vid,
            isOpen: number === i
        }));
        changeVideos(newVideos);
    }

    function onClick(chapter, index) {
        const id = chapter.title.toString();
        document.getElementById(id).scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
        closeAllExcept(index);
    }
    function renderContent() {
        return (<div>
            {!user && <>
                <section
                    className={`self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-12 box-border max-w-full shrink-0 text-left text-63xl text-gray-600 font-plus-jakarta-sans mq450:pb-5 mq450:box-border mq1150:pb-[31px] mq1150:box-border`}>
                    <div className="h-[78rem] lg:h-[800px] flex-1 [background:linear-gradient(180deg,_#f8f9fe,_#f8f8fb)] overflow-hidden flex flex-col lg:flex-row items-start justify-start py-[94.5px] pr-0 pl-[2rem] lg:pl-[140px] md:pl-[110px] box-border gap-[107px] max-w-full z-[0] mq450:gap-[27px] mq450:pl-5 mq450:box-border mq800:gap-[53px] mq800:pl-[70px] mq800:pt-[26px] mq800:pb-[26px] mq800:box-border mq1150:pt-10 mq1150:pb-10 mq1150:box-border mq1350:pt-[61px] mq1350:pb-[61px] mq1350:box-border">
                        <div className="w-full lg:w-[568px] flex flex-col items-start justify-start gap-[24px] shrink-0 mq1150:hidden mq1150:max-w-full">
                            <div className="flex items-center gap-2 mb-2">
                                <span className="bg-green-500 text-white px-3 py-1 rounded-full text-sm font-bold animate-pulse">
                                    LIMITED TIME OFFER
                                </span>
                                <span className="text-gray-600 text-sm">Free Access - Save $997!</span>
                            </div>

                            <h1 className="m-0 text-[3em] lg:text-[5.12em] self-stretch relative tracking-[-1px] leading-[3.25rem] lg:leading-[5.5rem] capitalize font-bold text-base-black mq450:text-6xl mq450:leading-[36px] mq800:text-22xl mq800:leading-[54px]">
                                <span>{`Master the Art of `}</span>
                                <span className="text-transparent !bg-clip-text [background:linear-gradient(98.81deg,_#825bf8,_#5f44f6_44.79%,_#5caff9_96.35%)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent]">Venture Building</span>
                            </h1>

                            <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-4 box-border max-w-full">
                                <div className="flex-1 text-xl leading-[150%] font-medium text-[#475467]">
                                    Join 10,000+ innovators who've transformed their ideas into successful ventures. Learn proven VC methodologies to validate products, manage risks, and make data-driven decisions that save millions.
                                </div>
                            </div>

                            <div className="flex flex-col gap-4">
                                <ButtonPrimary 
                                    className="text-white bg-[#6145F7] hover:!bg-[#422db6] rounded-3xl text-lg px-8 py-4 shadow-lg transform hover:scale-105 transition-all" 
                                    onClick={() => navigate('/signup')}
                                >
                                    Get Free Access Now →
                                </ButtonPrimary>

                                

                                <div className="relative inline-block">
                                    <iframe
                                        src="https://embed-v2.testimonial.to/badge/derisky-ai?starColor=825bf8&fontColor=000000&fontFamily=Plus+Jakarta+Sans&reviewTerm=review&fontSize=16&reviewTermPlural=reviews&alignment=left&customTemplate=%7Baverage%7D+stars+from+8+%7BreviewTerm%7D"
                                        className="border-0 w-full h-auto pointer-events-none"
                                        scrolling="no"
                                    ></iframe>
                                    <a
                                        href="http://testimonial.to/derisky/all"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="absolute top-0 left-0 w-full h-full z-10 block"
                                    ></a>
                                </div>
                            </div>
                        </div>

                        <div className="mt-[2.4px] lg:mt-[-202.4px] h-auto lg:h-[1798.5px] flex-1 relative w-full lg:max-w-[calc(100%_-_675px)] text-21xl text-gray-800 mq1150:hidden mq1150:max-w-full">
                            <img className="relative lg:absolute top-[1rem] h-auto lg:h-[80rem] bottom-[0px] left-[0px] max-h-full w-[964.9px]" alt="" src="/Union3.svg" />
                            <div className="relative lg:absolute top-[-25.9rem] lg:top-[450.9px] left-[30px] lg:left-[90px] w-[311px] flex flex-col items-start justify-start gap-[2rem] z-[1]">
                                <div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
                                    <div className="self-stretch relative text-[2.5rem] tracking-[-1px] leading-[130%] capitalize font-semibold mq450:text-5xl mq450:leading-[31px] mq800:text-13xl mq800:leading-[42px]">5X</div>
                                    <div className="self-stretch relative text-[1.02rem] leading-[34px] font-semibold text-gray-600">Faster Time-to-Market</div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
                                    <div className="self-stretch relative text-[2.5rem] tracking-[-1px] leading-[130%] capitalize font-semibold mq450:text-5xl mq450:leading-[31px] mq800:text-13xl mq800:leading-[42px]">15X</div>
                                    <div className="self-stretch relative text-[1.02rem] leading-[34px] font-semibold text-gray-600">ROI Over 3 Years</div>
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
                                    <div className="self-stretch relative text-[2.5rem] tracking-[-1px] leading-[130%] capitalize font-semibold mq450:text-5xl mq450:leading-[31px] mq800:text-13xl mq800:leading-[42px]">80%</div>
                                    <div className="self-stretch relative text-[1.02rem] leading-[34px] font-semibold text-gray-600">Immediate Cost Savings</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Media />
                <Animate>
                    <Testimonialto/>
                </Animate>
                <Row color='white'>

                    <p className='text-lg font-bold'>
                        Unlock the secrets to testing and validating your business ideas with real customers. Learn
                        practical strategies to minimize risks, unify your team's communication, and seamlessly
                        integrate de-risking into your business model. Shift from executing blind plans to
                        systematically identifying and mitigating risks. Start your journey towards successful,
                        risk-free business innovation today!
                    </p>
                </Row>
                <div className="bg-[linear-gradient(98.81deg,rgb(130,91,248),rgb(95,68,246)_44.79%,rgb(92,175,249)_96.35%)] pt-8 pb-8 text-white shadow-2xl mt-16 mb-16">
                    <div className='container mx-auto px-4'>
                        <div className='grid md:grid-cols-4 xs:grid-cols-2 gap-8 h-full'>
                            <div className='p-8 md:col-span-4 xs:col-span-2 text-center mb-8'>
                                <h2 className="text-3xl font-bold mb-4">Derisky MBA Video Curriculum</h2>
                                <p className="text-xl opacity-90">
                                    Thoughtfully conceptualized, designed and animated lessons to accelerate your learning
                                </p>
                            </div>
                            {
                                features && features.map((f, index) => (
                                    <div className='md:col-span-1 xs:col-span-1 transform transition duration-500 hover:scale-105' key={index}>
                                        {!playingStates[index] ? (
                                            <div 
                                                className='relative cursor-pointer rounded-xl overflow-hidden'
                                                onClick={() => handlePlayingState(index)}
                                            >
                                                <img 
                                                    src={f.poster} 
                                                    alt={f.title}
                                                    className="w-full h-full object-cover opacity-30"
                                                />
                                                <div className="absolute inset-0 bg-black/40 flex flex-col justify-center items-center p-4">
                                                    <div className="bg-white/20 p-3 rounded-full mb-4">
                                                        <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 20 20">
                                                            <path d="M8 5v10l7-5-7-5z"/>
                                                        </svg>
                                                    </div>
                                                    <h3 className="text-lg font-semibold text-center">{f.title}</h3>
                                                    <div className="text-sm mt-2 text-white/80">{f.badge}</div>
                                                </div>
                                            </div>
                                        ) : (
                                            <video 
                                                id={f.title}
                                                className='rounded-xl shadow-lg hover:shadow-2xl w-full'
                                                src={f.video}
                                                autoPlay
                                                noremoteplayback='true'
                                                controlsList="nodownload"
                                                playsInline
                                                controls
                                            />
                                        )}
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>

                <Row>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div className="text-lg mt-5 md:col-span-2 md:order-1">

                            {
                                renderText()
                            }
                        </div>
                        <div className="order-2">
                            <Animate>
                                <div className="bg-gradient-to-br from-white to-gray-50 rounded-2xl p-10 shadow-2xl border border-gray-100/50 backdrop-blur-sm hover:shadow-3xl transition-all duration-300">
                                    <div className="flex items-center mb-8">
                                        <div className="p-3 bg-brand-400/10 rounded-xl">
                                            <Icon size='24' color='derisky-rose' image='award'/>
                                        </div>
                                        <h1 className="text-2xl font-bold text-brand-400 ml-4 bg-gradient-to-r from-brand-400 to-brand-500 bg-clip-text text-transparent">
                                            Derisky MBA
                                        </h1>
                                    </div>

                                    <dl className="space-y-6 text-gray-900">
                                        <div className="space-y-3">
                                            <dt className="flex items-center text-lg font-semibold text-gray-800">
                                                <Icon image='briefcase' color={"dark"} size="16"/>
                                                <h3 className='ml-3'>Who can benefit</h3>
                                            </dt>
                                            <dd className="text-sm leading-relaxed text-gray-600 ml-7">
                                                Entrepreneurs, Product managers, Program Managers, Portfolio
                                                Managers, Analysts, Innovation Managers, and other specialists
                                                interested in the Innovation and AI.
                                                Solopreneurs, Venture Capitalists
                                            </dd>
                                        </div>

                                        <div className="space-y-3">
                                            <dt className="flex items-center text-lg font-semibold text-gray-800">
                                                <Icon image='briefcase' color={"dark"} size="16"/>
                                                <h3 className='ml-3'>Content</h3>
                                            </dt>
                                            <dd className="text-sm leading-relaxed text-gray-600 ml-7">
                                                Mental models & deep dives on Innovation, Hypotheses, Experiments, 
                                                Innovation Metrics, Derisking
                                            </dd>
                                        </div>

                                        <div className="space-y-3">
                                            <dt className="flex items-center text-lg font-semibold text-gray-800">
                                                <Icon image='dollar-sign' color={"dark"} size="16"/>
                                                <h3 className='ml-3'>Price</h3>
                                            </dt>
                                            <dd className="text-sm font-medium text-brand-400 ml-7">
                                                Free (No credit card required)
                                            </dd>
                                        </div>

                                        <div className="space-y-3">
                                            <dt className="flex items-center text-lg font-semibold text-gray-800">
                                                <Icon image='award' color={"dark"} size="16"/>
                                                <h3 className='ml-3'>Degree</h3>
                                            </dt>
                                            <dd className="text-sm leading-relaxed text-gray-600 ml-7">
                                                Derisky MBA Certificate for LinkedIn
                                            </dd>
                                        </div>
                                    </dl>

                                    <div className="mt-8">
                                        <Button 
                                            goto='/signup' 
                                            text='Register for Course' 
                                            color={"derisky-rose"} 
                                            big
                                            className="w-full transform transition-transform hover:scale-105"
                                        />
                                    </div>

                                    <div className="flex flex-wrap gap-2 mt-8">
                                        <Badge color={'green'} text="Innovation"/>
                                        <Badge color='green' text="Product Management"/>
                                        <Badge color='green' text="AI"/>
                                        <Badge color='green' text="Derisking"/>
                                        <Badge color='green' text="Business"/>
                                        <Badge color='green' text="Self actualization"/>
                                    </div>

                                </div>
                            </Animate>

                        </div>
                    </div>

                </Row>
            </>}
        <div className='bg-gradient-to-br from-brand-400 via-brand-500 to-brand-600 text-white'>
            {!user && <>
                <Row>
                    <div className="grid md:grid-cols-5 sm:grid-cols-1 gap-8 py-12">
                        <div className="md:col-span-3 sm:col-span-1">
                            <h1 className="text-4xl font-bold mb-12 bg-clip-text text-transparent bg-gradient-to-r from-white to-gray-200">MBA Curriculum</h1>
                            <div className="space-y-8">
                                <section className="bg-white/10 p-6 rounded-xl backdrop-blur-sm">
                                    <h3 className="text-xl font-semibold mb-3">The Importance of De-risking</h3>
                                    <p className="text-gray-100">Avoid failure from unchecked assumptions by systematically identifying and mitigating risks.</p>
                                </section>

                                <section className="bg-white/10 p-6 rounded-xl backdrop-blur-sm">
                                    <h3 className="text-xl font-semibold mb-3">Understanding De-risking</h3>
                                    <p className="text-gray-100">Key concepts, steps, and terminology to get started confidently.</p>
                                </section>

                                <section className="bg-white/10 p-6 rounded-xl backdrop-blur-sm">
                                    <h3 className="text-xl font-semibold mb-3">Identifying and Managing Risks</h3>
                                    <p className="text-gray-100">Turn uncertainties into testable hypotheses for successful de-risking.</p>
                                </section>

                                <section className="bg-white/10 p-6 rounded-xl backdrop-blur-sm">
                                    <h3 className="text-xl font-semibold mb-3">Learn from the Experts</h3>
                                    <p className="text-gray-100">Gain insights and master techniques to effectively de-risk your business ideas.</p>
                                </section>

                                <section className="bg-white/20 p-6 rounded-xl backdrop-blur-sm text-center">
                                    <h3 className="text-2xl font-bold">Start your journey towards successful, risk-free business innovation today!</h3>
                                </section>
                            </div>
                        </div>
                        <div className="md:col-span-2 sm:col-span-1 flex items-center justify-center p-8">
                            <img src="/Add/mba.png" alt="Derisky MBA" className="rounded-2xl shadow-2xl transform hover:scale-105 transition-all duration-300"/>
                        </div>
                    </div>
                    <Grid cols={3} className="gap-8">
                        <Card className="p-8 text-black shadow-2xl rounded-xl bg-white/95 backdrop-blur-sm transform hover:-translate-y-2 transition-all duration-300">
                            <Icon image='triangle' color={"derisky-rose"} size="40"/>
                            <h1 className="my-6 text-brand-400 text-2xl font-bold">1. Business Idea Testing</h1>
                            <p className="text-gray-600">It's easy to come up with business ideas. The first step is to extract appropriate and testable hypotheses from your business model.</p>
                        </Card>
                        <Card className="p-8 text-black shadow-2xl rounded-xl bg-white/95 backdrop-blur-sm transform hover:-translate-y-2 transition-all duration-300">
                            <Icon image='hexagon' color={"derisky-rose"} size="40"/>
                            <h1 className="my-6 text-brand-400 text-2xl font-bold">2. Smart Experiments</h1>
                            <p className="text-gray-600">You conduct smart experiments to challenge assumptions, emphasizing participant selection, appropriate metrics for stronger evidence.</p>
                        </Card>
                        <Card className="p-8 text-black shadow-2xl rounded-xl bg-white/95 backdrop-blur-sm transform hover:-translate-y-2 transition-all duration-300">
                            <Icon image='bar-chart' color={"derisky-rose"} size="40"/>
                            <h1 className="my-6 text-brand-400 text-2xl font-bold">3. Portfolio Management</h1>
                            <p className="text-gray-600">Ideation, Discovery, Validation & Confirmation, involving idea generation, market testing, feasibility confirmation & scalability decision.</p>
                        </Card>
                    </Grid>
                </Row></>
            }
            <Row className="py-12">
                {!user && <h1 className='text-3xl font-bold mb-8'>MBA Curriculum</h1>}
                <div className="flex flex-wrap gap-4 mb-12">
                    <span className='font-bold text-xl'>Chapters:</span>
                    {videos.map((chapter, index) => (
                        <button
                            key={chapter.title + Math.random()}
                            className="px-4 py-2 bg-white/10 rounded-full hover:bg-white/20 transition-all duration-300"
                            onClick={() => onClick(chapter, index)}
                        >
                            {index + 1}. {chapter.title}
                        </button>
                    ))}
                </div>
                <div className='space-y-8'>
                    {videos.map((chapter, index) => (
                        <div 
                            id={chapter.title}
                            key={chapter.title + Math.random()}
                            className="bg-white/5 rounded-2xl overflow-hidden backdrop-blur-sm"
                        >
                            <button
                                className="w-full p-6 flex items-center justify-between hover:bg-white/5 transition-all duration-300"
                                onClick={() => changeOpen(index)}
                            >
                                <h1 className="text-2xl font-bold flex items-center gap-3">
                                    <Icon icon={chapter.icon} size={24}/>
                                    {chapter.title}
                                </h1>
                                <Icon 
                                    className="text-white/80" 
                                    image={!chapter.isOpen ? 'chevron-down' : 'chevron-up'}
                                />
                            </button>

                            {chapter.isOpen && (
                                <div className="p-6 pt-0">
                                    <p className="text-white/80 mb-6">{chapter.description}</p>
                                    <Grid cols={3} className="gap-6">
                                        {chapter.videos.map(video => (
                                            <div
                                                className="bg-white/10 rounded-xl overflow-hidden hover:bg-white/15 transition-all duration-300"
                                                key={chapter.title + " " + Math.random()}
                                            >
                                                <div className="aspect-video">
                                                    {!user ? (
                                                        video.free && video.hasOwnProperty("video") ? (
                                                            <video controls poster={video.poster} className="w-full h-full object-cover">
                                                                <source src={video.video} type="video/mp4"/>
                                                                Your browser does not support the video tag.
                                                            </video>
                                                        ) : (
                                                            <a href='/signup' className="block w-full h-full">
                                                                <img 
                                                                    className="w-full h-full object-cover"
                                                                    src='/video/course/signup.png'
                                                                    alt="Sign up"
                                                                />
                                                            </a>
                                                        )
                                                    ) : (
                                                        video.hasOwnProperty("video") ? (
                                                            <video controls poster={video.poster} className="w-full h-full object-cover">
                                                                <source src={video.video} type="video/mp4"/>
                                                                Your browser does not support the video tag.
                                                            </video>
                                                        ) : video.hasOwnProperty("image") ? (
                                                            <img
                                                                alt="Sample"
                                                                src={video.image}
                                                                className="w-full h-full object-cover"
                                                            />
                                                        ) : (
                                                            <img 
                                                                src='/video/course/released.png'
                                                                className="w-full h-full object-cover"
                                                                alt="Coming soon"
                                                            />
                                                        )
                                                    )}
                                                </div>
                                                <div className="p-6">
                                                    <h3 className="text-xl font-bold mb-2">{video.title}</h3>
                                                    <p className="text-white/80 mb-4">{video.description}</p>
                                                    <RWebShare
                                                        data={{
                                                            text: video.description,
                                                            url: "https://derisky.ai/mba",
                                                            title: video.title
                                                        }}
                                                        onClick={() => console.log("shared successfully!")}
                                                    >
                                                        <button className="flex items-center gap-2 text-white/60 hover:text-white transition-colors duration-300">
                                                            <span>Share</span>
                                                            <Icon image='share-2'/>
                                                        </button>
                                                    </RWebShare>
                                                </div>
                                            </div>
                                        ))}
                                    </Grid>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </Row>

            <SocialRow/>
        </div>
        <script async type="text/javascript" src="https://testimonial.to/js/widget-embed.js"></script>
        <div className="testimonial-to-embed"
             data-url="https://embed-v2.testimonial.to/c/derisky-ai?theme=light" data-allow="camera;microphone"
             data-resize="true"></div>

    </div>)
        ;
    }

    function renderText() {
        return (<div className='space-y-4 mr-1 mb-8'>
            <section>
                In today's competitive market, 80% of new products fail. <b>Derisking</b> transforms how you validate and launch successful ventures.

                This executive-level course delivers battle-tested techniques from venture capital to systematically reduce risk and maximize success potential. Learn to make data-driven decisions that protect your investments and accelerate growth.

                Master strategic validation frameworks like the Business Model Canvas while developing a rigorous experimentation mindset. Replace guesswork with evidence-based methodologies that consistently deliver results.

                <h1 className="text-2xl my-10 font-bold text-center bg-gradient-to-r from-brand-400 to-derisky-rose bg-clip-text text-transparent">Derisking: Transform Ideas into Successful Ventures</h1>
                <div className="grid md:grid-cols-3 gap-6 grid-cols-2">
                    {[
                        {image: 'trending-up', text: 'Manage ideas like a venture capitalist.'},
                        {image: 'dollar-sign', text: 'Unlock unlimited resources.'},
                        {image: 'briefcase', text: 'Spark creativity & boost your career.'},
                        {image: 'mail', text: 'One new lecture every Wednesday.'},
                        {image: 'film', text: 'Thoughtful minimal 2D animations.'},
                        {image: 'heart', text: 'Mental models by neuroscience.'}
                    ].map((item, index) => (
                        <Card
                            key={index + " " + item.text}
                            className="h-full p-6 text-black shadow-xl hover:shadow-2xl transition-all duration-300 rounded-xl bg-gradient-to-br from-white to-gray-50 border border-gray-100 transform hover:-translate-y-1"
                        >
                            <div className="bg-brand-400/10 w-14 h-14 rounded-lg flex items-center justify-center mb-4">
                                <Icon image={item.image} color="derisky-rose" size="24" />
                            </div>
                            <h1 className="text-gray-800 text-lg font-medium leading-snug">{item.text}</h1>
                        </Card>
                    ))}
                </div>

            </section>

        </div>);
    }

    return (
        <>
            {props.loading ? <Loader/> : renderContent()}</>
    );
}



