import PropTypes from 'prop-types'
import {useEffect, useRef, useState} from 'react'

const IntegrationNetwork = ({className = ''}) => {
	const canvasRef = useRef(null)
	const [loadedImages, setLoadedImages] = useState({})
	const [activeServiceIndex, setActiveServiceIndex] = useState(Math.floor(Math.random() * 6)) // Start with random index
	const animationRef = useRef(null)
	const flowProgress = useRef(0)

	// Take 6 random services
	const allServices = [
		{name: 'Optimizely', logo: 'https://www.rudderstack.com/integration/_next/image/?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2F97bpcflt%2Fproduction%2F90d44122f4613b945d9ebc41e373beba975f82bb-83x80.svg%3Fw%3D83%26fm%3Dwebp%26fit%3Dfill%26dpr%3D2&w=2048&q=75'},
		{name: 'Survey Monkey', logo: 'https://play-lh.googleusercontent.com/QBR4_b0G2bXWnNx8WOOL-3XmSC6yLkBrX8IuAM6Jpq_aDTnB3crN8GI0bdIp1eJPrW8'},
		{name: 'Unbounce', logo: 'https://cdn.worldvectorlogo.com/logos/unbounce.svg'},
		{name: 'Meta Business', logo: 'https://axiom.ai/automate-images/meta-logo.png'},
		{name: 'Amplitude', logo: 'https://amplitude.com/favicon.ico'},
		{name: 'Google Analytics', logo: 'https://www.vectorlogo.zone/logos/google_analytics/google_analytics-icon.svg'},
		{name: 'Mixpanel', logo: 'https://cdn.brandfetch.io/idr_rhI2FS/theme/dark/idMJ8uODLv.svg'},
		{name: 'Hotjar', logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1bFokFT723dhAIqi7w6H1dBpxLQVmOcEuCg&s'},
		{name: 'Segment', logo: 'https://www.vectorlogo.zone/logos/segment/segment-icon.svg'},
		{name: 'Mailchimp', logo: 'https://cdn.worldvectorlogo.com/logos/mailchimp-freddie-icon.svg'},
		{name: 'Tealium', logo: 'https://seeklogo.com/images/T/tealium-logo-A7DABCAF4C-seeklogo.com.png'},
		{name: 'Rudderstack', logo: 'https://avatars.githubusercontent.com/u/52480567?s=280&v=4'},
		{name: 'Appcues', logo: 'https://www.vectorlogo.zone/logos/appcues/appcues-icon.svg'},
		{name: 'Kissmetrics', logo: 'https://www.vectorlogo.zone/logos/kissmetrics/kissmetrics-icon.svg'},
		{name: 'Delighted', logo: 'https://cdn.worldvectorlogo.com/logos/delighted.svg'},
		{name: 'Qualtrics', logo: 'https://cdn.prod.website-files.com/6640cd28f51f13175e577c05/664e00b93db9646afe55f0ef_a0ebb2e4-9231-5e58-80f0-487d5bea1f7e.svg'}
	]

	// Take first 6 services for visualization
	const [services] = useState(allServices.slice(0, 6))

	// Auto rotate active service randomly
	useEffect(() => {
		const interval = setInterval(() => {
			let nextIndex
			do {
				nextIndex = Math.floor(Math.random() * services.length)
			} while (nextIndex === activeServiceIndex)
			setActiveServiceIndex(nextIndex)
			flowProgress.current = 0 // Reset flow animation
		}, 4000)

		return () => clearInterval(interval)
	}, [services.length, activeServiceIndex])

	// Preload images
	useEffect(() => {
		services.forEach((service) => {
			const img = new Image()
			img.onload = () => {
				setLoadedImages((prev) => ({
					...prev,
					[service.name]: true
				}))
			}
			img.src = service.logo
		})
	}, [services])

	useEffect(() => {
		const canvas = canvasRef.current
		if (!canvas) return

		const ctx = canvas.getContext('2d')
		if (!ctx) return

		const centerX = canvas.width / 2
		const centerY = canvas.height / 2
		const radius = 180 // Reduced radius for smaller circle

		// Position services in a circle
		const positions = services.map((_, i) => {
			const angle = (i * 2 * Math.PI) / services.length
			return {
				x: centerX + radius * Math.cos(angle),
				y: centerY + radius * Math.sin(angle)
			}
		})

		const drawElbowedLine = (startX, startY, endX, endY, isActive = false) => {
			const midX = startX + (endX - startX) / 2

			ctx.beginPath()

			if (isActive) {
				// Create gradient for active line
				const gradient = ctx.createLinearGradient(startX, startY, endX, endY)
				gradient.addColorStop(0, '#825bf8')
				gradient.addColorStop(0.5, '#5f44f6')
				gradient.addColorStop(1, '#5caff9')

				ctx.strokeStyle = gradient
				ctx.lineWidth = 3
				ctx.shadowColor = '#825bf8'
				ctx.shadowBlur = 15

				// Continuous flow effect without blinking
				const pathLength = 50
				ctx.setLineDash([pathLength, pathLength])
				ctx.lineDashOffset = flowProgress.current * pathLength // Removed negative sign to reverse direction
			} else {
				ctx.strokeStyle = 'rgba(229, 231, 235, 0.6)'
				ctx.lineWidth = 1.5
				ctx.shadowBlur = 0
				ctx.setLineDash([])
			}

			ctx.moveTo(startX, startY)
			ctx.lineTo(midX, startY)
			ctx.lineTo(midX, endY)
			ctx.lineTo(endX, endY)
			ctx.stroke()
		}

		const animate = () => {
			ctx.clearRect(0, 0, canvas.width, canvas.height)

			// Draw inactive lines first
			positions.forEach((pos, i) => {
				if (i !== activeServiceIndex) {
					drawElbowedLine(centerX, centerY, pos.x, pos.y, false)
				}
			})

			// Draw active line with animation
			if (activeServiceIndex >= 0) {
				const pos = positions[activeServiceIndex]
				drawElbowedLine(centerX, centerY, pos.x, pos.y, true)
				flowProgress.current = (flowProgress.current + 0.02) % 1 // Kept forward direction for counter
			}

			animationRef.current = requestAnimationFrame(animate)
		}

		animate()

		return () => {
			if (animationRef.current) {
				cancelAnimationFrame(animationRef.current)
			}
			ctx.clearRect(0, 0, canvas.width, canvas.height)
		}
	}, [services, activeServiceIndex])

	// Calculate positions once and store them
	const servicePositions = services.map((_, i) => {
		const angle = (i * 2 * Math.PI) / services.length
		const radius = 35 // Percentage from center
		return {
			x: 50 + radius * Math.cos(angle),
			y: 50 + radius * Math.sin(angle)
		}
	})

	return (
		<section className={`self-stretch flex flex-col items-start justify-start pt-0 px-6 pb-[40px] lg:pb-[120px] max-w-full text-left text-xl font-plus-jakarta-sans bg-[#F9F9F9] ${className}`}>
			<div className="flex flex-row items-start justify-between w-full max-w-7xl mx-auto">
				{/* Left side - Network Diagram */}
				<div className="relative w-[50%] h-[500px]">
					<canvas ref={canvasRef} width={500} height={500} className="absolute top-0 left-0 w-full h-full" />

					{/* Derisky center box */}
					<div className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-xl shadow-[0_4px_24px_rgba(130,91,248,0.4)] border-2 border-[#825bf8] p-6 transition-all duration-500 animate-pulse-scale`}>
						<img src="/frame.svg" alt="Derisky" className="w-12 h-12" />
						<style jsx>{`
							@keyframes pulse-scale {
								0% {
									transform: translate(-50%, -50%) scale(1);
									box-shadow: 0 4px 24px rgba(130, 91, 248, 0.4);
								}
								50% {
									transform: translate(-50%, -50%) scale(1.1);
									box-shadow: 0 4px 32px rgba(130, 91, 248, 0.6);
								}
								100% {
									transform: translate(-50%, -50%) scale(1);
									box-shadow: 0 4px 24px rgba(130, 91, 248, 0.4);
								}
							}
							.animate-pulse-scale {
								animation: pulse-scale 3s ease-in-out infinite;
							}
						`}</style>
					</div>

					{/* Service boxes */}
					{services.map((service, i) => {
						const position = servicePositions[i]

						return (
							<div
								key={service.name}
								className={`absolute transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-xl shadow-[0_4px_24px_rgba(0,0,0,0.1)] border p-3 transition-all duration-500 ${i === activeServiceIndex ? 'border-[#825bf8] shadow-[0_4px_24px_rgba(130,91,248,0.2)] scale-125' : 'border-gray-100 hover:shadow-[0_4px_24px_rgba(130,91,248,0.2)] hover:border-[#825bf8] hover:scale-110'}`}
								style={{
									top: `${position.y}%`,
									left: `${position.x}%`
								}}
								onMouseEnter={() => setActiveServiceIndex(i)}>
								<img src={service.logo} alt={service.name} className="w-8 h-8 object-contain" />
							</div>
						)
					})}
				</div>

				{/* Right side - Text and Service List */}
				<div className="flex flex-col items-start justify-start w-[50%] px-12">
					<h3 className="text-3xl font-bold mt-12 mb-6 text-gray-800 bg-gradient-to-r from-[#825bf8] to-[#5f44f6] bg-clip-text text-transparent">Seamless Integration with Leading Platforms</h3>
					<p className="text-lg text-gray-600 mb-8 leading-relaxed">Connect your workflow with industry-leading platforms. Our integration network enables smooth data flow and enhanced productivity across your favorite tools.</p>

					<div className="flex flex-wrap gap-3 w-full">
						{allServices.map((service, i) => (
							<div key={service.name} className="inline-flex items-center gap-2 py-2.5 px-4 bg-white rounded-lg shadow-sm border border-gray-100 hover:border-[#825bf8] hover:shadow-[0_8px_32px_rgba(130,91,248,0.3)] transition-all duration-300 transform hover:scale-110">
								<img src={service.logo} alt={service.name} className="w-5 h-5 object-contain" />
								<span className="text-sm font-medium text-gray-700 whitespace-nowrap">{service.name}</span>
							</div>
						))}
					</div>
				</div>
			</div>
		</section>
	)
}

IntegrationNetwork.propTypes = {
	className: PropTypes.string
}

export default IntegrationNetwork
