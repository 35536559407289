import Header from '../components/Header'
import Benefits from '../components/Benefits'
import Media from '../components/Media'
import IntegrationLogos from '../components/IntegrationLogos'
import Decisions from '../components/Decisions'
import HowItWorks from '../components/HowItWorks'
import FrameComponent1 from '../components/FrameComponent1'
import Testimonials from '../components/Testimonials'
import RssIntegration from '../components/RssIntegration'
import ButtonPrimary from '../components/ButtonPrimary'
import FrameComponent from '../components/FrameComponent'
import Navigation1 from '../components/Navigation1'
import IntegrationNetwork from '../components/IntegrationNetwork'
import {useNavigate} from 'react-router-dom'
import {Testimonialto} from 'components/testimonialto/testimonialto'
import {SocialRow} from '../../../components/socialrow/socialrow'
import VentureCapitalSection from '../components/VentureCapitalSection.js'
import {useEffect, useState, useRef} from 'react'

const WebVersion = () => {
	const navigate = useNavigate()
	const [showPopup, setShowPopup] = useState(false)
	const ventureRef = useRef(null)

	useEffect(() => {
		// Check if popup was previously closed
		const popupClosed = sessionStorage.getItem('popupClosed')

		const handleScroll = () => {
			if (window.scrollY > 300 && !showPopup && !popupClosed) {
				setShowPopup(true)
			}
		}

		window.addEventListener('scroll', handleScroll)
		return () => window.removeEventListener('scroll', handleScroll)
	}, [showPopup])

	const scrollToVenture = () => {
		ventureRef.current?.scrollIntoView({behavior: 'smooth'})
		setShowPopup(false)
		sessionStorage.setItem('popupClosed', 'true')
	}

	const closePopup = () => {
		setShowPopup(false)
		sessionStorage.setItem('popupClosed', 'true')
	}

	return (
		<div className="w-full relative bg-base-white overflow-hidden flex flex-col items-start justify-start pt-0 px-0 pb-0 lg:pb-4 box-border leading-[normal] tracking-[normal] text-left text-lg text-gray-900 font-plus-jakarta-sans mq1350:h-auto">
			{/* Decorative background elements */}
			<div className="absolute inset-0 z-0">
				<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[500px] h-[500px] bg-gradient-to-r from-purple-100/30 to-blue-100/30 rounded-full blur-3xl" />
				<div className="absolute top-0 right-0 w-72 h-72 bg-gradient-to-br from-indigo-100/40 to-purple-100/40 rounded-full blur-2xl" />
			</div>

			{/* Fancy Dark Popup */}
			{showPopup && (
				<div className="fixed bottom-8 left-8 bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 p-8 rounded-2xl z-50 w-[320px] shadow-2xl animate-slide-in border border-gray-700/50 backdrop-blur-lg">
					<button onClick={closePopup} className="absolute top-4 right-4 text-gray-400 hover:text-white transition-colors text-xl">
						×
					</button>

					<div className="space-y-6">
						<div>
							<span className="inline-block px-3 py-1 rounded-full text-xs font-medium bg-gradient-to-r from-purple-500 to-blue-500 text-white">Limited Time Offer</span>
							<h3 className="text-2xl font-bold text-white mt-3 bg-gradient-to-r from-purple-400 via-pink-400 to-blue-400 bg-clip-text text-transparent">Venture Capital Mastery</h3>
						</div>

						<p className="text-sm text-gray-300 leading-relaxed">Learn how to make your startup attractive to investors. Get 50% off today!</p>

						<div>
							<button onClick={scrollToVenture} className="w-full bg-gradient-to-r from-purple-500 to-blue-500 text-white px-6 py-3 rounded-xl font-medium hover:from-purple-600 hover:to-blue-600 transform hover:scale-[1.02] transition-all duration-200 shadow-lg shadow-purple-500/25">
								Get Started
							</button>
							<div className="flex items-center gap-2 justify-center mt-4">
								<div className="w-2 h-2 rounded-full bg-green-500 animate-pulse" />
								<span className="text-xs text-gray-400">Limited spots available</span>
							</div>
						</div>
					</div>
				</div>
			)}

			<Header />
			<Benefits />
			<Media />
			<HowItWorks />
			<IntegrationNetwork />
			<FrameComponent1 />
			<Decisions />
			<Testimonialto />
			<div ref={ventureRef}>
				<VentureCapitalSection />
			</div>
			<RssIntegration />

			<section className="self-stretch flex flex-col items-start justify-start pt-0 px-0 box-border gap-[81px] max-w-full shrink-0 mq450:gap-[20px] mq450:pb-[207px] mq450:box-border mq800:gap-[40px] mq800:pb-[319px] mq800:box-border mq1350:pb-[491px] mq1350:box-border">
				<Navigation1 />
			</section>
		</div>
	)
}

export default WebVersion
