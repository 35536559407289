import axios from 'axios';
import { Card, Form, Grid, Icon, Loader, SubNavbar, Tooltip, useLocation } from 'components/lib';
import Spacer from 'components/spacer/spacer';
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { LogoCard } from "../../components/card/projectcard";
import AllIntegrations from "./integrations/AllIntegrations";

export const Integration = () => {
	const [product, setProduct] = useState(null);
	const [integration, setIntegration] = useState({});
	const [loading, setLoading] = useState(false);
	const [selectedIntegration, setSelectedIntegration] = useState(null);
	const path = window.location.pathname;
	const modalRef = useRef(null);

	// Remove '/integration/' and extract the product_id
	const product_id = path.replace('/integration/', '');

	useEffect(() => {
		const fetchData = async () => {
			await getProduct();
			await getIntegrations();
		};
		fetchData();
	}, []);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (modalRef.current && !modalRef.current.contains(event.target)) {
				setIsModalOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const getIntegrations = async () => {
		if (product_id) {
			setLoading(true);
			try {
				const res = await axios.get(`/api/integration/${product_id}`);
				if (res.data) {
					setIntegration(res.data.data);
				}
			} catch (err) {
				console.error('Error fetching product', err);
			} finally {
				setLoading(false);
			}
		}
	}

	const getProduct = async () => {
		if (product_id) {
			setLoading(true);
			try {
				const res = await axios.get(`/api/product/${product_id}`);
				if (res.data) {
					setProduct(res.data.data);
				}
			} catch (err) {
				console.error('Error fetching product', err);
			} finally {
				setLoading(false);
			}
		}
	};

	const [isModalOpen, setIsModalOpen] = useState(false);

	const toggleModal = (integration = null) => {
		setSelectedIntegration(integration);
		setIsModalOpen(!isModalOpen);
	};

	const renderModalContent = () => {
		if (!selectedIntegration) return null;

		return (
			<div className="p-8 pb-48">
				<h2 className="text-2xl font-bold mb-6 bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
					{selectedIntegration.label} Integration
				</h2>
				<form className="space-y-8">
					{selectedIntegration.label === 'Google Analytics' && (
						<>
							<div className="group">
								<label className="block text-sm font-medium text-gray-700 mb-2 group-hover:text-blue-600 transition-colors duration-200">
									Measurement ID *
								</label>
								<input
									type="text"
									placeholder="e.g. G-QPD2NX5E3C"
									className="w-full px-4 py-3 border border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500/40 transition-all duration-200 hover:border-blue-500/40"
								/>
								<p className="mt-2 text-sm text-gray-500">Create a property in your Google Analytics account. You can find your measurement ID there.</p>
							</div>

							<div className="group">
								<label className="block text-sm font-medium text-gray-700 mb-2 group-hover:text-blue-600 transition-colors duration-200">
									Google Optimize Container ID
								</label>
								<input
									type="text"
									placeholder="e.g. GTM-A1B2CD"
									className="w-full px-4 py-3 border border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500/40 transition-all duration-200 hover:border-blue-500/40"
								/>
								<p className="mt-2 text-sm text-gray-500">Create a new container in your Google Optimize account then paste the container ID here.</p>
							</div>

							<div className="group">
								<label className="block text-sm font-medium text-gray-700 mb-2 group-hover:text-blue-600 transition-colors duration-200">
									Private Key (JSON)
								</label>
								<div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-100 border-dashed rounded-xl hover:border-blue-500/40 transition-colors duration-200 bg-gray-50/50">
									<div className="space-y-2 text-center">
										<svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48">
											<path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
										</svg>
										<div className="flex text-sm text-gray-600 justify-center">
											<label className="relative cursor-pointer bg-white rounded-lg px-4 py-2 font-medium text-blue-600 hover:text-blue-500 hover:bg-blue-50 transition-all duration-200">
												<span>Upload a file</span>
												<input type="file" className="sr-only" accept=".json" />
											</label>
										</div>
										<p className="text-xs text-gray-500">JSON file only</p>
									</div>
								</div>
								<p className="mt-2 text-sm text-gray-500">Create a private key under Service Accounts in Google Cloud Console and upload.</p>
							</div>
						</>
					)}

					<button
						type="submit"
						className="w-full flex justify-center py-3 px-4 border border-transparent rounded-xl shadow-sm text-sm font-medium text-white bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-200 transform hover:scale-[1.02]"
					>
						Save Settings
					</button>
				</form>
			</div>
		);
	};

	return (
		<Fragment>
			<div className="relative">
				<div className={`fixed inset-y-0 right-0 w-[28rem] z-50 transition-all duration-300 ease-in-out transform ${isModalOpen ? 'translate-x-0' : 'translate-x-full'}`}>
					{isModalOpen && (
						<div ref={modalRef} className="h-full m-4 rounded-2xl border border-gray-100 bg-white/95 backdrop-blur-lg relative overflow-y-auto shadow-[0_8px_30px_rgb(0,0,0,0.12)]">
							<button
								onClick={() => toggleModal()}
								className="absolute right-6 top-6 text-gray-500 hover:text-gray-700 transition-colors duration-200"
							>
								<svg className="w-6 h-6 hover:rotate-90 transition-transform duration-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
								</svg>
							</button>
							{renderModalContent()}
						</div>
					)}
				</div>
			</div>
			<div className={`relative ${isModalOpen ? 'opacity-20' : ''}`}>
				{loading && <Loader fullScreen />}
				{product && (
					<LogoCard
						title={product.name}
						subtitle={product.description}
						logo={product.logo}
					/>
				)}
				<Spacer height="h-[1.188rem]" />
				<SubNavbar active="integration" id={product_id} />
				<Spacer height="h-[1.125rem]" />

				<AllIntegrations 
					getIntegrations={getIntegrations} 
					integration={integration} 
					product_id={product_id}
					onCardClick={toggleModal}
				/>
			</div>
		</Fragment>
	);
};
