/***
*
*   HERO
*   High-converting hero section with social proof, urgency, and clear value props
*
*   PROPS
*   title: h1 title
*   tagline: h2 tagline
*   image: source object
*   alt: img alt tag
*
**********/

import {Animate, Content, Button, ClassHelper, Badge, Grid, Icon} from 'components/lib';
import Style from './hero.tailwind.js';
import CountDown from 'count-down-react';
import React from "react";

export function Hero(props) {
    const today = new Date();
    const lastDayOfMonth = today.getDay() > 15 ?
        new Date(today.getFullYear(), today.getMonth(), 15):
        new Date(today.getFullYear(), today.getMonth()+1, 1);

    function addZeroWhenOneDigit (number) {
        let formattedNumber = (number < 10 ? '0' : '') + number;
        return formattedNumber;
    }

    const heroStyle = ClassHelper(Style, {
        hero: true,
        className: props.className,
    });

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return "Offer expired";
        } else {
            return (
                <div className="flex-1 justify-end">
                    <div className='flex justify-end items-center gap-2'>
                        <span className='text-3xl line-through text-gray-500'>$799</span>
                        <span className='text-4xl font-bold text-green-600'>FREE</span>
                    </div>
                    <div className='flex justify-end mt-1 text-red-600 font-semibold'>
                        Limited Time Offer - Expires {lastDayOfMonth.toLocaleDateString("us-en")}
                    </div>

                    <ul className="flex justify-end mt-3">
                        <li className="shadow-xl mr-1 p-2 text-sm text-center bg-brand-400 text-white rounded">
                            <div className="text-3xl align-middle font-bold" id="days">{addZeroWhenOneDigit(days)}</div>days
                        </li>
                        <li className="shadow-xl mr-1 p-2 text-sm text-center bg-brand-400 text-white rounded">
                            <div className="text-3xl font-bold" id="hours">{addZeroWhenOneDigit(hours)}</div>hours
                        </li>
                        <li className="shadow-xl mr-1 p-2 text-sm text-center bg-brand-400 text-white rounded">
                            <div className="text-3xl font-bold" id="minutes">{addZeroWhenOneDigit(minutes)}</div>mins
                        </li>
                        <li className="shadow-xl mr-1 p-2 text-sm text-center bg-brand-400 text-white rounded">
                            <div className="text-3xl font-bold" id="seconds">{addZeroWhenOneDigit(seconds)}</div>secs
                        </li>
                    </ul>
                    <div className='float-right text-sm mt-2 animate-pulse bg-red-600 text-white px-4 py-2 rounded-full'>
                        ⚡ Only {addZeroWhenOneDigit(days)*3} spots remaining!
                    </div>
                </div>
            );
        }
    };

    return (
        <section className={heroStyle}>
            <Content>
                <Animate type='pop'>
                    <section className={Style.blurb}>
                        {props.badge && 
                            <Badge className="uppercase bg-derisky-rose mb-4 shadow px-4 text-brand-400 animate-pulse" 
                                text={props.badge} />
                        }
                        
                        <h1 className={`${Style.title} text-4xl md:text-5xl lg:text-6xl font-bold mb-6`}>
                            {props.title}
                        </h1>

                        <h2 className={`${Style.tagline} text-xl md:text-2xl text-gray-600 mb-8`}>
                            {props.tagline}
                        </h2>

                        <div className='grid md:grid-cols-3 gap-6 mb-8'>
                            <div className='transform hover:scale-105 transition-all duration-300 bg-white rounded-lg p-6 shadow-lg border-t-4 border-derisky-rose'>
                                <h4 className='flex items-center gap-3 font-bold text-gray-800'>
                                    <Icon image='fast-forward' color='#6145F7' />
                                    <span>5X FASTER</span>
                                </h4>
                                <p className='mt-2 text-gray-600'>Accelerate your time-to-market</p>
                            </div>
                            <div className='transform hover:scale-105 transition-all duration-300 bg-white rounded-lg p-6 shadow-lg border-t-4 border-derisky-rose'>
                                <h4 className='flex items-center gap-3 font-bold text-gray-800'>
                                    <Icon image='rotate-ccw' color='#6145F7' />
                                    <span>15X ROI</span>
                                </h4>
                                <p className='mt-2 text-gray-600'>Proven returns over 3 years</p>
                            </div>
                            <div className='transform hover:scale-105 transition-all duration-300 bg-white rounded-lg p-6 shadow-lg border-t-4 border-derisky-rose'>
                                <h4 className='flex items-center gap-3 font-bold text-gray-800'>
                                    <Icon image='dollar-sign' color='#6145F7' />
                                    <span>SAVE NOW</span>
                                </h4>
                                <p className='mt-2 text-gray-600'>Immediate cost reduction</p>
                            </div>
                        </div>

                        <div className="flex flex-col md:flex-row justify-between items-center gap-6">
                            <Button 
                                goto='/signup' 
                                text='Start Free Trial → No Credit Card Required' 
                                color="derisky-rose"
                                big 
                                className={`${Style.button} transform hover:scale-105 transition-all duration-300`} 
                            />
                            {props.countdown && 
                                <CountDown
                                    date={lastDayOfMonth}
                                    renderer={renderer}
                                />
                            }
                        </div>

                        {props.showLaurens && 
                            <div className="mt-8 flex items-center bg-gray-50 p-4 rounded-lg">
                                <img 
                                    src="/Add/1710842465321.jpeg" 
                                    alt="Laurens Lang - Founder" 
                                    className="w-12 h-12 shadow-xl rounded-full" 
                                />
                                <div className='ml-4'>
                                    <p className='font-semibold'>Laurens Lang M.Sc, MBA</p>
                                    <a 
                                        href="http://linkedin.com/in/laurenslang" 
                                        className='text-blue-600 hover:text-blue-800' 
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Founder & CEO
                                    </a>
                                </div>
                            </div>
                        }
                    </section>

                    <h4 className='text-center font-semibold text-gray-600 mt-12 mb-4'>
                        TRUSTED BY LEADING PUBLICATIONS
                    </h4>
                </Animate>
            </Content>

            <div className={`${Style.marquee} bg-gray-50 py-8`}>
                <div className={Style.marqueeContent}>
                    <a target='_blank' rel="noopener noreferrer" className={`${Style.newslink} ${Style.marqueeItem} opacity-70 hover:opacity-100 transition-opacity`} href='http://entrepreneur.com'>
                        <img className={Style.newsimage} src='/news/entrepreneur.webp' alt='Featured in Entrepreneur Magazine' />
                    </a>
                    <a target='_blank' rel="noopener noreferrer" className={`${Style.newslink} ${Style.marqueeItem} opacity-70 hover:opacity-100 transition-opacity`} href='http://bloomberg.com'>
                        <img className={Style.newsimage} src='/news/bloomberg.png' alt='Featured in Bloomberg' />
                    </a>
                    <a target='_blank' rel="noopener noreferrer" className={`${Style.newslink} ${Style.marqueeItem} opacity-70 hover:opacity-100 transition-opacity`} href='http://forbes.com'>
                        <img className={Style.newsimage} src='/news/forbes.webp' alt='Featured in Forbes' />
                    </a>
                    <a target='_blank' rel="noopener noreferrer" className={`${Style.newslink} ${Style.marqueeItem} opacity-70 hover:opacity-100 transition-opacity`} href='http://businessinsider.com'>
                        <img className={Style.newsimage} src='/news/businessinsider.webp' alt='Featured in Business Insider' />
                    </a>
                    <a target='_blank' rel="noopener noreferrer" className={`${Style.newslink} ${Style.marqueeItem} opacity-70 hover:opacity-100 transition-opacity`} href='http://finance.yahoo.com'>
                        <img className={Style.newsimage} src='/news/yahoo-finance_white.png' alt='Featured in Yahoo Finance' />
                    </a>
                </div>
            </div>
        </section>
    );
}