import PropTypes from 'prop-types'
import { useState, useEffect, useRef } from 'react'
import { Icon } from 'components/lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartSimple, faFilePdf, faFileExcel, faFilePowerpoint, faArrowRight } from '@fortawesome/free-solid-svg-icons'

const HowItWorks = ({ className = '' }) => {
	const [state, setState] = useState({
		activeStep: 0,
		showFirst: false,
		showSecond: false,
		showThird: false,
		isDragging: false,
		isDropped: false,
		rightPanelState: 'relative',
		showUploadForm: true,
		showDoc1: false,
		showDoc2: false,
		showDoc3: false,
		showLoading: false,
		showTable: false
	})

	const refs = {
		section: useRef(null),
		steps: useRef([]),
		rightPanel: useRef(null)
	}

	const steps = [
		{
			title: 'Upload & Hypothesize',
			description: 'Transform your ideas into actionable insights with our advanced AI analysis. Our platform intelligently processes your innovation projects, identifying hidden risks and validating hypotheses in real-time.',
			details: 'Our cutting-edge AI technology meticulously analyzes your project data, uncovering potential risks and opportunities that human analysis might miss. By cross-referencing your hypotheses against extensive market data and industry trends, we help you build an unshakeable foundation for success. Experience the power of AI-driven decision-making that has helped countless innovators achieve breakthrough results.',
			features: [
				{ icon: 'M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z', text: 'Smart document analysis' },
				{ icon: 'M13 10V3L4 14h7v7l9-11h-7z', text: 'Real-time processing' },
				{ icon: 'M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2', text: 'Multiple file formats' }
			]
		},
		{
			title: 'Experiment & Measure',
			description: 'Accelerate your innovation journey with precision-engineered experiments and real-time analytics. Our AI assistant guides you through each step, ensuring maximum learning with minimum resource investment.',
			details: 'Leverage our sophisticated AI engine to design and execute perfectly calibrated experiments. Our platform automatically collects and analyzes relevant data points, providing you with crystal-clear insights and actionable metrics. Watch your innovation progress unfold through beautiful, intuitive dashboards that transform complex data into strategic advantage.',
			features: [
				{ icon: 'M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z', text: 'Real-time analytics' },
				{ icon: 'M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z', text: 'Interactive dashboards' },
				{ icon: 'M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z', text: 'Progress tracking' }
			]
		},
		{
			title: 'Data-driven portfolio decisions',
			description: 'Make confident investment decisions backed by comprehensive AI analysis and predictive modeling. Our platform aligns market opportunities with your strategic goals for optimal resource allocation.',
			details: 'Transform your investment strategy with our AI-powered decision engine. By synthesizing experimental results, market conditions, and your strategic objectives, we provide you with clear, actionable investment recommendations.',
			features: [
				{ icon: 'M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z', text: 'Predictive modeling' },
				{ icon: 'M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z', text: 'ROI optimization' },
				{ icon: 'M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z', text: 'Strategic planning' }
			]
		}
	]

	const updateState = updates => setState(prev => ({ ...prev, ...updates }))

	useEffect(() => {
		const handleScroll = () => {
			if (!refs.section.current || !refs.steps.current.length || !refs.rightPanel.current || window.innerWidth < 1024) return

			const sectionRect = refs.section.current.getBoundingClientRect()
			const { bottom: lastStepBottom } = refs.steps.current[2].getBoundingClientRect()
			const { top: firstStepTop } = refs.steps.current[0].getBoundingClientRect()
			const { height: rightPanelHeight } = refs.rightPanel.current.getBoundingClientRect()
			const relativeTop = firstStepTop + window.scrollY

			let newState = state.rightPanelState
			// Check if we're above the section
			if (sectionRect.top > 0) {
				newState = 'relative'
			}
			// Check if we're below the last step
			else if (lastStepBottom <= (window.innerHeight / 2 + rightPanelHeight / 2)) {
				newState = 'hidden'
			}
			// Check if we're in the middle section
			else if (window.scrollY >= relativeTop - window.innerHeight / 2) {
				newState = 'fixed'
			} else {
				newState = 'relative'
			}

			if (newState !== state.rightPanelState) {
				updateState({ rightPanelState: newState })
			}
		}

		const throttledScroll = () => {
			if (!window.requestAnimationFrame) {
				setTimeout(handleScroll, 16)
				return
			}
			window.requestAnimationFrame(handleScroll)
		}

		window.addEventListener('scroll', throttledScroll)
		window.addEventListener('resize', throttledScroll)
		return () => {
			window.removeEventListener('scroll', throttledScroll)
			window.removeEventListener('resize', throttledScroll)
		}
	}, [state.rightPanelState])

	useEffect(() => {
		const observer = new IntersectionObserver(
			entries => {
				entries.forEach(entry => {
					const index = refs.steps.current.findIndex(ref => ref === entry.target)
					if (index === -1) return

					if (entry.isIntersecting) {
						updateState({ activeStep: index })
					} else if (entry.boundingClientRect.top > 0) {
						updateState({ activeStep: Math.max(0, index - 1) })
					}
				})
			},
			{ threshold: 0.7, rootMargin: '-10% 0px' }
		)

		refs.steps.current.forEach(ref => ref && observer.observe(ref))
		return () => refs.steps.current.forEach(ref => ref && observer.unobserve(ref))
	}, [])

	useEffect(() => {
		if (state.activeStep === 0) {
			const timers = [
				setTimeout(() => updateState({ showDoc1: true }), 1000),
				setTimeout(() => updateState({ showDoc2: true }), 2000),
				setTimeout(() => updateState({ showDoc3: true }), 3000),
				setTimeout(() => {
					updateState({ 
						showUploadForm: false,
						showLoading: true,
						showFirst: true
					})
				}, 4000),
				setTimeout(() => updateState({ showSecond: true }), 5000),
				setTimeout(() => updateState({ showThird: true }), 6000),
				setTimeout(() => {
					updateState({
						showLoading: false,
						showTable: true
					})
				}, 7000)
			]
			return () => timers.forEach(clearTimeout)
		}
		updateState({ 
			showUploadForm: true,
			showDoc1: false,
			showDoc2: false,
			showDoc3: false,
			showLoading: false,
			showFirst: false,
			showSecond: false,
			showThird: false,
			showTable: false
		})
	}, [state.activeStep])

	const renderUploadSection = () => (
		<div className={`transition-opacity h-[350px] duration-500 ${state.activeStep === 0 ? 'opacity-100' : 'opacity-0'}`}>
			{state.showUploadForm && (
				<div 
					className="relative border-2 border-dashed border-gray-200 rounded-lg p-8 text-center transition-all hover:border-indigo-400 group hover:shadow-lg"
				>
					<div className="flex flex-col items-center gap-4">
						{state.showDoc1 && (
							<div className="flex items-center justify-between bg-indigo-100 backdrop-blur-sm rounded-lg px-4 py-3 shadow-lg border border-indigo-200 w-full">
								<div className="flex items-center">
									<FontAwesomeIcon icon={faFilePdf} className="w-5 h-5 text-indigo-600 mr-2" />
									<span className="text-sm text-indigo-700 font-semibold">product_document.pdf</span>
								</div>
								<span className="text-sm text-indigo-700">2.1MB</span>
							</div>
						)}
						{state.showDoc2 && (
							<div className="flex items-center justify-between bg-indigo-100 backdrop-blur-sm rounded-lg px-4 py-3 shadow-lg border border-indigo-200 w-full">
								<div className="flex items-center">
									<FontAwesomeIcon icon={faFilePowerpoint} className="w-5 h-5 text-indigo-600 mr-2" />
								<span className="text-sm text-indigo-700 font-semibold">Pitch Deck.pptx</span>
								</div>
								<span className="text-sm text-indigo-700">2.1MB</span>
							</div>
						)}
						{state.showDoc3 && (
							<div className="flex items-center justify-between bg-indigo-100 backdrop-blur-sm rounded-lg px-4 py-3 shadow-lg border border-indigo-200 w-full">
								<div className="flex items-center">
									<FontAwesomeIcon icon={faFileExcel} className="w-5 h-5 text-indigo-600 mr-2" />
									<span className="text-sm text-indigo-700 font-semibold">Business Case.xlsx</span>
								</div>
								<span className="text-sm text-indigo-700">2.1MB</span>
							</div>
						)}
						<svg className={`w-16 h-16 transition-colors duration-500 ${state.showFirst ? 'text-indigo-500' : 'text-gray-400'} group-hover:text-indigo-500`} fill="none" stroke="currentColor" viewBox="0 0 24 24">
							<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
						</svg>

						<div className="space-y-2">
							<p className="text-gray-700 font-medium">Drop your innovation documents here or <span className="text-indigo-600 hover:text-indigo-700 cursor-pointer">browse</span></p>
							<p className="text-sm text-gray-500">Supports PDF, DOC, DOCX, TXT up to 50MB</p>
						</div>
					</div>
				</div>
			)}

			{state.showLoading && renderLoadingIndicator()}

			{state.showTable && (
				<div className="mt-8">
					<table className="min-w-full divide-y divide-gray-200">
						<thead>
							<tr>
								<th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Document Name</th>
								<th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
								<th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
							</tr>
						</thead>
						<tbody className="bg-white divide-y divide-gray-200">
							<tr>
								<td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
									<div className="flex items-center">
										<FontAwesomeIcon icon={faFilePdf} className="w-5 h-5 text-indigo-600 mr-2" />
										product_document.pdf
									</div>
								</td>
								<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">PDF</td>
								<td className="px-6 py-4 whitespace-nowrap text-sm text-green-600">Analyzed</td>
							</tr>
							<tr>
								<td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
									<div className="flex items-center">
										<FontAwesomeIcon icon={faFilePowerpoint} className="w-5 h-5 text-indigo-600 mr-2" />
										Pitch Deck.pptx
									</div>
								</td>
								<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">PowerPoint</td>
								<td className="px-6 py-4 whitespace-nowrap text-sm text-green-600">Analyzed</td>
							</tr>
							<tr>
								<td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
									<div className="flex items-center">
										<FontAwesomeIcon icon={faFileExcel} className="w-5 h-5 text-indigo-600 mr-2" />
										Business Case.xlsx
									</div>
								</td>
								<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">Excel</td>
								<td className="px-6 py-4 whitespace-nowrap text-sm text-green-600">Analyzed</td>
							</tr>
						</tbody>
					</table>
				</div>
			)}
		</div>
	)

	const renderLoadingIndicator = () => (
		<div className={`transition-all duration-500 ${state.showLoading ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'}`}>
			<div className="animate-pulse flex items-center justify-center space-x-4 mt-8">
				{[0, 0.2, 0.4].map((delay, i) => (
					<div 
						key={i}
						className={`w-5 h-5 rounded-full animate-bounce ${
							['bg-indigo-500', 'bg-purple-500', 'bg-pink-500'][i]
						}`}
						style={{ animationDelay: `${delay}s` }}
					/>
				))}
			</div>
		</div>
	)

	const renderAnalysisStatus = () => (
		<div className="space-y-3 mt-8">
			{[
				{ show: state.showFirst, text: 'Analyzing document structure and content...' },
				{ show: state.showSecond, text: 'Extracting key insights and patterns...' },
				{ show: state.showThird, text: 'Generating strategic recommendations...' }
			].map(({ show, text }, index) => (
				<div key={index} className={`transition-all duration-500 ${show ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'}`}>
					<span className="text-gray-700 font-mono animate-typing overflow-hidden whitespace-nowrap text-sm">
						{text}
					</span>
				</div>
			))}
		</div>
	)

	const renderUploadAndHypothesize = () => (
		<div className="space-y-6">
			{renderUploadSection()}
			{state.showLoading && renderAnalysisStatus()}
		</div>
	)
	const renderStepContent = step => {
		if (step === steps[1]) {
			return (
				<div className="h-[300px] relative">
					{/* Top sentence box with hypothesis label */}
					<div className="absolute top-0 left-1/2 -translate-x-1/2 w-full max-w-2xl">
						<div className="bg-indigo-50 ml-4 text-xs font-semibold text-indigo-600 p-2 rounded-t-lg inline-flex items-center">
							<FontAwesomeIcon icon={faArrowRight} className="w-3 h-3 mr-1" />
							HYPOTHESIS 1
						</div>
						<div className="bg-white p-4 shadow-lg border border-indigo-100 transition-all duration-300 hover:shadow-xl rounded-lg w-full">
							<p className="text-left text-gray-600 text-lg">
								"Our new feature will increase user engagement by 40%"
							</p>
						</div>
					</div>

					<div className="absolute top-40 left-1/2 -translate-x-1/2 w-full h-full">
						<svg viewBox="0 0 400 300" className="w-full h-full">
							<defs>
								<linearGradient id="risk-gradient" x1="0" y1="0" x2="1" y2="0">
									<stop offset="0%" stopColor="#4F46E5" />
									<stop offset="100%" stopColor="#7C3AED" />
								</linearGradient>
								<linearGradient id="line-gradient" x1="0" y1="0" x2="0" y2="1">
									<stop offset="0%" stopColor="#4F46E5" />
									<stop offset="50%" stopColor="#7C3AED" />
									<stop offset="100%" stopColor="#EC4899" />
								</linearGradient>
								<filter id="box-shadow">
									<feDropShadow dx="0" dy="2" stdDeviation="3" floodOpacity="0.2"/>
								</filter>
								<filter id="glow">
									<feGaussianBlur stdDeviation="2" result="coloredBlur"/>
									<feMerge>
										<feMergeNode in="coloredBlur"/>
										<feMergeNode in="SourceGraphic"/>
									</feMerge>
								</filter>
							</defs>

							{/* Derisky Logo Box */}
							<g transform="translate(120, 40)" className="cursor-move">
								<rect x="0" y="0" width="80" height="80" rx="10" fill="white" stroke="#E5E7EB" strokeWidth="2" filter="url(#box-shadow)" />
								<image href="/frame.svg" x="10" y="10" width="60" height="60" />
							</g>

							{/* Risk Meter Box */}
							<g transform="translate(240, 40)" className="cursor-move">
								<rect x="0" y="0" width="80" height="80" rx="10" fill="white" stroke="#E5E7EB" strokeWidth="2" filter="url(#box-shadow)" />
								<g transform="translate(40, 40) rotate(180)">
									<circle cx="0" cy="0" r="30" fill="none" stroke="#e5e7eb" strokeWidth="6" strokeLinecap="round" strokeDasharray="190" strokeDashoffset="55" />
									<circle cx="0" cy="0" r="30" fill="none" stroke="url(#risk-gradient)" strokeWidth="6" strokeLinecap="round" strokeDasharray="190" strokeDashoffset="95">
										<animate 
											attributeName="stroke-dashoffset"
											values="160;140;160"
											dur="3s"
											repeatCount="indefinite"
										/>
									</circle>
									<text x="0" y="-5" textAnchor="middle" className="text-sm font-bold fill-gray-700" transform="rotate(180)">
										<animate
											attributeName="textContent"
											values="10%;11%;12%;13%;14%;15%;16%;15%;14%;13%;12%;11%;10%"
											dur="3s"
											repeatCount="indefinite"
										/>
									</text>
									<text x="0" y="10" textAnchor="middle" className="text-xs fill-gray-500" transform="rotate(180)">RISK</text>
								</g>
							</g>

							{/* Integration Boxes */}
							<g transform="translate(40, 20)" className="cursor-move">
								<rect x="0" y="0" width="50" height="50" rx="8" fill="white" stroke="#E5E7EB" strokeWidth="2" filter="url(#box-shadow)" />
								<image href="https://www.rudderstack.com/integration/_next/image/?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2F97bpcflt%2Fproduction%2F90d44122f4613b945d9ebc41e373beba975f82bb-83x80.svg%3Fw%3D83%26fm%3Dwebp%26fit%3Dfill%26dpr%3D2&w=2048&q=75" x="5" y="5" width="40" height="40" />
							</g>

							<g transform="translate(40, 90)" className="cursor-move">
								<rect x="0" y="0" width="50" height="50" rx="8" fill="white" stroke="#E5E7EB" strokeWidth="2" filter="url(#box-shadow)" />
								<image href="https://axiom.ai/automate-images/meta-logo.png" x="5" y="5" width="40" height="40" />
							</g>

							{/* Connection Lines */}
							<path 
								d="M 90 45 L 105 45 L 105 80 L 120 80" 
								stroke="url(#line-gradient)" 
								strokeWidth="3" 
								strokeDasharray="4"
								fill="none" 
								strokeLinecap="round"
								markerEnd="url(#arrowhead)"
								filter="url(#glow)"
							>
								<animate 
									attributeName="stroke-dashoffset"
									from="8"
									to="0"
									dur="1s"
									repeatCount="indefinite"
								/>
							</path>
							<path 
								d="M 200 80 L 240 80" 
								stroke="url(#line-gradient)" 
								strokeWidth="3" 
								strokeDasharray="4"
								fill="none" 
								strokeLinecap="round"
								markerEnd="url(#arrowhead)"
								filter="url(#glow)"
							>
								<animate 
									attributeName="stroke-dashoffset"
									from="8"
									to="0"
									dur="1s"
									repeatCount="indefinite"
								/>
							</path>
							<path 
								d="M 90 115 L 105 115 L 105 80 L 120 80" 
								stroke="url(#line-gradient)" 
								strokeWidth="3" 
								strokeDasharray="4"
								fill="none" 
								strokeLinecap="round"
								markerEnd="url(#arrowhead)"
								filter="url(#glow)"
							>
								<animate 
									attributeName="stroke-dashoffset"
									from="8"
									to="0"
									dur="1s"
									repeatCount="indefinite"
								/>
							</path>
							<path 
								d="M 160 40 L 160 20 L 240 20 L 240 40" 
								stroke="url(#line-gradient)" 
								strokeWidth="3" 
								strokeDasharray="4"
								fill="none" 
								strokeLinecap="round"
								markerEnd="url(#arrowhead)"
								filter="url(#glow)"
							>
								<animate 
									attributeName="stroke-dashoffset"
									from="8"
									to="0"
									dur="1s"
									repeatCount="indefinite"
								/>
							</path>
						</svg>
					</div>
				</div>
			)
		}
		if (step === steps[2]) {
			return (				<div className="h-[350px] relative bg-white rounded-xl p-6">
				<svg viewBox="0 0 400 300" className="w-full h-full">
					{/* Chart Background */}
					<rect x="40" y="20" width="320" height="260" fill="white" rx="8"/>
					
					{/* Y Axis */}
					<line x1="60" y1="260" x2="60" y2="40" stroke="#94a3b8" strokeWidth="2"/>
					<polygon points="60,30 55,40 65,40" fill="#94a3b8"/>
					
					{/* X Axis */}
					<line x1="40" y1="240" x2="360" y2="240" stroke="#94a3b8" strokeWidth="2"/>
					<polygon points="370,240 360,235 360,245" fill="#94a3b8"/>
					
					{/* Axis Labels */}
					<text x="30" y="150" transform="rotate(-90,30,150)" className="text-sm fill-gray-500" textAnchor="middle">Expected Revenue (M)</text>
					<text x="200" y="280" className="text-sm fill-gray-500" textAnchor="middle">Risk Level</text>
					
					{/* Grid Lines */}
					<g stroke="#e2e8f0" strokeDasharray="4">
						{[0, 1, 2, 3, 4].map(i => (
							<line key={i} x1="60" y1={240 - i * 50} x2="360" y2={240 - i * 50} />
						))}
					</g>
					
					{/* Data Points */}
					<g>
						{/* Point 1 */}
						<circle cx="120" cy="100" r="6" fill="#4f46e5"/>
						<foreignObject x="130" y="70" width="120" height="80">
							<div className="bg-white p-3 rounded-lg shadow-lg border border-indigo-100">
								<div className="text-sm font-semibold text-gray-700">Risk: 75%</div>
								<div className="text-sm font-semibold text-gray-700">Revenue: 700M</div>
								<button className="mt-2 px-3 py-1 bg-indigo-600 text-white text-xs rounded-full hover:bg-indigo-700 transition-colors">
									Invest
								</button>
							</div>
						</foreignObject>
						
						{/* Point 2 */}
						<circle cx="280" cy="180" r="6" fill="#4f46e5"/>
						<foreignObject x="290" y="150" width="120" height="80">
							<div className="bg-white p-3 rounded-lg shadow-lg border border-indigo-100">
								<div className="text-sm font-semibold text-gray-700">Risk: 25%</div>
								<div className="text-sm font-semibold text-gray-700">Revenue: 400M</div>
								<button className="mt-2 px-3 py-1 bg-indigo-600 text-white text-xs rounded-full hover:bg-indigo-700 transition-colors">
									Invest
								</button>
							</div>
						</foreignObject>
					</g>
					
					{/* Axis Values */}
					<text x="60" y="255" className="text-xs fill-gray-500" textAnchor="middle">100</text>
					<text x="360" y="255" className="text-xs fill-gray-500" textAnchor="middle">0</text>
					<text x="40" y="245" className="text-xs fill-gray-500" textAnchor="end">0</text>
					<text x="40" y="45" className="text-xs fill-gray-500" textAnchor="end">900</text>
				</svg>
				</div>)
		}
		
		return (
			<div className="h-[350px] overflow-y-auto">
				<p className="text-gray-700 leading-relaxed text-lg prose prose-indigo max-w-none">
					{step.details}
				</p>
			</div>
		)
	}

	const getRightPanelStyles = () => {
		if (state.rightPanelState === 'hidden') {
			return {
				visibility: 'hidden',
				opacity: 0,
				display: 'none',
			}
		}
		return {
			position: state.rightPanelState === 'relative' ? 'relative' : 'fixed',
			top: state.rightPanelState === 'fixed' ? '50%' : 'auto',
			right: 50,
			transform: state.rightPanelState === 'fixed' ? 'translateY(-50%)' : 'none',
			visibility: 'visible',
			opacity: 1,
			height: '500px'
		}
	}

	const renderRightPanel = () => (
		<div 
			ref={refs.rightPanel}
			className="right-panel hidden lg:block transition-all duration-700 ease-in-out bg-white/90 backdrop-blur-md border border-gray-100 rounded-3xl p-12 shadow-2xl w-[600px] hover:scale-[1.02] hover:shadow-3xl hover:bg-white/95 overflow-hidden"
			style={getRightPanelStyles()}
		>
			<div className="flex flex-col h-full">
				<h3 className="text-4xl font-bold mb-8 bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-600 bg-clip-text text-transparent">
					{steps[state.activeStep].title}
				</h3>
				{state.activeStep === 0 ? renderUploadAndHypothesize() : renderStepContent(steps[state.activeStep])}
			</div>
		</div>
	)

	const renderStep = (step, index) => (
		<div 
			key={index}
			ref={el => refs.steps.current[index] = el}
			className={`group relative p-10 rounded-3xl cursor-pointer transition-all duration-500 ${
				state.activeStep === index 
					? 'bg-white/90 backdrop-blur-md border border-gray-100 shadow-2xl scale-105' 
					: 'bg-white/60 hover:bg-white/80 hover:scale-105 hover:shadow-xl'
			}`}
			onClick={() => updateState({ activeStep: index })}
		>
			<div className="absolute -top-4 left-8 px-6 py-2 bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-600 rounded-full text-white font-semibold text-sm tracking-wider shadow-lg">
				STEP {(index + 1).toString().padStart(2, '0')}
			</div>
			<h2 className="text-3xl font-bold mt-4 mb-6 bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-600 bg-clip-text text-transparent">
				{step.title}
			</h2>
			<p className="text-gray-700 leading-relaxed text-lg group-hover:text-gray-900 transition-colors duration-300 mb-6">
				{step.description}
			</p>
			<div className="space-y-4">
				{step.features.map((feature, i) => (
					<div key={i} className="flex items-center gap-3">
						<svg className="w-5 h-5 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
							<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={feature.icon} />
						</svg>
						<span className="text-gray-600 group-hover:text-gray-900 transition-colors duration-300">
							{feature.text}
						</span>
					</div>
				))}
			</div>
		</div>
	)

	return (
		<section ref={refs.section} className={`relative w-full overflow-hidden py-32 px-6 bg-gradient-to-b from-gray-50 to-white ${className}`}>
			<div className="absolute inset-0 z-0">
				<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[1000px] h-[1000px] bg-gradient-to-r from-purple-100/30 to-blue-100/30 rounded-full blur-3xl" />
				<div className="absolute top-0 right-0 w-96 h-96 bg-gradient-to-br from-indigo-100/40 to-purple-100/40 rounded-full blur-2xl" />
			</div>

			<div className="relative z-10 max-w-7xl mx-auto">
				<div className="max-w-3xl">
					<h1 className="text-5xl md:text-6xl font-bold tracking-tight bg-gradient-to-r from-gray-900 to-gray-600 bg-clip-text text-transparent">
						How it works
					</h1>
					<p className="mt-6 text-xl text-gray-600">
						Transform your innovation process with our AI-powered platform that turns uncertainty into opportunity
					</p>
				</div>

				<div className="mt-24">
					<div className="w-full lg:w-1/2 space-y-48">
						{steps.map((step, index) => renderStep(step, index))}
					</div>

					{renderRightPanel()}
				</div>
			</div>
		</section>
	)
}

HowItWorks.propTypes = {
	className: PropTypes.string
}

export default HowItWorks
