import React from 'react'
import {motion} from 'framer-motion'
import {FaMobileAlt, FaGlobe, FaMapMarkerAlt, FaCalendar, FaGraduationCap, FaUniversity, FaRobot, FaMedal, FaAward, FaCertificate, FaPaintBrush, FaEnvelope, FaLinkedin, FaGithub, FaUserFriends, FaHeart, FaHandshake, FaRandom, FaLightbulb, FaHeadset, FaRocket} from 'react-icons/fa'
import Media from '../../views/home/components/Media'
import Spacer from '../../components/spacer/spacer'

export function MVP() {
	return (
		<div className="min-h-screen">
			<motion.header initial={{opacity: 0}} animate={{opacity: 1}} className="min-h-screen flex items-center justify-center text-center px-4 pt-20 bg-gradient-to-br from-blue-600 to-purple-600 relative overflow-hidden">
				{/* Animated circles background */}
				{[...Array(5)].map((_, i) => (
					<motion.div
						key={i}
						className="absolute rounded-full bg-white/10"
						initial={{
							scale: 0,
							x: '50%',
							y: '50%',
							opacity: 0.7
						}}
						animate={{
							scale: [1, 2, 3],
							opacity: [0.7, 0.3, 0]
						}}
						transition={{
							duration: 4,
							repeat: Infinity,
							delay: i * 0.8,
							ease: 'easeOut'
						}}
						style={{
							width: '300px',
							height: '300px',
							left: '50%',
							top: '50%',
							transform: 'translate(-50%, -50%)'
						}}
					/>
				))}

				<div className="absolute inset-0 opacity-[0.03] pointer-events-none bg-[linear-gradient(to_right,rgba(255,255,255,0.1)_1px,transparent_1px),linear-gradient(to_bottom,rgba(255,255,255,0.1)_1px,transparent_1px)] bg-[size:14px_24px]"></div>
				<div className="absolute inset-0 bg-gradient-to-br from-blue-600/30 to-purple-600/30 backdrop-blur-3xl"></div>

				<div className="max-w-4xl mx-auto relative z-10">
					<motion.div
						animate={{
							scale: [1, 1.1, 1],
							rotate: [0, 5, -5, 0]
						}}
						transition={{
							duration: 4,
							repeat: Infinity,
							ease: 'easeInOut'
						}}
						className="w-24 h-24 mx-auto mb-8 bg-white/10 backdrop-blur-xl rounded-2xl p-4 shadow-xl">
						<img src="/frame.svg" alt="Derisky Logo" className="w-full h-full" />
					</motion.div>

					<div className="inline-flex items-center gap-2 px-4 py-2 bg-white/20 backdrop-blur-lg rounded-full mb-6">
						<FaRocket className="text-white text-xl" />
						<span className="text-white font-medium">Kickstart your app idea in 2025</span>
					</div>

					<motion.h1 initial={{y: 20, opacity: 0}} animate={{y: 0, opacity: 1}} transition={{delay: 0.2}} className="text-5xl md:text-7xl font-bold mb-6 text-white">
						Get your MVP built in <br />
						<span className="bg-white/20 backdrop-blur-lg p-2 rounded">2 weeks</span> for <span className="bg-white/20 backdrop-blur-lg p-2 rounded">CHF 10,000</span>
					</motion.h1>

					<motion.div initial={{y: 20, opacity: 0}} animate={{y: 0, opacity: 1}} transition={{delay: 0.3}} className="inline-flex items-center gap-2 px-4 py-2 bg-white/20 backdrop-blur-lg rounded-full mb-8">
						<span className="text-white font-medium">🇨🇭 Swiss Version</span>
					</motion.div>

					<motion.p initial={{y: 20, opacity: 0}} animate={{y: 0, opacity: 1}} transition={{delay: 0.4}} className="text-xl text-white/90 mb-8">
						Receive a fully functional (mobile or web) app in only 2 weeks.
					</motion.p>
					<motion.button onClick={() => window.scrollTo({top: document.getElementById('laurens').offsetTop, behavior: 'smooth'})} initial={{y: 20, opacity: 0}} animate={{y: 0, opacity: 1}} transition={{delay: 0.6}} whileHover={{scale: 1.05}} whileTap={{scale: 0.95}} className="px-8 py-4 bg-white text-blue-600 rounded-full text-lg font-medium hover:shadow-lg hover:shadow-white/25 transition-all flex items-center justify-center gap-2 mx-auto">
						<FaEnvelope className="text-xl" />
						Book a Call
					</motion.button>
				</div>
			</motion.header>

			<Media className="pt-12" />

			{/* Services Section */}
			<section className="py-20 px-4 bg-gradient-to-br from-blue-50 via-indigo-50 to-purple-50">
				<div className="container mx-auto">
					<motion.div initial={{opacity: 0, y: 20}} whileInView={{opacity: 1, y: 0}} viewport={{once: true}} className="text-center mb-20">
						<span className="inline-block px-6 py-2 rounded-full bg-blue-100 text-blue-600 font-medium mb-6">Our Services</span>
						<h2 className="text-5xl md:text-6xl font-bold mb-8 bg-gradient-to-r from-blue-600 via-indigo-600 to-purple-600 bg-clip-text text-transparent">Derisky MVP Factory</h2>
						<p className="text-gray-700 text-xl max-w-2xl mx-auto leading-relaxed">Transform your vision into reality with our cutting-edge digital solutions</p>
					</motion.div>

					<div className="grid md:grid-cols-3 gap-10">
						{[
							{
								icon: <FaMobileAlt className="text-6xl" />,
								title: 'Mobile App Development',
								subtitle: 'Native & Cross-Platform Solutions',
								desc: 'Create stunning mobile experiences that engage users and drive results'
							},
							{
								icon: <FaRobot className="text-6xl" />,
								title: 'AI Integration',
								subtitle: 'Smart Automation Solutions',
								desc: 'Leverage the power of AI to automate workflows and enhance user experiences'
							},
							{
								icon: <FaPaintBrush className="text-6xl" />,
								title: 'UI/UX Design',
								subtitle: 'Beautiful & Functional Design',
								desc: 'Craft intuitive interfaces that delight users and boost conversion rates'
							}
						].map((service, index) => (
							<motion.div key={index} initial={{opacity: 0, y: 20}} whileInView={{opacity: 1, y: 0}} viewport={{once: true}} transition={{delay: index * 0.2}} whileHover={{y: -12, scale: 1.02}} className="p-10 rounded-[2rem] bg-white/70 backdrop-blur-lg shadow-xl hover:shadow-2xl transition-all duration-500 border border-white/50 group">
								<div className="flex flex-col items-center text-center">
									<motion.div whileHover={{rotate: 360, scale: 1.1}} transition={{duration: 0.7}} className="w-24 h-24 flex items-center justify-center bg-gradient-to-br from-blue-500 via-indigo-500 to-purple-500 text-white rounded-[1.5rem] mb-8 group-hover:shadow-lg group-hover:shadow-blue-500/25 transition-all duration-500">
										{service.icon}
									</motion.div>
									<h3 className="text-2xl font-bold mb-3 text-gray-800">{service.title}</h3>
									<div className="w-16 h-1 bg-gradient-to-r from-blue-500 to-purple-500 rounded-full mb-4"></div>
									<h4 className="text-lg font-semibold mb-4 bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">{service.subtitle}</h4>
									<p className="text-gray-600 leading-relaxed">{service.desc}</p>
								</div>
							</motion.div>
						))}
					</div>
				</div>
			</section>

			{/* Why choose us? */}
			<section className="py-20 bg-gradient-to-br from-gray-50 to-white">
				<div className="container mx-auto px-4">
					<div className="flex flex-col items-center mb-16">
						<div className="inline-flex items-center px-4 py-2 rounded-full bg-blue-600/10 border border-blue-600/20 mb-8">
							<span className="text-blue-600 font-medium">Why choose us?</span>
						</div>
						<h2 className="text-4xl md:text-5xl font-bold text-center mb-4">
							<span className="bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">You Don't Need a Development Team</span>
						</h2>
						<p className="text-gray-600 text-center text-xl">You need a partner who works with you.</p>
						<motion.button onClick={() => document.getElementById('laurens').scrollIntoView({behavior: 'smooth'})} whileHover={{scale: 1.05}} whileTap={{scale: 0.95}} className="mt-8 px-10 py-3 bg-gradient-to-r from-blue-600 to-purple-600 text-white rounded-full font-medium hover:shadow-xl transition-all flex items-center gap-3 text-lg">
							<FaCalendar className="text-xl" />
							Book a Call Now
						</motion.button>
						<p></p>
					</div>

					<div className="grid md:grid-cols-2 lg:grid-cols-3 gap-12">
						{[
							{
								icon: <FaRocket className="text-4xl text-blue-600" />,
								title: 'Agile',
								description: 'Our youthful team brings fresh perspectives and a dynamic approach to every project, ensuring your solutions are modern and relevant.'
							},
							{
								icon: <FaHeart className="text-4xl text-blue-600" />,
								title: 'Passionate',
								description: 'Our passion for technology drives us to go the extra mile, delivering services that exceed expectations.'
							},
							{
								icon: <FaHandshake className="text-4xl text-blue-600" />,
								title: 'Dedicated',
								description: "We're committed to your success. Our team works tirelessly to ensure your project goals are met on time and within budget."
							},
							{
								icon: <FaRandom className="text-4xl text-blue-600" />,
								title: 'Adaptable',
								description: 'Our team thrives on change and is always ready to adjust strategies to meet your evolving business needs.'
							},
							{
								icon: <FaLightbulb className="text-4xl text-blue-600" />,
								title: 'Innovative',
								description: "We're not just developers, we're innovators. Our team is constantly exploring new technologies and methodologies to deliver cutting-edge solutions."
							},
							{
								icon: <FaHeadset className="text-4xl text-blue-600" />,
								title: '24/7 Support',
								description: "We're here for you around the clock. Our team provides continuous support to ensure your operations run smoothly at all times."
							}
						].map((feature, index) => (
							<motion.div key={index} initial={{opacity: 0, y: 20}} whileInView={{opacity: 1, y: 0}} viewport={{once: true}} transition={{delay: index * 0.1}} className="group relative">
								<div className="flex flex-col items-start space-y-4">
									<div className="transform transition-all duration-300 group-hover:scale-110">{feature.icon}</div>
									<h3 className="text-2xl font-bold text-gray-800">{feature.title}</h3>
									<div className="h-0.5 w-12 bg-gradient-to-r from-blue-500 to-purple-500 transform origin-left transition-all duration-300 group-hover:w-20"></div>
									<p className="text-gray-600 leading-relaxed">{feature.description}</p>
								</div>
							</motion.div>
						))}
					</div>
				</div>
			</section>
			{/* Process Section */}
			<section className="py-20 px-4 bg-gradient-to-br from-gray-50 to-white">
				<div className="container mx-auto">
					<motion.div initial={{opacity: 0, y: 20}} whileInView={{opacity: 1, y: 0}} viewport={{once: true}} className="text-center mb-32">
						<h2 className="text-3xl font-bold mb-4 text-gray-800">Our Process</h2>
						<h3 className="text-4xl md:text-5xl font-bold mb-6 bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">Let's get it done in 2 weeks</h3>
					</motion.div>

					<div className="max-w-4xl mx-auto">
						{[
							{
								image: 'https://images.unsplash.com/photo-1520607162513-77705c0f0d4a?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
								step: '1',
								title: 'Free Discovery Call',
								badge: '30 min',
								description: "Share your vision with our experts and let's explore how we can transform your idea into reality.",
								align: 'right'
							},
							{
								image: 'https://images.unsplash.com/photo-1542626991-cbc4e32524cc?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fHdvcmtzaG9wJTIwY2FyZCUyMHNvcnRpbmd8ZW58MHx8MHx8fDA%3D',
								step: '2',
								title: 'Strategic Workshop',
								badge: '2h',
								description: "Meet our team in Zürich for an engaging workshop where we'll dive deep into your project vision and goals.",
								align: 'left'
							},
							{
								image: 'https://images.unsplash.com/photo-1531403009284-440f080d1e12?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
								step: '3',
								badge: '3 days',
								title: 'Tailored Project Plan',
								description: 'Receive a comprehensive proposal with detailed scope, timeline and fixed pricing - no hidden surprises.',
								align: 'right'
							},
							{
								image: 'https://images.unsplash.com/photo-1515879218367-8466d910aaa4?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
								step: '4',
								badge: '2 weeks',
								title: 'Rapid Development',
								description: 'Watch your vision come to life as our expert team builds your custom app with cutting-edge technology.',
								align: 'left'
							}
						].map((step, index) => (
							<motion.div
								key={index}
								initial={{opacity: 0}}
								whileInView={{opacity: 1}}
								exit={{opacity: 0}}
								viewport={{
									once: false,
									amount: 0.8
								}}
								className="min-h-screen flex items-center justify-center">
								<motion.div className={`flex items-center gap-12 ${step.align === 'left' ? 'flex-row' : 'flex-row-reverse'}`}>
									<motion.div whileHover={{scale: 1.05}} className="w-1/2">
										<div className="relative rounded-3xl shadow-2xl overflow-hidden">
											<div className="absolute inset-0 bg-gradient-to-br from-blue-600/20 to-purple-600/20 hover:opacity-0 transition-opacity duration-300 z-10"></div>
											<img src={step.image} alt={step.title} className="w-full h-[300px] object-cover" />
										</div>
									</motion.div>

									<div className="w-1/2 space-y-6">
										<motion.div initial={{scale: 0}} whileInView={{scale: 1}} transition={{delay: 0.2}} className="w-16 h-16 bg-gradient-to-br from-blue-600 to-purple-600 rounded-2xl flex items-center justify-center shadow-xl">
											<span className="text-3xl font-bold text-white">{step.step}</span>
										</motion.div>

										<motion.h3 initial={{opacity: 0, x: step.align === 'left' ? -50 : 50}} whileInView={{opacity: 1, x: 0}} transition={{delay: 0.3}} className="text-3xl font-bold text-gray-800">
											{step.title}
										</motion.h3>

										<motion.p initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{delay: 0.4}} className="text-xl text-gray-600">
											{step.description}
										</motion.p>

										{step.badge && (
											<motion.span initial={{opacity: 0, y: 20}} whileInView={{opacity: 1, y: 0}} transition={{delay: 0.5}} className="inline-block px-6 py-2 text-lg font-medium text-blue-600 bg-blue-100 rounded-full shadow-inner">
												{step.badge}
											</motion.span>
										)}
									</div>
								</motion.div>
							</motion.div>
						))}
					</div>
				</div>
			</section>
			{/* Project Manager Section */}
			<section id="laurens" className="py-20 bg-gradient-to-br from-gray-50 to-white">
				<div className="container mx-auto px-4">
					<motion.div initial={{opacity: 0, y: 20}} whileInView={{opacity: 1, y: 0}} viewport={{once: true}} className="max-w-6xl mx-auto bg-white rounded-3xl shadow-2xl overflow-hidden">
						<div className="p-12">
							<h2 className="text-4xl font-bold mb-12 bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent text-left leading-tight">
								Stop overthinking.
								<br />
								Materialize your vision & book your call now.
							</h2>

							<div className="flex flex-col md:flex-row items-start gap-12">
								<div className="md:w-2/5 flex flex-col items-start">
									<motion.div whileHover={{scale: 1.05}} className="w-80 h-80 rounded-2xl overflow-hidden shadow-2xl mb-6 border-4 border-blue-100">
										<img src="/laurnes.jpg" alt="Project Manager" className="w-full h-full object-cover" />
									</motion.div>

									<h3 className="text-3xl font-bold mb-6 bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent text-left">Laurens Lang MSc. MBA</h3>

									<div className="flex items-center gap-2 text-gray-600 text-base mb-2 text-left w-full">
										<FaMapMarkerAlt className="text-blue-600" />
										<span className="font-medium">Zürich, Switzerland</span>
									</div>
									<div className="flex items-center gap-2 text-gray-600 text-base mb-2 text-left w-full">
										<FaGlobe className="text-blue-600" />
										<span className="font-medium">Fluent in German and English</span>
									</div>

									<div className="flex items-center gap-2 text-gray-600 text-base mb-2 text-left w-full">
										<FaGraduationCap className="text-blue-600" />
										<span className="font-medium">Master of Science in Computer Science</span>
									</div>
									<div className="flex items-center gap-2 text-gray-600 text-base mb-2 text-left w-full">
										<FaUniversity className="text-blue-600" />
										<span className="font-medium">Master of Business Administration</span>
									</div>

									<div className="flex gap-4 mb-6">
										<motion.button onClick={() => window.open('https://calendly.com/derisky/30min', '_blank')} target="_blank" whileHover={{scale: 1.05}} whileTap={{scale: 0.95}} className="px-10 py-2 bg-gradient-to-r from-blue-600 to-purple-600 text-white rounded-full font-medium hover:shadow-xl transition-all flex items-center gap-3 text-lg">
											<FaCalendar className="text-xl" />
											Book a Call
										</motion.button>
										<motion.a whileHover={{scale: 1.1, rotate: 5}} href="https://www.linkedin.com/in/laurenslang/" target="_blank" className="p-3 bg-blue-50 rounded-full hover:bg-blue-100 transition-all shadow-md">
											<FaLinkedin className="text-2xl text-blue-600" />
										</motion.a>
									</div>
								</div>

								<div className="md:w-3/5">
									<p className="text-gray-700 text-lg leading-relaxed mb-8 text-left">An international, entrepreneurial thinking innovator with a passion for building innovative business models from scratch - challenging status quo by diving deep into cutting-edge technology innovations to disrupt traditional ways of doing things: securing problem-solution and product-market-fit, building iteratively with a radical focus on users & strong bias towards action. Available for in-person meetings in Zürich.</p>

									<div className="flex flex-wrap gap-4 mb-8">
										<motion.div whileHover={{scale: 1.05, backgroundColor: '#EBF5FF'}} className="flex items-center gap-3 p-3 bg-blue-50 rounded-xl transition-all hover:shadow-lg cursor-pointer">
											<FaLightbulb className="text-xl text-blue-600 shrink-0" />
											<span className="text-gray-700 font-bold text-sm">Strong analytical skills</span>
										</motion.div>
										<motion.div whileHover={{scale: 1.05, backgroundColor: '#EBF5FF'}} className="flex items-center gap-3 p-3 bg-blue-50 rounded-xl transition-all hover:shadow-lg cursor-pointer">
											<FaRocket className="text-xl text-blue-600 shrink-0" />
											<span className="text-gray-700 font-bold text-sm">Ambition, Willpower & Focus</span>
										</motion.div>
										<motion.div whileHover={{scale: 1.05, backgroundColor: '#EBF5FF'}} className="flex items-center gap-3 p-3 bg-blue-50 rounded-xl transition-all hover:shadow-lg cursor-pointer">
											<FaUserFriends className="text-xl text-blue-600 shrink-0" />
											<span className="text-gray-700 font-bold text-sm">Outstanding Communication & Presentation Skills</span>
										</motion.div>
										<motion.div whileHover={{scale: 1.05, backgroundColor: '#EBF5FF'}} className="flex items-center gap-3 p-3 bg-blue-50 rounded-xl transition-all hover:shadow-lg cursor-pointer">
											<FaHeart className="text-xl text-blue-600 shrink-0" />
											<span className="text-gray-700 font-bold text-sm">Team-player</span>
										</motion.div>
										<motion.div whileHover={{scale: 1.05, backgroundColor: '#EBF5FF'}} className="flex items-center gap-3 p-3 bg-blue-50 rounded-xl transition-all hover:shadow-lg cursor-pointer">
											<FaRandom className="text-xl text-blue-600 shrink-0" />
											<span className="text-gray-700 font-bold text-sm">Creative Mind</span>
										</motion.div>
										<motion.div whileHover={{scale: 1.05, backgroundColor: '#EBF5FF'}} className="flex items-center gap-3 p-3 bg-blue-50 rounded-xl transition-all hover:shadow-lg cursor-pointer">
											<FaHandshake className="text-xl text-blue-600 shrink-0" />
											<span className="text-gray-700 font-bold text-sm">6 Years Experience</span>
										</motion.div>
									</div>

									<div className="border-t pt-8">
										<h4 className="text-xl font-bold text-gray-800 mb-4 text-left">Certifications</h4>
										<div className="grid gap-4">
											<motion.div whileHover={{x: 10}} className="flex items-center gap-3">
												<FaCertificate className="text-blue-600" />
												<span className="text-gray-700">Advanced Certified Scrum Product Owner®</span>
											</motion.div>
											<motion.div whileHover={{x: 10}} className="flex items-center gap-3">
												<FaAward className="text-blue-600" />
												<span className="text-gray-700">Certified Professional for Requirements Engineering - FL</span>
											</motion.div>
											<motion.div whileHover={{x: 10}} className="flex items-center gap-3">
												<FaMedal className="text-blue-600" />
												<span className="text-gray-700">Certified Scrum Product Owner®</span>
											</motion.div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</motion.div>
				</div>
			</section>
		</div>
	)
}
