import React from 'react';
import './mvp.module.css'
import './canves.scss'
import { Button, Loader, MultiSelect, ViewContext, useLocation } from 'components/lib';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useContext } from 'react';


const Canvas = (props, data) => {
  const [editedData, setEditedData] = useState([])
  const [loading, setLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  const router = useLocation()
  // let id = router.pathname.replace('/hypothesis/', '')
  const segments = [
    'hypothesis',
    'cases',
    'business-model',
    'singleproject',
    'draganddrop',
    'insight',
    'integration',
    'documents',
  ];

  const matchedSegment = segments.find((segment) => router.pathname.includes(segment)) || 'documents';
  const id = router.pathname.replace(`/${matchedSegment}/`, '');
  const context = useContext(ViewContext)
  const categories = ['Feasibility', 'Viability', 'Desirability', 'Adoptability', 'Compliance & Regulatory'

  ];

  let totalRisk = editedData.reduce((p, c) => {
    const currentRisk = parseInt(c?.risk) || 0;
    return {
      risk: parseInt(p?.risk) + currentRisk
    };
  }, { risk: 0 }).risk;

  const addHypothesis = async () => {
    if (!editedData?.length || totalRisk > 100) {
      context.notification.show(
        "Please ensure all data is valid and total risk is <= 100.",
        "error",
        true
      );
      return;
    }

    try {
      setLoading(true);
      let hasError = false;

      for (let i = 0; i < editedData.length;) { // No increment here; index updates dynamically
        const hypothesis = editedData[i];
        try {
          const response = await axios.post(`/api/product_hypothesis`, {
            risk: hypothesis?.risk || 0,
            phase:
              hypothesis?.category && categories.includes(hypothesis?.category)
                ? hypothesis?.category
                : "Desirability",
            statement: hypothesis?.hypothesis,
            date_created: moment().format("YYYY-MM-DD"),
            id,
          });

          if (response?.data) {
            console.log("Hypothesis added successfully:", response.data);

            // Remove the successfully processed item from the array
            const updatedData = [...editedData];
            updatedData.splice(i, 1);
            setEditedData(updatedData);

            // Do not increment `i` because the array size has changed
            continue;
          }
        } catch (error) {
          hasError = true;
          const errorMessage =
            error?.response?.data?.message || "An unexpected error occurred.";
          context.notification.show(errorMessage, "error", true);
          console.error("Error adding hypothesis:", errorMessage);

          // Exit loop on error if further processing is unnecessary
          break;
        }
      }

      if (!hasError) {
        props?.callBack();
      }
    } catch (exception) {
      console.error("Unexpected error:", exception.message);
      context.notification.show(
        "An unexpected error occurred while processing hypotheses.",
        "error",
        true
      );
    } finally {
      setLoading(false);
    }
  };



  // const addHypothesis = async () => {
  //   if (editedData?.length && totalRisk <= 100) {
  //     for (let i = 0; i < editedData.length; i++) {
  //       setLoading(true)
  //       await axios({
  //         method: 'post',
  //         url: `/api/product_hypothesis`,
  //         data: {
  //           risk: editedData[i]?.risk ? editedData[i]?.risk : 0,
  //           phase: editedData[i]?.category && categories.includes(editedData[i]?.category) ? editedData[i]?.category : "Desirability",
  //           statement: editedData[i]?.hypothesis,
  //           date_created: moment().format('YYYY-MM-DD'),
  //           id: id
  //         }
  //       })
  //         .then((res) => {
  //           setIsError(false)
  //           if (res.data) {


  //           }
  //         })
  //         .catch((e) => {
  //           setIsError(true)
  //           if (e?.response?.data?.message) {
  //             context.notification.show(e?.response?.data?.message, 'error', true)
  //           }
  //           setLoading(false)
  //         })

  //       if (i == editedData?.length - 1) {
  //         setLoading(false)
  //         if (!isError)
  //           props?.callBack()
  //       }
  //     }

  //   }
  // }

  return (
    <div>
      {loading && <Loader />}
      <h3 className='font-bold'>Hypothesis</h3>
      <div>
        {/* {props?.data?.AIResponse && <div dangerouslySetInnerHTML={{ __html: props?.data?.AIResponse }} />} */}
        {props?.data?.AIResponse && <HypothesisGrid hypothesesData={props?.data?.AIResponse} data={setEditedData} />}
      </div>
      <div className='flex justify-between gap-2 mb-10'>
        <Button
          className={`inline-block rounded bg-primary px-6 w-[100%]  pb-2 text-xs font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]`}
          medium
          text={'Cancel'}
          color="white"
          rounded
          action={() => props?.cancell()}
        />
        <Button
          className={`inline-block rounded bg-primary px-6 w-[100%]  pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] ${totalRisk > 100 ? 'opacity-25' : ""}`}
          medium
          text={'Save'}
          color="blue"
          rounded

          action={() => {

            addHypothesis()
          }}
        />
      </div>
    </div>
  );
};

const HypothesisGrid = ({ hypothesesData, data }) => {
  const [editableData, setEditableData] = useState([]);

  const handleEdit = (index, field, value) => {
    const updatedData = [...editableData];
    updatedData[index][field] = value;
    data && data(updatedData)
    setEditableData(updatedData);
  };

  useEffect(() => {
    try {
      const jsonStart = hypothesesData.indexOf('[');
      const jsonEnd = hypothesesData.lastIndexOf(']') + 1;
      const jsonString = hypothesesData.substring(jsonStart, jsonEnd);
      const parsedData = JSON.parse(jsonString);
      console.log('Parsed Data:', parsedData);
      // const parsedData = JSON.parse(hypothesesData);

      if (parsedData) {

        validateRiskTotal(parsedData);
        data && data(parsedData)

      }



    } catch (error) {
      console.log('=======error', error)

    }

  }, [hypothesesData])

  const validateRiskTotal = (data) => {
    const totalRisk = data.reduce((acc, hypothesis) => acc + hypothesis.risk, 0);

    if (totalRisk > 100) {
      console.warn('Total risk exceeds 100. Reducing risk values proportionally.');
      const reductionFactor = 100 / totalRisk;
      const adjustedData = data.map((hypothesis) => ({
        ...hypothesis,
        risk: Math.floor(hypothesis.risk * reductionFactor),
      }));

      setEditableData(adjustedData);
    } else {
      setEditableData(data);
    }
  };

  let totalRisk = editableData.reduce((p, c) => {
    const currentRisk = parseInt(c?.risk) || 0;  // Ensure that risk is a number, default to 0 if it's not present or not a valid number
    return {
      risk: parseInt(p?.risk) + currentRisk
    };
  }, { risk: 0 }).risk;

  const categories = [
    { label: 'Feasibility', value: 'Feasibility' },
    { label: 'Viability', value: 'Viability' },
    { label: 'Desirability', value: 'Desirability' },
    { label: 'Adoptability', value: 'Adoptability' },
    { label: 'Compliance & Regulatory', value: 'Compliance & Regulatory' }
  ];


  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr>
            <th className="border border-gray-300 p-2">Risk</th>
            <th className="border border-gray-300 p-2">Category</th>
            <th className="border border-gray-300 p-2">Hypothesis</th>
          </tr>
        </thead>
        <tbody>
          {editableData.map((hypothesis, index) => (
            <tr key={index}>
              <td className="border border-gray-300 p-2">
                <input
                  type="number"
                  value={hypothesis.risk}
                  onChange={(e) => handleEdit(index, 'risk', e.target.value)}
                  className="w-full h-12 p-1"
                />
              </td>
              <td className="border border-gray-300 p-2">

                <MultiSelect
                  // label="category"
                  options={categories}
                  type="multiSelect"
                  value={hypothesis?.category ? hypothesis?.category : hypothesis.phase}
                  isMulti={false}
                  onChange={(e) => handleEdit(index, 'category', e)}
                  parent={'canvas'}
                />
              </td>
              <td className="border border-gray-300 p-2">
                <textarea
                  type="text"
                  value={hypothesis.hypothesis}
                  onChange={(e) => handleEdit(index, 'hypothesis', e.target.value)}
                  className="w-full"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {totalRisk > 100 ? <div className='p-3 text-red-500'>Risk not greater to 100%</div> : ""}

      {totalRisk ? <div className='p-3'>Total Risk: {totalRisk}</div> : ""}
    </div>
  );
};

export default Canvas;
