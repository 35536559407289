import React, { useState } from 'react'
import { Button, Form, Loader, ViewContext } from '../../../components/lib'
import axios from 'axios'
import settings from '../../../../src/settings.json'
import { useEffect } from 'react'
import { useContext } from 'react'

const CardForm = ({ product_id, field, label, integration, getIntegrations, icon, checkAllIntegrations, isAllCollapsed, onToggleCollapse, rowIndex, onRowToggle, checkIntegrations }) => {
	const [isUpdated, setIsUpdated] = useState(false)
	const [value, setValue] = useState('')
	const [isCollapsed, setIsCollapsed] = useState(true)
	const [error, setError] = useState('')
	const [isLoading, setIsLoading] = useState(false)

	// Update local collapse state when parent state changes
	React.useEffect(() => {
		setIsCollapsed(isAllCollapsed)
	}, [isAllCollapsed])

	const key = integration && integration[0] && `is${field[0].toUpperCase() + field.slice(1)}` // Constructs 'isStatus'
	let valueOfVariable = integration && integration[0] && integration[0][key] // Accesses the dynamically created key


	// Check if valueOfVariable is 0 and set an error message
	useEffect(() => {
		if (valueOfVariable === 0) {

		} else {
			setError('') // Clear error when value is valid
		}
	}, [valueOfVariable, label])

	const handleCheckIntegrations = async () => {
		setIsLoading(true)
		setError('') // Reset error before starting validation

		try {
			const validationResult = await checkIntegrations(field) // Perform validation
			if (validationResult?.isValid) {
				setIsUpdated(true)
				setError('') // Clear error on success
			} else {
				if (valueOfVariable) {
					setError('')
				}
				if (integration?.length && integration[0][field]) {
					setError(`Invalid key for ${label}. Please verify your credentials.`)
				} else {
					setError(`Keys's for ${label} not found. Please add credentials first.`)
				}

			}
		} catch (err) {
			setError(`Error checking ${label}: ${err.message}`)
		} finally {
			setIsLoading(false)
		}
	}


	// console.log('==== valueOfVariable', valueOfVariable)
	let placeholder
	let placeholder2
	let placeholder3
	switch (label) {
		case 'Google Analytics':
			placeholder = 'Enter your Google Analytics Property ID'
			break
		case 'Amplitude':
			placeholder = 'Enter Project ID'
			placeholder2 = 'Enter API Key'
			placeholder3 = 'Enter Secret Key'
			break
		case 'Hotjar':
			placeholder = 'Enter Client ID'
			placeholder2 = 'Enter Client Secret'
			break
		case 'Mailchimp':
			placeholder = 'Enter API Key'
			break
		case 'Mixpanel':
			placeholder = 'Enter Service Username'
			placeholder2 = 'Enter Secret'
			break
		default:
			placeholder = 'Enter your API Key'
	}

	const handleInputChange = (e) => {
		setValue(e.target.value)
		setIsUpdated(true) // Change to true to toggle the icon to green
	}

	const oauth_dialog = () => {
		const SM_API_BASE = 'https://api.surveymonkey.com'
		const AUTH_CODE_ENDPOINT = '/oauth/authorize'
		const redirect_uri = `${settings[process.env.NODE_ENV].server_url}/api/surveymonkey/oauth/`
		const client_id = '9iIxGEg7SRK2pSFK0banzQ'
		const response_type = 'code'
		const state = product_id

		var auth_dialog_uri = SM_API_BASE + AUTH_CODE_ENDPOINT + '?redirect_uri=' + redirect_uri + '&client_id=' + client_id + '&response_type=' + response_type + '&state=' + state
		window.location.href = auth_dialog_uri
	}

	const oauth_dialog_mailchip = async () => {

		const redirect_uri = `${settings[process.env.NODE_ENV].server_url}/api/mailchip/oauth/`
		// const redirect_uri = `https://api.derisky.ai/metrix/api/integration/mailchimp`
		// const redirect_uri = `http://127.0.0.1:7171/metrix/api/mailchip/oauth/`
		const MAILCHIMP_CLIENT_ID = '727944910137'
		const state = product_id
		const authUrl = `https://login.mailchimp.com/oauth2/authorize?response_type=code&client_id=${MAILCHIMP_CLIENT_ID}&redirect_uri=${redirect_uri}`;

		var auth_dialog_uri = authUrl + '&state=' + state

		window.location.href = auth_dialog_uri
	}
	const oauth_dialog_google = async () => {
		try {
			const state = product_id

			const authUrl = `${settings[process.env.NODE_ENV].server_url}/api/google/oauth?state=${state}`;
			window.location.href = authUrl;
		} catch (error) {
			console.error('Error initiating Google Login:', error);

		}

	}

	const toggleCollapse = () => {
		const newState = !isCollapsed
		setIsCollapsed(newState)
		// onToggleCollapse(newState)
		onRowToggle(rowIndex, newState)
	}

	return (
		<div className={`card bg-white shadow-lg rounded-lg p-6 border border-gray-200 transition-all duration-300 hover:shadow-xl ${!valueOfVariable ? 'opacity-50' : ''}`}>
			{/* Card Header */}
			<div className="card-header flex flex-col space-y-3 mb-4">
				<div className="flex justify-between items-center cursor-pointer" onClick={toggleCollapse}>
					<div className="flex items-center gap-3">
						<div className="w-10 h-10 flex items-center justify-center bg-gray-50 rounded-lg">
							<img src={icon} alt={label} className="w-6 h-6 object-contain" />
						</div>
						<h2 className="text-xl font-semibold text-gray-800">{label}</h2>
					</div>
					<div className="flex items-center gap-2">
						<a
							onClick={(e) => {
								e.stopPropagation()
								handleCheckIntegrations()
								// checkAllIntegrations()
							}}
							className="flex cursor-pointer items-center bg-gray-50 px-3 py-1.5 rounded-full">
							<div className={`w-3 h-3 rounded-full mr-2 ${valueOfVariable ? 'bg-green-500 animate-pulse' : 'bg-red-500'}`} />
							<span className={`text-sm font-medium ${valueOfVariable ? 'text-green-600' : 'text-red-600'}`}>{valueOfVariable ? 'Connected' : 'Not Connected'}</span>
						</a>
						<svg className={`w-6 h-6 transform transition-transform ${isCollapsed ? '' : '-rotate-180'}`} fill="none" stroke="currentColor" viewBox="0 0 24 24">
							<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
						</svg>
					</div>
				</div>

				{error && (
					<div className="bg-red-100 text-red-800 px-4 py-2 rounded-lg mb-4">
						{error}
					</div>
				)}
				{!isCollapsed && integration && integration[0] && (
					<>
						{(() => {
							switch (label) {
								case 'Mailchimp':
									return (
										<div className="text-sm text-gray-600 font-medium px-3 py-2 bg-gray-50 rounded-lg overflow-hidden text-ellipsis">
											<b>API Key:</b> {integration[0][field] || 'Not Connected'}
										</div>
									)
								case 'Amplitude':
									return (
										<div className="text-sm text-gray-600 font-medium px-3 py-2 bg-gray-50 rounded-lg overflow-hidden text-ellipsis">
											<b>Project ID:</b> {integration[0].amplitude_project_id || 'Not Connected'}
											<br />
											<b>API Key:</b> {integration[0].amplitude_api_key || 'Not Connected'}
											<br />
											<b>Secret Key:</b> {integration[0].amplitude_secret_key || 'Not Connected'}
										</div>
									)
								case 'Mixpanel':
									return (
										<div className="text-sm text-gray-600 font-medium px-3 py-2 bg-gray-50 rounded-lg overflow-hidden text-ellipsis">
											<b>Username:</b> {integration[0].mixpanel_username || 'Not Connected'}
											<br />
											<b>Secret:</b> {integration[0].mixpanel_secret || 'Not Connected'}
										</div>
									)
								case 'Hotjar':
									return (
										<div className="text-sm text-gray-600 font-medium px-3 py-2 bg-gray-50 rounded-lg overflow-hidden text-ellipsis">
											<b>Client ID:</b> {integration[0].hotjar_client_id || 'Not Connected'}
											<br />
											<b>Client Secret:</b> {integration[0].hotjar_client_secret || 'Not Connected'}
										</div>
									)
								case 'Meta Business Manager':
									return (
										<div className="text-sm text-gray-600 font-medium px-3 py-2 bg-gray-50 rounded-lg overflow-hidden text-ellipsis">
											<b>Account ID:</b> {integration[0].meta_account_id || 'Not Connected'}
											<br />
											<b>Access Secret:</b> {integration[0][field] || 'Not Connected'}
										</div>
									)
								case 'Segment':
									return (
										<div className="text-sm text-gray-600 font-medium px-3 py-2 bg-gray-50 rounded-lg overflow-hidden text-ellipsis">
											<b>Segment Write key:</b> {integration[0].segment_write_key || 'Not Connected'}
											<br />
											<b>Segment Workspace ID:</b> {integration[0].segment_workspace_id || 'Not Connected'}
											<br />
											<b>Api Key:</b> {integration[0][field] || 'Not Connected'}
										</div>
									)
								case 'Webflow':
									return (
										<div className="text-sm text-gray-600 font-medium px-3 py-2 bg-gray-50 rounded-lg overflow-hidden text-ellipsis">
											<b>Segment Site ID:</b> {integration[0].webflow_site_id || 'Not Connected'}
											<br />
											<b>Segment Collection ID:</b> {integration[0].webflow_collection_id || 'Not Connected'}
											<br />
											<b>Api Key:</b> {integration[0][field] || 'Not Connected'}
										</div>
									)

								case 'Leadpages':
									return (
										<div className="text-sm text-gray-600 font-medium px-3 py-2 bg-gray-50 rounded-lg overflow-hidden text-ellipsis">
											<b>Api Key:</b> {integration[0][field] || 'Not Connected'}
											<br />
											<b>Leadpages Client Secret:</b> {integration[0].leadpage_client_secret || 'Not Connected'}
										</div>
									)
								case 'Rudderstack':
									return (
										<div className="text-sm text-gray-600 font-medium px-3 py-2 bg-gray-50 rounded-lg overflow-hidden text-ellipsis">
											<b>Source Write Key:</b> {integration[0][field] || 'Not Connected'}
											<br />
											<b>Workspace Token:</b> {integration[0].rudderstack_workspace_token || 'Not Connected'}
											<br />
											<b>Data Plan Url:</b> {integration[0].rudderstack_data_plan_url || 'Not Connected'}


										</div>
									)
								case 'Qualtrics':
									return (
										<div className="text-sm text-gray-600 font-medium px-3 py-2 bg-gray-50 rounded-lg overflow-hidden text-ellipsis">
											<b>Api Token:</b> {integration[0].qualtrics || 'Not Connected'}
											<br />
											<b>Data Center Specifications:</b> {integration[0].qualtrics_data_center || 'Not Connected'}
										</div>
									)
								case 'Kissmetrics':
									return (
										<div className="text-sm text-gray-600 font-medium px-3 py-2 bg-gray-50 rounded-lg overflow-hidden text-ellipsis">
											<b>Api key:</b> {integration[0][field] || 'Not Connected'}
											<br />
											<b>Product ID:</b> {integration[0].kissmetrics_products_id || 'Not Connected'}
										</div>
									)
								case 'Appcues':
									return (
										<div className="text-sm text-gray-600 font-medium px-3 py-2 bg-gray-50 rounded-lg overflow-hidden text-ellipsis">
											<b>Account ID:</b> {integration[0].appcues_account_id || 'Not Connected'}
											<br />
											<b>Api key:</b> {integration[0][field] || 'Not Connected'}
											<br />

											<b>Api Secret:</b> {integration[0].appcues_api_secret || 'Not Connected'}
										</div>
									)

								default:
									return (
										<div className="text-sm text-gray-600 font-medium px-3 py-2 bg-gray-50 rounded-lg overflow-hidden text-ellipsis">
											<b>API Key:</b> {integration[0][field] || 'Not Connected'}
										</div>
									)
							}
						})()}
					</>
				)}
			</div>
			{label == 'Survey Monkey' && <>
				<a className="p-3 bg-[#6145F7] hover:!bg-[#422db6] rounded-3xl text-white rounded-lg cursor-pointer mb-2" onClick={() => oauth_dialog()}>{valueOfVariable ? "🔑 Connect Different Account" : "🔑 Connect Survey Monkey"}</a>
				<div className='pb-4'></div>
			</>}
			{label == 'Mailchimp' && <>
				<a className="p-3 bg-[#6145F7] hover:!bg-[#422db6] rounded-3xl text-white rounded-lg cursor-pointer mb-2" onClick={() => oauth_dialog_mailchip()}>{valueOfVariable ? "🔑 Connect Different Account" : "🔑 Connect Mailchimp"}</a>
				<div className='pb-4'></div>
			</>}
			{label == 'Google Analytics' && <>
				<a className="p-3 bg-[#6145F7] hover:!bg-[#422db6] rounded-3xl text-white rounded-lg cursor-pointer mb-2" onClick={() => oauth_dialog_google()}>{valueOfVariable ? "🔑 Connect Different Account" : "🔑 Connect Google Analytics"}</a>
				<div className='pb-4'></div>
			</>}

			{label == 'Amplitude'}

			{!isCollapsed && label && <Form
				inputs={label == 'Appcues' ? {

					appcues_account_id: {
						showLabel: true,
						label: 'Account ID',
						type: 'text',
						required: true,
						placeholder: 'Enter Account ID',
						errorMessage: 'Please enter Account ID',

					},
					[field]: {
						showLabel: false,
						type: 'text',
						label: 'Api Key',
						required: true,
						placeholder: 'Enter your Api Key',
						errorMessage: 'Please enter the Api Key'
					},
					appcues_api_secret: {
						showLabel: false,
						type: 'text',
						label: 'Api Secret',
						required: true,
						placeholder: 'Enter your Api Secret',
						errorMessage: 'Please enter the Api Secret'
					},

				} : label == 'Rudderstack' ? {
					[field]: {
						showLabel: false,
						type: 'text',
						label: 'Source Write Key',
						required: true,
						placeholder: 'Enter your Source Write Key',
						errorMessage: 'Please enter the Source Write Key'
					},

					rudderstack_workspace_token: {
						showLabel: true,
						label: 'Workspace Token',
						type: 'text',
						required: true,
						placeholder: 'Enter Workspace Token',
						errorMessage: 'Please enter Workspace Token',

					},
					rudderstack_data_plan_url: {
						showLabel: true,
						label: 'Data Plan Url',
						type: 'text',
						required: true,
						placeholder: 'Enter Data Plan Url',
						errorMessage: 'Please enter Data Plan Url',

					},

				} : label == 'Kissmetrics' ? {
					[field]: {
						showLabel: false,
						type: 'text',
						label: 'Api Key',
						required: true,
						placeholder: 'Enter your Api Key',
						errorMessage: 'Please enter the Api Key'
					},

					kissmetrics_products_id: {
						showLabel: true,
						label: 'Product ID',
						type: 'text',
						required: true,
						placeholder: 'Enter Product ID',
						errorMessage: 'Please enter Product ID',

					},
				} : label == 'Qualtrics' ?
					{

						qualtrics: {
							showLabel: false,
							type: 'text',
							label: 'Api Token',
							required: true,
							placeholder: 'Enter your Api Token',
							errorMessage: 'Please enter the Api Token'
						},

						qualtrics_data_center: {
							showLabel: true,
							label: 'Data Center Specifications',
							type: 'text',
							required: true,
							placeholder: 'Enter Data Center Specifications',
							errorMessage: 'Please enter Data Center Specifications',

						},
					} :
					label == 'Leadpages' ?
						{

							[field]: {
								// Wrap field in square brackets to dynamically assign the key
								showLabel: false,
								type: 'text',
								label: 'Api Key',
								required: true,
								placeholder: 'Enter your Api Key',
								errorMessage: 'Please enter the Api Key'
							},

							leadpage_client_secret: {
								showLabel: true,
								label: 'Leadpage Client Secret',
								type: 'text',
								required: true,
								placeholder: 'Enter Client Secret',
								errorMessage: 'Please enter Client Secret',

							},
						} :
						label == 'Segment' ?
							{
								segment_write_key: {
									showLabel: true,
									label: 'Segment Write Key',
									type: 'text',
									required: true,
									placeholder: 'Enter Segment Write Key',
									errorMessage: 'Please enter Segment Write Key',

								},
								segment_workspace_id: {
									showLabel: true,
									label: 'Segment Workspace ID',
									type: 'text',
									required: true,
									placeholder: 'Enter Segment Workspace ID',
									errorMessage: 'Please enter Segment Workspace ID',

								},
								[field]: {
									// Wrap field in square brackets to dynamically assign the key
									showLabel: false,
									type: 'text',
									label: 'Api Key',
									required: true,
									placeholder: 'Enter your Api Key',
									errorMessage: 'Please enter the Api Key'
								}
							} : label == 'Meta Business Manager' ? {
								meta_account_id: {
									showLabel: true,
									label: 'Account ID',
									type: 'text',
									required: true,
									placeholder: 'Enter Account ID',
									errorMessage: 'Please enter Account ID',

								},
								[field]: {
									// Wrap field in square brackets to dynamically assign the key
									showLabel: false,
									type: 'text',
									label: 'Access Token',
									required: true,
									placeholder: 'Enter your Access Token',
									errorMessage: 'Please enter the Access Token'
								}
							} : label == 'Hotjar' ? {
								hotjar_client_id: {
									showLabel: true,
									label: 'Client ID',
									type: 'text',
									required: true,
									placeholder: 'Enter Client ID',
									errorMessage: 'Please enter Client ID',

								},
								hotjar_client_secret: {
									showLabel: true,
									type: 'text',
									label: 'Client Secret',
									required: true,
									placeholder: 'Enter Client Secret',
									errorMessage: 'Please enter Client Secret',

								}
							} :
								label == 'Mixpanel' ? {
									mixpanel_username: {
										showLabel: true,
										label: 'Username',
										type: 'text',
										required: true,
										placeholder: 'Enter Service Username',
										errorMessage: 'Please enter the username',

									},
									mixpanel_secret: {
										showLabel: true,
										type: 'text',
										label: 'Secret',
										required: true,
										placeholder: 'Enter Secret',
										errorMessage: 'Please enter the API Token',

									}
								} : label == 'Amplitude' ? {
									amplitude_project_id: {
										showLabel: true,
										label: 'Project ID',
										type: 'text',
										required: true,
										placeholder: 'Enter Project ID',
										errorMessage: 'Please enter the Project ID',

									},
									amplitude_api_key: {
										showLabel: true,
										type: 'text',
										label: 'API Key',
										required: true,
										placeholder: 'Enter Api key',
										errorMessage: 'Please enter the API Key',

									},
									amplitude_secret_key: {
										showLabel: true,
										type: 'text',
										required: true,
										label: "Secret Key",
										placeholder: 'Enter Api Secret',
										errorMessage: 'Please enter the Secret Key',

									}
								} : {
									[field]: {
										// Wrap field in square brackets to dynamically assign the key
										showLabel: false,
										type: 'text',
										required: true,
										placeholder: 'Enter your API Key',
										errorMessage: 'Please enter the API Key'
									}
								}}
				url={`/api/integration/${product_id}`}
				method="PUT"
				buttonText="Save"
				callback={(res) => {
					getIntegrations()
					alert('Updated successfully')
				}}
				className="w-full border border-gray-300 rounded-lg px-4 py-3 focus:outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-200"
			/>}


		</div>
	)
}

// Main component that renders each form in a grid layout
const IntegrationForms = ({ product_id }) => {
	const context = useContext(ViewContext)
	const [integration, setIntegration] = useState()
	const [isAllCollapsed, setIsAllCollapsed] = useState(true)
	const [rowStates, setRowStates] = useState([])
	const [loading, setLoading] = useState(false)


	const formFields = [
		{ field: 'googleanalytics', label: 'Google Analytics', icon: 'https://www.vectorlogo.zone/logos/google_analytics/google_analytics-icon.svg' },
		// { field: 'optimizely', label: 'Optimizely', icon: 'https://www.rudderstack.com/integration/_next/image/?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2F97bpcflt%2Fproduction%2F90d44122f4613b945d9ebc41e373beba975f82bb-83x80.svg%3Fw%3D83%26fm%3Dwebp%26fit%3Dfill%26dpr%3D2&w=2048&q=75' },

		// { field: 'unbounce', label: 'Unbounce', icon: 'https://cdn.worldvectorlogo.com/logos/unbounce.svg' },
		// { field: 'metabusinessmanager', label: 'Meta Business Manager', icon: 'https://www.facebook.com/images/fb_icon_325x325.png' },
		{ field: 'amplitude', label: 'Amplitude', icon: 'https://amplitude.com/favicon.ico' },


		{ field: 'hotjar', label: 'Hotjar', icon: 'https://www.hotjar.com/favicon.ico' },

		// { field: 'segment', label: 'Segment', icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROVrtUgi2SUOV5r45Muk1mbixjlaNUvycTBA&s' },
		{ field: 'mailchimp', label: 'Mailchimp', icon: 'https://cdn.worldvectorlogo.com/logos/mailchimp-freddie-icon.svg' },
		{ field: 'mixpanel', label: 'Mixpanel', icon: 'https://cdn.brandfetch.io/idr_rhI2FS/theme/dark/idMJ8uODLv.svg?k=id64Mup7ac&t=1717143401811' },
		// { field: 'tealium', label: 'Tealium', icon: 'https://www.tealium.com/favicon.ico' },
		// { field: 'rudderstack', label: 'Rudderstack', icon: 'https://rudderstack.com/favicon.ico' },
		// { field: 'appcues', label: 'Appcues', icon: 'https://cdn.icon-icons.com/icons2/2699/PNG/512/appcues_logo_icon_170540.png' },
		// { field: 'kissmetrics', label: 'Kissmetrics', icon: 'https://cdn.worldvectorlogo.com/logos/kissmetrics.svg' },
		{ field: 'surveymonkey', label: 'Survey Monkey', icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReQ_wDq6zJrb_yxCuSlO6az4-H7i0eKQBbsw&s' },
		// { field: 'webflow', label: 'Webflow', icon: 'https://webflow.com/favicon.ico' },
		// { field: 'leadpages', label: 'Leadpages', icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7l9Tl53z-ZYnlM6IV8NB3r7JaNaV85xmO0Q&s' }
		// { field: 'delighted', label: 'Delighted', icon: 'https://cdn.worldvectorlogo.com/logos/delighted.svg' },
		// { field: 'qualtrics', label: 'Qualtrics', icon: 'https://cdn.prod.website-files.com/6640cd28f51f13175e577c05/664e00b93db9646afe55f0ef_a0ebb2e4-9231-5e58-80f0-487d5bea1f7e.svg' }
	]

	useEffect(() => {
		getIntegrations()
	}, [])
	const checkAllIntegrations = async () => {
		try {
			setLoading(true)
			// console.log('==== checkAllIntegrations')
			const response = await axios.get(`/api/integration/${product_id}/check`).then(res => {
				setLoading(false)
				// setCheckIntegrations(res?.data)
				setIntegration(res.data?.data)
				// console.log('==+++integrations', res?.data)
			})
			setLoading(false)
			// getIntegrations()
		} catch (error) {
			setLoading(false)
			if (error?.response?.data) {
				context.notification.show(error?.response?.data, 'error', true)
			}
		}

	}

	const handleToggleAll = (newState) => {
		setIsAllCollapsed(newState)
		setRowStates(new Array(Math.ceil(formFields.length / 3)).fill(newState))
	}

	const handleRowToggle = (rowIndex, newState) => {
		const newRowStates = [...rowStates]
		newRowStates[rowIndex] = newState
		setRowStates(newRowStates)
	}
	const getIntegrations = async () => {
		try {
			setLoading(true)

			const response = await axios.get(`/api/integration/${product_id}`)
			console.log('==== response', response.data?.data)
			setIntegration(response.data?.data)
			setLoading(false)
		} catch (error) {
			setLoading(true)
		}

	}

	const checkIntegrations = async (field) => {
		try {

			const response = await axios.get(`/api/integrationoptions/${field}/product/${product_id}`).then(res => {
				setLoading(false)
				return res

			})
			console.log('=+++++++0jknsm', response.data)
			return response
		} catch (error) {

		}
	}

	return (
		<div>
			{loading && <Loader />}
			<div className="bg-grey-800 p-6 m-6 mt-0 rounded-lg shadow-lg"><h1 className="text-2xl font-bold mb-3">🔑 Integration is key</h1><p className="">Breathe some consciousness into your hypotheses and experiments and help us to make realistic risk, innovation and innovation performance predictions</p></div>

			<div className="flex gap-4 mx-6">
				<a className="p-3 bg-[#6145F7] hover:!bg-[#422db6] rounded-3xl text-white rounded-lg cursor-pointer" onClick={() => checkAllIntegrations()}>🕵️ Check All Integrations</a>
				<a className="p-3 bg-[#6145F7] hover:!bg-[#422db6] rounded-3xl text-white rounded-lg cursor-pointer" onClick={() => {
					// setIsAllCollapsed(!isAllCollapsed)
					handleToggleAll(!isAllCollapsed)
				}}>{!isAllCollapsed ? '🔼 Collapse All' : '🔽 Expand All'}  </a>
			</div>
			<div className="grid grid-cols-1 md:grid-cols-3 gap-6 p-6">
				{formFields.map((field) => (
					<CardForm key={field.field}
						field={field.field}
						icon={field.icon}
						label={field.label}
						product_id={product_id}
						onToggleCollapse={handleToggleAll}
						onRowToggle={handleRowToggle}
						rowIndex={rowStates}
						getIntegrations={getIntegrations}
						integration={integration}
						checkAllIntegrations={checkAllIntegrations}
						checkIntegrations={checkIntegrations}
						isAllCollapsed={isAllCollapsed} />
				))}
			</div>
		</div>
	)
}

export default IntegrationForms
