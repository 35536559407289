import { useNavigate } from 'react-router-dom';
import ButtonPrimary from '../components/ButtonPrimary'

const VentureCapitalSection = () => {
    const navigate = useNavigate();
    
    return (
        <section className="self-stretch h-auto lg:h-[700px] bg-[#6145F7] overflow-hidden shrink-0 flex flex-row items-center justify-between py-[101px] px-[2rem] lg:px-[140px] box-border max-w-full text-left text-37xl text-base-white font-plus-jakarta-sans">
            <div className="w-full lg:w-[45%] flex flex-col items-start justify-start gap-[32px]">
                <div className="flex items-center gap-3 mb-4">
                    <span className="bg-red-500 text-white px-3 py-1 rounded-full text-sm font-bold animate-pulse">FREE</span>
                    <span className="text-white/80 text-sm line-through">Valued at $997</span>
                </div>
                <h1 className="m-0 self-stretch relative text-[2rem] lg:text-[56px] text-white leading-[38.2px] lg:leading-[67.2px] font-medium">
                    Master Venture Capital Thinking <span className="text-yellow-300">Without Spending a Fortune</span>
                </h1>
                <div className="self-stretch relative text-[1rem] lg:text-[1.02rem] text-white/90 leading-[20px] lg:leading-[27px] font-medium">
                    <p className="mb-4">🚀 Transform your vague business ideas into a portfolio of worth-building & investable products. Our comprehensive course teaches you:</p>
                    <ul className="list-none space-y-2">
                        <li className="flex items-center gap-2">
                            <span className="text-green-300">✓</span> Expert-level business concept exploration
                        </li>
                        <li className="flex items-center gap-2">
                            <span className="text-green-300">✓</span> Data-driven product validation techniques
                        </li>
                        <li className="flex items-center gap-2">
                            <span className="text-green-300">✓</span> Risk management mastery
                        </li>
                        <li className="flex items-center gap-2">
                            <span className="text-green-300">✓</span> Portfolio diversification strategies
                        </li>
                    </ul>
                </div>
                <div className="flex flex-col gap-4">
                    <ButtonPrimary className="rounded-3xl hover:bg-[#6145F7] bg-[#422db6] text-white shadow-xl hover:shadow-2xl transform hover:-translate-y-1 transition-all duration-300" onClick={() => navigate('/mba')}>Enrol</ButtonPrimary>
                    <p className="text-white/80 text-sm">🔥 Limited Time Offer - Join <span className="font-bold text-white">2,500+</span> successful students!</p>
                </div>
            </div>

            <div className="hidden lg:flex w-[45%] relative">
                <div className="absolute top-0 right-0 w-[500px] h-[600px] bg-gradient-to-br from-[#422db6] to-[#6145F7] rounded-3xl transform rotate-6 shadow-2xl">
                    <div className="absolute inset-0 bg-white/5 backdrop-blur-sm rounded-3xl transform -rotate-6">
                        <div className="p-12 flex flex-col gap-6">
                            <div className="flex items-center gap-4">
                                <div className="w-16 h-16 rounded-2xl bg-white/10 flex items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                                    </svg>
                                </div>
                                <h2 className="text-2xl font-bold text-white">What You'll Learn</h2>
                            </div>
                            <div className="space-y-4">
                                <div className="flex items-center gap-3 text-white/90">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-yellow-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    <span>Risk Assessment Fundamentals (4 Modules)</span>
                                </div>
                                <div className="flex items-center gap-3 text-white/90">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-yellow-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    <span>Portfolio Management (6 Modules)</span>
                                </div>
                                <div className="flex items-center gap-3 text-white/90">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-yellow-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    <span>Investment Strategy (5 Modules)</span>
                                </div>
                                <div className="mt-6 p-4 bg-white/10 rounded-lg">
                                    <p className="text-sm text-white/90">🎁 <span className="font-bold text-white">Bonus:</span> Get exclusive access to our VC network!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default VentureCapitalSection;
