import PropTypes from 'prop-types'
import Style from '../../commit/components/hero/hero.module.scss'

const mediaContent = {
	title: "Known From Media",
	items: [
		{
			href: "https://www.benzinga.com/content/40299416/releasing-innovation-with-derisking-intelligence",
			imgSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQS5qoyan9XhrFRDxuW-eqNKrLOPNXVoEhOVJ-y884ZX9aSiba1sghrnqapk0a_G7f8ysA&usqp=CAU",
			alt: "Derisky Featured: Benzinga Logo"
		},
		{
			href: "https://www.digitaljournal.com/pr/news/binary-news-network/increase-innovation-performance-derisking-intelligence-1333860005.html",
			imgSrc: "https://www.fiveblocks.com/wp-content/uploads/2018/10/digital-journal-logo.png",
			alt: "Derisky Featured: Digital Journal Logo"
		},
		{
			href: "https://www.wicz.com/story/51192992/deriskyai-launches-to-transform-corporate-innovation-with-advanced-derisking-intelligence",
			imgSrc: "https://upload.wikimedia.org/wikipedia/commons/7/71/WICZ_logo_2018.svg",
			alt: "Derisky Featured: WICZ Logo"
		},
		{
			href: "https://kingnewswire.com/derisky-ai-launches-to-transform-corporate-innovation-with-advanced-derisking-intelligence-24081215533/",
			imgSrc: "https://kingnewswire.com/wp-content/uploads/King-News-Wire-logo-final-1-12.png",
			alt: "Derisky Featured: King News Wire Logo"
		},
		{
			href: "https://www.theglobeandmail.com/investing/markets/markets-news/Globe-PR-/29794547/increase-innovation-performance-with-derisking-intelligence/",
			imgSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT84CHuKbjtIEn_9KuOKl29xs8Pie1qqd9FqQ&s",
			alt: "Derisky Featured: The Globe and Mail Logo"
		}
	]
}

const Media = ({className = ''}) => {
	return (
		<section className={`w-full flex flex-row items-start justify-start pt-0 px-0 pb-[40px] lg:pb-[120px] box-border max-w-full shrink-0 text-center text-xl font-plus-jakarta-sans ${className}`}>
			<div className="w-full flex flex-col items-center justify-start gap-[24px] max-w-full">
				<h2 className="text-3xl font-bold relative leading-[120%] text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 via-purple-500 to-blue-500 hover:from-blue-500 hover:via-purple-500 hover:to-indigo-500 transition-all duration-500 tracking-tight">
					{mediaContent.title}
				</h2>
				<div className="w-full flex flex-col items-start justify-start max-w-full">
					<div className="w-full flex-wrap lg:flex-nowrap flex flex-row items-center justify-center lg:justify-between py-0 px-4 lg:px-[140px] box-border opacity-[0.8] gap-8 lg:gap-[60px] max-w-full mq800:px-8 mq1350:px-16">
						{mediaContent.items.map((item, index) => (
							<a key={index} target="_blank" className="w-auto h-auto lg:h-full" href={item.href}>
								<img
									className="self-stretch w-[198px] relative max-h-full overflow-hidden shrink-0 min-h-[90px] grayscale hover:grayscale-0 opacity-80 hover:opacity-100 cursor-pointer transition-all duration-300 transform hover:scale-110"
									alt={item.alt}
									src={item.imgSrc}
								/>
							</a>
						))}
					</div>
				</div>
			</div>
		</section>
	)
}

Media.propTypes = {
	className: PropTypes.string
}

export default Media
