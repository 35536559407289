import PropTypes from 'prop-types'
import {useNavigate} from 'react-router-dom'
import {SocialRow} from "../../../components/socialrow/socialrow";

const Navigation1 = ({className = ''}) => {
	const navigate = useNavigate()
	return (<>
			<div className='w-[1160px] flex flex-row justify-center'>
			{/*<span className='text-sm'><strong>Featured tools:</strong>
					//<a target='_blank' href='http://superx.so' className='ml-4'>Grow faster on 𝕏</a>
					</span>*/}
			</div>

			<footer className={`self-stretch flex flex-row items-start justify-center py-0 px-5 box-border max-w-full text-center text-xl text-primary font-plus-jakarta-sans ${className}`}>
				<div className="w-[1160px] flex flex-col items-start justify-start gap-[48px] max-w-full mq800:gap-[24px]">
					<div className="self-stretch flex flex-col items-start justify-start gap-[40px] max-w-full mq800:gap-[20px]">
						<div className="w-[388px] flex flex-col items-start justify-start gap-[32px] max-w-full">
							<div className="w-[122px] flex flex-row items-center justify-start gap-[12px]">
								<img className="h-12 w-6 relative overflow-hidden shrink-0" alt="" src="/frame-1.svg" />
								<div className="flex-1 relative tracking-[-1px] leading-[120%] font-semibold inline-block min-w-[86px] mq450:text-base mq450:leading-[19px]">Derisky.ai</div>

							</div>
							<div className="w-[388px] relative text-base leading-[120%] text-gray-800 text-left hidden">{`Derisky.ai, Badenerstrasse 571, Zürich, Switzerland `}</div>
						</div>
						<div className="self-stretch flex flex-col lg:flex-row items-start lg:items-center justify-between py-0 pr-0 lg:pr-[354px] pl-0 gap-[20px] text-base text-gray-800 mq450:pr-5 mq450:box-border mq800:pr-[88px] mq800:box-border mq1150:flex-wrap mq1350:pr-[177px] mq1350:box-border">
							<div className="relative leading-[12px] font-semibold inline-block min-w-[47px]" onClick={() => navigate('/')}>
								Home
							</div>
							<div className="hidden flex-row items-center justify-start gap-[4px] text-gray1-800">
								<div className="h-3 relative leading-[120%] font-medium inline-block">Features</div>
								<img className="h-3.5 w-3.5 relative min-h-[14px]" alt="" src="/iconlylightarrow--down-3.svg" />
							</div>
							<div className="hidden flex-row items-start justify-start gap-[4px] text-gray1-800">
								<div className="h-3 relative leading-[120%] font-medium inline-block">Use Cases</div>
								<img className="h-3.5 w-3.5 relative min-h-[14px]" alt="" src="/iconlylightarrow--down-3.svg" />
							</div>
							<div className="relative leading-[12px] font-medium inline-block min-w-[96px] cursor-pointer" onClick={() => navigate('/mba')}>
								Derisky MBA
							</div>
							<div className="relative leading-[12px] font-medium inline-block min-w-[53px]  cursor-pointer" onClick={() => navigate('/pricing')}>
								Pricing
							</div>
							<div className="relative leading-[12px] font-medium inline-block min-w-[70px]  cursor-pointer" onClick={() => navigate('/contact')}>
								Contact
							</div>
							<div className="relative leading-[12px] font-medium inline-block min-w-[43px  cursor-pointer" onClick={() => navigate('/signin')}>
								Login
							</div>
							<div className="relative leading-[12px] font-medium inline-block min-w-[59px] whitespace-nowrap  cursor-pointer" onClick={() => navigate('/signup')}>
								Sign Up
							</div>
							<div className="relative leading-[12px] font-medium inline-block min-w-[46px] cursor-pointer" onClick={() => navigate('/terms')}>
								Terms
							</div>
							<div className="relative leading-[12px] font-medium inline-block min-w-[56px] cursor-pointer" onClick={() => navigate('/privacy')}>
								Privacy
							</div>

						</div>
					</div>
					<div className="self-stretch box-border flex flex-col lg:flex-row flex-wrap items-start lg:items-center justify-start pt-3.5 px-0 pb-6 gap-[20px] lg:gap-[48px] max-w-full text-base text-gray1-800 border-t-[1px] border-solid border-gray-400 mq800:gap-[24px]">
						<div className="flex-1 relative leading-[24px] lg:leading-[12px] text-gray-500 text-left inline-block min-w-auto lg:min-w-[361px] max-w-full mq450:min-w-full">{`Derisky.ai, Badenerstrasse 571, Zürich, Switzerland `}</div>
						<div className="flex-1 relative leading-[12px] text-gray-500 text-left lg:text-right inline-block min-w-auto lg:min-w-[361px] max-w-full mq450:min-w-full">Copyright © Derisky,ai 2024</div>
					</div>
				</div>

			</footer>
			<SocialRow className='w-[1160px]'/>


	</>
	)
}

Navigation1.propTypes = {
	className: PropTypes.string
}

export default Navigation1
