import axios from 'axios'
import { Accordions } from 'components/accordion/accordion'
import { LogoCard } from 'components/card/projectcard'
import {
    Card,
    Icon,
    Loader,
    MultiSelect,
    SubNavbar,
    useLocation,
    ViewContext
} from 'components/lib'
import Spacer from 'components/spacer/spacer'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import ConfidenceListModal from './confidencelistmodal'
import Wrapper from "../../components/MyStepwizard"
import FocusComponent from './megaWizard'

export const Hypothesis = () => {
    // Context and router setup
    const context = useContext(ViewContext)
    const router = useLocation()
    const id = router.pathname.replace('/hypothesis/', '')
    const user = JSON.parse(localStorage.getItem('user'))

    // State management
    const [product, setProduct] = useState()
    const [hypothesis, setBusinessHypothesis] = useState([])
    const [dropdownValues, setDropdownValues] = useState([])
    const [loading, setLoading] = useState(false)
    const [confidenceModel, setConfidenceModel] = useState(false)
    const [confidenceData, setConfidenceData] = useState('')
    const [hypothesisData, setHypothesisData] = useState('')
    const [finishModelData, setFinishModelData] = useState()
    const [modal, setModal] = useState(false)
    const [openWizard, setOpenWizard] = useState(false)
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [previews, setPreviews] = useState([]);

    // Initial data fetch
    useEffect(() => {
        getProductHypothesis()
    }, [])

    // API calls and data fetching
    const getProductHypothesis = async () => {
        await Promise.all([
            getProduct(),
            getAllHypothesis(),
            getDropdownHypothesis()
        ])
    }

    const getProduct = async () => {
        if (!id) return

        setLoading(true)
        try {
            const { data } = await axios.get(`/api/product/${id}`)
            if (data?.data) {
                setProduct(data.data)
            }
        } catch (err) {
            console.error('Failed to fetch product:', err)
        } finally {
            setLoading(false)
        }
    }

    const getAllHypothesis = async () => {
        setLoading(true)
        try {
            const { data } = await axios.get(`/api/all_product_hypothesis/${id}`)
            if (data?.data) {
                setBusinessHypothesis(data.data)
            }
        } finally {
            setLoading(false)
        }
    }

    const getDropdownHypothesis = async () => {
        setLoading(true)
        try {
            const { data } = await axios.get(`/api/dropdown_product_hypothesis/${id}`)
            if (data?.data) {
                const formattedOptions = data.data.map(e => ({
                    label: (
                        <div className="grid">
                            <div className={`flex items-center ${e?.label?.includes('Most Promising') ? 'font-bold' : ''}`}>
                                {e?.label}
                            </div>
                        </div>
                    ),
                    value: e?.value
                }))
                setDropdownValues(formattedOptions)
            }
        } finally {
            setLoading(false)
        }
    }

    const getAllFilterHypothesis = async (value) => {
        setLoading(true)
        try {
            const { data } = await axios.post(`/api/all_filter_hypothesis/${id}`, { query: value })
            if (data?.data) {
                setBusinessHypothesis(data.data)
            }
        } finally {
            setLoading(false)
        }
    }

    // Modal handlers
    const hypothesisModal = (value) => {
        context.modal.show(
            {
                isFullPage: true,
                parent: 'hypothesis',
                callBack: getAllHypothesis,
                update: value?.id ? value : undefined
            },
            getAllHypothesis
        )
    }

    const experienceModal = (hypothesis, value) => {
        context.modal.show(
            {
                isFullPage: true,
                parent: 'experiment',
                hypothesis,
                update: value || undefined,
                callBack: getAllHypothesis
            }
        )
    }

    const deleteHypothesis = async (id) => {
        try {
            setLoading(true)
            const { data } = await axios.delete(`/api/product_hypothesis/${id}`)

            if (data?.message) {
                context.notification.show(data.message, 'success', true)
                context.modal.hide(true)
                await getAllHypothesis()
            }
        } catch (error) {
            context.handleError(error)
        } finally {
            setLoading(false)
        }
    }

    const deleteHypothesisModal = (id) => {
        context.modal.show({
            title: 'Delete Hypothesis?',
            body: 'Are you sure you want to delete this hypothesis?',
            delete: true,
            buttonText: 'Delete',
            cancel: 'Cancel',
            submit: () => deleteHypothesis(id),
            loading,
            destructive: true
        })
    }

    const deleteExperiment = async (id) => {
        try {
            setLoading(true)
            const { data } = await axios.delete(`/api/product_experiment/${id}`)

            if (data?.message) {
                context.notification.show(data.message, 'success', true)
                context.modal.hide(true)
                await getAllHypothesis()
            }
        } catch (error) {
            context.handleError(error)
        } finally {
            setLoading(false)
        }
    }

    const deleteExperimentModal = (id) => {
        context.modal.show({
            title: 'Delete Experiment?',
            body: 'Are you sure you want to delete this experiment?',
            delete: true,
            buttonText: 'Delete',
            cancel: 'Cancel',
            submit: () => deleteExperiment(id),
            loading: false,
            destructive: true
        })
    }

    const finishExperiment = async (isFinished, value) => {
        if (!hypothesisData || !confidenceData) return

        try {
            const { data } = await axios.put(`/api/product_experiment/${confidenceData?.id}`, {
                status: 'Finished',
                risk_reduction: value ? (parseFloat(hypothesisData) * value.confidence).toFixed(2) : 0,
                confidence: value?.confidence || 0,
                real_end_date: value?.realEndDate,
                insight: value?.insight,
                support: value.support,
                refute: value.refuse,
                unclear: value.unClear
            })

            if (data) {
                if (value?.learning) {
                    await axios.post('/api/experiment_learning', {
                        product_id: id,
                        experiment_id: confidenceData?.id,
                        text: value.learning
                    })
                }

                context.notification.show(data.message, 'success', true)
                context.modal.hide(true)
                await getProductHypothesis()
                setConfidenceModel(false)
            }
        } catch (e) {
            if (e?.response?.data?.message) {
                setConfidenceModel(false)
            }
        }
    }

    // UI helpers
    const toggleModal = () => setModal(!modal)
    const toggleLoading = () => setLoading(!loading)
    const unLoading = () => setLoading(false)

    const handleFileChangeFromPublicFolder = async (event) => {
        const publicFilePath = '/Business_Risk_Assessment_Questions.pdf'; // Replace with your file path
        try {
            // Fetch the file from the public folder
            const response = await fetch(publicFilePath);
            if (!response.ok) {
                throw new Error('Failed to fetch file');
            }

            // Convert the response to a Blob
            const blob = await response.blob();

            // Create a File object from the Blob
            const file = new File([blob], 'Business_Risk_Assessment_Questions.pdf', { type: blob.type });

            // Set the file in state
            const files = [file];
            handleSubmit(event, files)
            setSelectedFiles(files);

            // Create file previews
            const filePreviews = files.map(file => ({
                name: file.name,
                url: URL.createObjectURL(file),
            }));
            setPreviews(filePreviews);

        } catch (error) {
            console.error('Error fetching file from public folder:', error);
            alert('Error selecting file.');
        }
    };


    const handleSubmit = async (event, selectedFiles) => {
        event.preventDefault();
        if (selectedFiles.length === 0) {
            alert('Please select documents to upload.');
            return;
        }

        const formData = new FormData();
        selectedFiles.forEach(file => {
            formData.append('documents', file);
        });

        setLoading(true);
        try {
            const res = await axios.post(`/api/document/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }).then((res) => {
                if (res.data) {
                    setSelectedFiles([]);
                    setLoading(false)
                    alert('Files uploaded successfully!');
                }
            }).catch(err => setLoading(false));


        } catch (err) {
            setLoading(false)
            console.error('Error uploading files', err);
            alert('Error uploading files.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Fragment>
            <Wrapper
                modal={modal}
                unLoading={unLoading}
                toggleLoading={toggleLoading}
                close={toggleModal}
                id={product?.id}
            />

            {loading && <Loader fullScreen />}

            {product && (
                <LogoCard
                    title={product.name}
                    subtitle={product.description}
                    logo={product.logo}
                    wizardClick={() => setOpenWizard(!openWizard)}
                />
            )}

            <Spacer height="h-[1.188rem]" />
            <SubNavbar active="hypotheses" id={id} />
            <Spacer height="h-[1.125rem]" />

            {product?.isTeamLead && (
                <div className="flex gap-3 mt-2">
                    <div
                        className="bg-derisky-rose text-brand-400 text-md hover:shadow-inner shadow-md flex p-2 pl-6 pr-6  cursor-pointer gap-3 self-center content-center"
                        onClick={hypothesisModal}>
                        <Icon image='alert-circle' size={18} color='brand-400' />
                        Add Hypothesis
                    </div>
                    <div
                        // ${user?.plan === 'free' && 'opacity-50'}
                        className={`border border-brand-400 text-brand-500 text-md rounded hover:shadow-inner shadow-lg flex pt-2 p-2 pr-5 gap-3 self-center content-center cursor-pointer`}
                        onClick={(e) => {
                            if (user?.plan != 'free') window.location.href = 'http://derisky.ai/pricing'
                            else {
                                handleFileChangeFromPublicFolder(e)
                            }
                        }}
                    >
                        🧠 AI Hypothesis
                    </div>

                    <button
                        className={`
                                    border border-brand-400 text-brand-500 text-md rounded
                                    hover:shadow-inner shadow-lg flex p-2 px-5 gap-3 items-center
                                    transition-all duration-200 hover:bg-brand-50
                                    ${user?.plan === 'free' ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}
                                `}
                        onClick={() => {
                            if (user?.plan !== 'free') {
                                window.location.href = 'http://derisky.ai/pricing'

                            } else {
                                setOpenWizard(true)
                            }
                        }}
                    >
                        🧠 AI Hypothesis
                    </button>
                </div>
            )}

            <Spacer height="h-[1.125rem]" />

            <Card>
                <MultiSelect
                    label="Cases"
                    options={dropdownValues}
                    type="multiSelect"
                    isMulti={false}
                    parent={'hypo'}
                    onChange={(e) => getAllFilterHypothesis(e?.value)}
                />
            </Card>

            <Spacer height="h-[1.125rem]" />

            <div className="flex flex-col gap-[0.5rem]">
                {hypothesis?.map((e) => (
                    <div key={e.label}>
                        <Spacer height="h-[1.125rem]" />
                        <h1 className="text-[1.325rem] font-normal leading-5">{e?.label}</h1>
                        <Spacer height="h-[1.125rem]" />

                        <div className="flex items-center justify-between">
                            <h1 className="text-[1rem] font-semibold leading-4">Hypothesis</h1>
                            <div className="flex items-center gap-2">
                                <span className="text-[1rem] font-semibold leading-4">Risk</span>
                                <span className="text-[1rem] font-semibold leading-4">Open Risk</span>
                                <img src="/icons/arrow_down.svg" alt="Expand" className="w-4 h-4" />
                            </div>
                        </div>

                        <Spacer height="h-[1.125rem]" />

                        <div className="flex flex-col gap-[0.5rem]">
                            {e?.hypothesis?.map((hypo) => (
                                <Accordions
                                    key={hypo.id}
                                    title={e?.phase}
                                    text={hypo?.statement}
                                    risk={hypo?.risk}
                                    edit={() => hypothesisModal(hypo)}
                                    delete={() => deleteHypothesisModal(hypo?.id)}
                                    newExperience={() => experienceModal(hypo, '')}
                                    experiments={hypo?.experiments}
                                    isTeamLead={product?.isTeamLead}
                                    onEdit={(e) => experienceModal(hypo, e)}
                                    onDelete={deleteExperimentModal}
                                    onFinish={(data) => {
                                        setFinishModelData(undefined)
                                        setConfidenceModel(true)
                                        setConfidenceData(data)
                                        setHypothesisData(hypo?.risk)
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                ))}

                {confidenceModel && (
                    <ConfidenceListModal
                        onClose={() => setConfidenceModel(false)}
                        data={confidenceData}
                        confidence={finishExperiment}
                    />
                )}
            </div>

        </Fragment>
    )
}
