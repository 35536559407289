import React, { useState } from 'react'
import { Range, getTrackBackground } from 'react-range'
import { Loader, Card, useNavigate, DropdownMenu, Icon, Button, Logo, Animate } from 'components/lib'
import ChatGPT from 'components/chatgptPrompt'
import { useEffect } from 'react'
import axios from 'axios'


export function ProjectCard(props) {
	const navigate = useNavigate()
	let user = JSON.parse(localStorage.getItem('user'))
	// onClick={() => navigate('/insight')}
	return (
		<div className="min-h-full cursor-pointer" onClick={() => navigate(`/singleproject/${props?.id}`)}>
			<Card className="hover:shadow-inner border-brand-400 border bg-white bg-opacity-50 flex items-center text-left gap-[1.438rem] p-2 rounded-md shadow-lg">
				<div className='w-full h-full'>
					<img className="max-w-[5rem] h-full rounded bg-gray object-cover " alt="" src={process.env.REACT_APP_IMAGE_URL + props.image} />
				</div>
				<h1 className="text-[0.875rem] w-full h-full text-brand-400 leading-[1.063rem]" onClick={() => navigate(`/singleproject/${props?.id}`)}>
					{props.title}
				</h1>
				<>
					<div className="absolute right-[0.2rem] top-0">
						{(props?.teamLead || user?.permission === 'admin') && <DropdownMenu onEdit={() => props?.onEdit(props)} onDelete={() => props?.onDelete(props)} className={'!mt-0'} editTitle={'Edit Product'} deleteTitle={'Delete Product'} />}
					</div>
					{props.favourite ? (
						<Animate type='pop'>
							<a className="absolute right-[0.5rem] bottom-[0.5rem]" onClick={props.removeStar}>
								<Icon color='derisky-rose' image='heart' />
							</a>
						</Animate>
					) : (
						<Animate type='pop'><a className="absolute right-[0.5rem] bottom-[0.5rem]" onClick={props.addStar}>
							<Icon className="text-derisky-rose opacity-50" image='heart-outline' />
						</a></Animate>
					)}
				</>

				{props.loading ? <Loader /> : props.children}
			</Card>
		</div>
	)
}
export function DashboardCard(props) {
	const [showInfo, setShowInfo] = useState(false);

	// Elegant gradient combinations
	const gradients = {
		border: 'bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500',
		text: 'bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500',
		hover: 'hover:from-indigo-600 hover:via-purple-600 hover:to-pink-600'
	};

	const icons = {
		'Project Risk Level': 'warning',
		'Spent on experiments': 'money', 
		'Overall Risk Reduction': 'chart-line-down',
		'Cost Structure': 'building',
		'Killed Hypothesis': 'x-circle',
		'Finished Experiments': 'check-circle',
		'Insightful Experiments': 'lightbulb',
		'Uninsightful Experiments': 'question-circle',
		'EBITDA': 'chart-line'
	};

	const tooltips = {
		'Project Risk Level': 'Overall risk assessment for the project based on various factors',
		'Spent on experiments': 'Total budget allocated and spent on experimental initiatives',
		'Overall Risk Reduction': 'Percentage decrease in risk exposure over time',
		'Cost Structure': 'Breakdown of project costs and expenditures',
		'Killed Hypothesis': 'Number of hypotheses that were disproven',
		'Finished Experiments': 'Total completed experimental initiatives',
		'Insightful Experiments': 'Experiments that provided valuable learnings',
		'Uninsightful Experiments': 'Experiments with inconclusive results',
		'EBITDA': 'Earnings Before Interest, Taxes, Depreciation and Amortization'
	};

	return (
		<div className="transform transition-all duration-100 hover:scale-[0.98] rounded-2xl shadow-md hover:no-shadow relative p-[2px] bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
				<div className="relative bg-white rounded-2xl p-6 h-[200px] group">
					{/* Info Icon & Tooltip */}
					<div className="absolute top-4 right-4">
						<div 
							className="cursor-pointer relative"
							onMouseEnter={() => setShowInfo(true)}
							onMouseLeave={() => setShowInfo(false)}
						>
							<Icon 
								image="info"
								color="gray"
								className="w-5 h-5 text-gray-400 hover:text-gray-600 transition-colors"
							/>
							{showInfo && (
								<div className="absolute right-0 w-64 p-3 mt-2 text-sm text-gray-600 bg-white rounded-lg shadow-xl border border-gray-100 z-10">
									{tooltips[props.subtitle]}
								</div>
							)}
						</div>
					</div>
					{/* Icon */}
					<div className="mb-4">
						<Icon 
							image={icons[props.subtitle] || 'bar-chart'} 
							color="black"
							className="w-8 h-8 text-transparent"
							style={{
								background: 'linear-gradient(to right, rgb(99 102 241), rgb(168 85 247), rgb(236 72 153))',
								WebkitBackgroundClip: 'text',
								backgroundClip: 'text'
							}}
						/>
					</div>

					{/* Content */}
					<div className="space-y-4">
						<p className="text-sm font-medium text-gray-500 tracking-wide">
							{props.subtitle}
						</p>

						<h1 className="text-4xl font-bold bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 bg-clip-text text-transparent">
							{props.title}
						</h1>

						<div className="mt-2">
							{props.loading ? <Loader /> : props.children}
						</div>
					</div>

					{/* Hover Effects */}
					<div className="absolute inset-0 rounded-2xl transition-opacity duration-300 opacity-0 group-hover:opacity-100 pointer-events-none">
						<div className="absolute inset-0 bg-gradient-to-r from-indigo-500/10 via-purple-500/10 to-pink-500/10" />
					</div>
				</div>
		</div>
	);
}
export function LogoCard(props) {
	return (
		<div className="flex items-center justify-between">
			<div className="flex items-center">
				<img className="shadow-md h-48 rounded-md mr-4" alt="" src={process.env.REACT_APP_IMAGE_URL + props.logo} />
				<div>
					<h1 className="text-[1.125rem ] mb-4 text-brand-400 leading-[1.375rem] font-bold flex gap-1">{props.title}</h1>
					<p className="text-[1.125rem ]  leading-[1.375rem] font-normal ">{props.subtitle}</p>
				</div>
			</div>

			<div className="mt-2 border border-brand-400 text-brand-400 rounded hover:shadow-inner shadow-lg flex pt-2 p-2 pr-5 text-xs self-center content-center cursor-pointer"
				onClick={props?.wizardClick}
			>
				Create Wizard
			</div>

		</div>
	)
}

export function PageTitle(props) {
	return (
		<div className="flex items-center gap-1 ">
			<Logo color />
		</div>
	)
}
export function HypothesisCard(props) {
	return (
		<Card className="bg-white rounded-xl shadow-lg transition-all duration-300 hover:shadow-xl hover:scale-[1.02] group">
			<div className="flex items-center justify-center gap-6 py-6">
				<div className="bg-gradient-to-br from-brand-400 to-brand-500 rounded-lg w-16 h-16 flex items-center justify-center p-4 transition-colors duration-300 group-hover:from-brand-500 group-hover:to-brand-600">
					<img src={props.image} className="w-full h-full filter brightness-0 invert object-contain" alt="" />
				</div>
				<div className="flex flex-col gap-3">
					<h1 className="text-xl font-bold text-gray-800 group-hover:text-brand-500 transition-colors duration-300">{props.title}</h1>
					<p className="text-sm text-gray-600 group-hover:text-gray-700 transition-colors duration-300">{props.subtitle}</p>
				</div>
				{props.loading ? <Loader /> : props.children}
			</div>
		</Card>
	)
}

const STEP = 0.1
const MIN = 1
const MAX = 100

class RangeSlider extends React.Component {
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props)
		this.state = {
			values: [30]
		}
	}

	render() {
		return (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					flexWrap: 'wrap',
					flexDirection: 'column',
					marginTop: '1.5em'
				}}>
				<div className="flex items-center flex-wrap gap-2 w-full">
					{/* <div>{this.props.label}</div> */}
					<div>
						<h1 className={`text-[0.875rem] leading-[1.125rem] w-full font-normal uppercase text-light_blac min-w-[8rem] ${this.props.className}`}>{this.props.label}</h1>
					</div>
					<Range
						values={this.props?.values ?? [30]}
						step={0.1}
						min={this.props?.min}
						max={this.props?.max}
						onChange={(values) => {
							this.setState({ values })
							this.props?.onChange(values)
						}}
						renderTrack={({ props, children }) => (
							<div
								onMouseDown={props.onMouseDown}
								onTouchStart={props.onTouchStart}
								className="flex w-[17.5rem]"
								style={{
									...props.style
									//display: 'flex',
									//width: '20rem'
								}}>
								<div
									ref={props.ref}
									style={{
										height: '10px',
										width: '100%',
										borderRadius: '4px',
										background: getTrackBackground({
											values: this.props?.values ?? [50],
											colors: ['#4e8ed2', '#ccc'],
											min: this.props?.min,
											max: this.props?.max
										}),
										alignSelf: 'center'
									}}>
									{children}
								</div>
							</div>
						)}
						renderThumb={({ props, isDragged }) => (
							<div className="h-[22px] w-[22px] rounded-full bg-white flex justify-center items-center shadow" {...props}>
								<div
									style={{
										position: 'absolute',
										top: '-28px',
										color: '#fff',
										fontWeight: 'bold',
										fontSize: '14px',
										fontFamily: 'Arial,Helvetica Neue,Helvetica,sans-serif',
										padding: '4px',
										borderRadius: '4px',
										backgroundColor: '#548BF4'
									}}>
									{this.props?.values[0]?.toFixed(1) + '%'}
								</div>
								<div
									style={{
										height: '16px',
										width: '5px',
										backgroundColor: isDragged ? '#548BF4' : '#CCC'
									}}
								/>
							</div>
						)}
					/>
				</div>
			</div>
		)
	}
}
export default RangeSlider

export function ExperimentCard(props) {
	const user = JSON.parse(localStorage.getItem('user'))
	const [generateResponse, setGenerateResponse] = useState(false)
	const [product, setProduct] = useState()
	console.log('======props', props)

	useEffect(() => {
		if (props?.hypothesis?.product_id) {
			getProduct(props?.hypothesis?.product_id)

		}
	}, [props])

	const getProduct = async (id) => {
		if (props) {
			await axios({
				method: 'get',
				url: `/api/product/${id}`
			})
				.then((res) => {
					if (res.data) {
						setProduct(res.data.data)
					}
				})
				.catch((err) => {
					console.log('err', err)
				})
		}
	}
	return (
		<Card className="leading-[1.813rem] flex flex-col gap-[2rem] mb-0">
			<h1 className="text-[1.5rem]  leading-[1.813rem]">{props?.hypothesis?.statement}</h1>
			{props?.hypothesis?.description && <h1 className="max-w-[41rem]">{props?.hypothesis?.description}</h1>}
			<div className="flex gap-6 sm:gap-10 flex-wrap">
				<div className="flex mr-2 items-center">
					<h1 className="text-[0.875rem] leading-[1.125rem] font-normal uppercase text-light_blac mr-2">Business Model: </h1>
					<h1 className="font-bold">{props?.hypothesis?.phase}</h1>
				</div>
				<div className="flex mr-2 items-center">
					<h1 className=' mr-2'>Risk:</h1>
					<h1 className="font-bold">{props?.hypothesis?.risk}%</h1>
				</div>
				<div className="flex mr-2 items-center">
					<h1 className=' mr-2'>Importance: </h1>
					<h1 className="font-bold"> {props?.hypothesis?.importance}%</h1>
				</div>
				<div className="flex mr-2 items-center">
					<h1 className=' mr-2'> Unknown: </h1>
					<h1 className="font-bold">{props?.hypothesis?.unknown}%</h1>
				</div>

			</div>
			<div className="flex gap-6 sm:gap-10 flex-wrap">
				<Button text={'Give me a recommendation'}
					className={`inline-block rounded bg-primary px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2) !w-[18rem] ${user?.plan === 'free' && 'opacity-50'}`}
					medium
					color="blue"
					rounded
					// action={() => { if (user?.plan != 'free') window.location.href = 'http://Derisky.ai' }}
					action={() => {
						setGenerateResponse(true);
					}}
				/>
			</div>
			{generateResponse && <ChatGPT product={product} hypothesis={props?.hypothesis} />}
		</Card>
	)
}
