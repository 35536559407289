/***
*
*   PREMIUM PRICING EXPERIENCE
*   Elevate your journey with stunning animations and luxurious design
*   Optimized for maximum conversions with proven strategies:
*   - Social proof
*   - Scarcity & urgency 
*   - Value-based pricing
*   - Risk reversal
*   - Clear CTAs
*
**********/

import {Card, CheckList, Button, useNavigate, usePlans, Icon} from 'components/lib';
import Style from './pricing.tailwind.js';
import CountDown from "count-down-react";
import React, {useState} from "react";
import ReactGA from "react-ga4";
import {tagManager} from "../../app/googleTagManager";
import {motion, AnimatePresence} from "framer-motion";

// Social proof section
function SocialProof() {
  return (
    <motion.div
      initial={{opacity: 0}}
      animate={{opacity: 1}} 
      className="flex justify-center items-center gap-8 mb-12"
    >
      <div className="flex items-center">
        <Icon image="users" size="24" className="text-[#6145f7] mr-2"/>
        <span className="font-medium">10,000+ Happy Users</span>
      </div>
      <div className="flex items-center">
        <Icon image="star" size="24" className="text-[#6145f7] mr-2"/>
        <span className="font-medium">4.9/5 Rating</span>
      </div>
      <div className="flex items-center">
        <Icon image="shield" size="24" className="text-[#6145f7] mr-2"/>
        <span className="font-medium">30-Day Money Back</span>
      </div>
    </motion.div>
  );
}

function ValueAdded() {
  return (
    <motion.div 
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      transition={{duration: 1}}
      className="grid gap-8 mt-20 mb-20"
    >
      {[
        {image: 'trending-up', text: 'Master Strategic Thinking Like Elite VCs', desc: 'Join 500+ founders who transformed their startups using our proven frameworks'},
        {image: 'dollar-sign', text: 'Unlimited Potential Unleashed', desc: 'Our members report an average 3x growth within 6 months'},
        {image: 'briefcase', text: 'Accelerate Your Success Journey', desc: 'Access the same strategies used by unicorn founders'},
        {image: 'mail', text: 'Weekly Wisdom Delivered', desc: 'Join 50,000+ subscribers getting insider knowledge every Wednesday'},
        {image: 'film', text: 'Captivating Visual Learning', desc: '200+ HD video lessons with new content added weekly'},
        {image: 'heart', text: 'Science-Backed Growth', desc: 'Methodologies validated by Harvard Business School research'}
      ].map((item, index) => (
        <motion.div
          key={index}
          initial={{y: 50, opacity: 0}}
          animate={{y: 0, opacity: 1}}
          transition={{delay: index * 0.1, type: "spring", stiffness: 100}}
          whileHover={{
            scale: 1.05,
            boxShadow: "0 25px 50px -12px rgba(97, 69, 247, 0.25)",
            y: -5
          }}
          className="h-full p-8 bg-white rounded-2xl shadow-lg backdrop-blur-sm hover:bg-gradient-to-br from-white to-[#6145f7]/5 transition-all duration-500"
        >
          <Icon image={item.image} color="[#6145f7]" size="48" className="mb-6"/>
          <h3 className="text-gray-900 font-bold text-xl mb-3">{item.text}</h3>
          <p className="text-gray-600 leading-relaxed">{item.desc}</p>
        </motion.div>
      ))}
    </motion.div>
  );
}

export function PricePlans(props){
  const today = new Date();
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0); // Last day of current month

  const addZeroWhenOneDigit = number => (number < 10 ? '0' : '') + number;

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) return null;
    
    return (
      <motion.div 
        initial={{opacity: 0, y: 20}}
        animate={{opacity: 1, y: 0}}
        transition={{duration: 0.8}}
        className="flex-1"
      >
        <div className="flex justify-center mb-4">
          <div className="text-xl font-semibold text-[#6145f7] bg-[#6145f7]/10 px-6 py-2 rounded-full shadow-lg">
            <Icon image="clock" className="inline mr-2" size="20" />
            Special Launch Offer Ends in:
          </div>
        </div>
        <div className="flex justify-center space-x-4">
          {[
            {value: days, label: 'days', icon: 'calendar'},
            {value: hours, label: 'hours', icon: 'clock'}, 
            {value: minutes, label: 'mins', icon: 'watch'},
            {value: seconds, label: 'secs', icon: 'timer'}
          ].map((item, index) => (
            <motion.div
              key={index}
              whileHover={{scale: 1.1, rotate: [0, -1, 1, -1, 0]}}
              className="bg-gradient-to-br from-white to-[#6145f7]/5 rounded-xl shadow-xl p-4 min-w-[100px] text-center"
            >
              <Icon image={item.icon} className="mb-2" size="24" color="[#6145f7]" />
              <div className="text-4xl font-black text-[#6145f7]">
                {addZeroWhenOneDigit(item.value)}
              </div>
              <div className="text-sm font-medium text-gray-600">{item.label}</div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    );
  };

  const navigate = useNavigate();
  const plans = usePlans();
  const [hoveredPlan, setHoveredPlan] = useState(null);

  if (!plans.data) return null;
  // Order plans: free first, yearly in middle, monthly last
  const sortedPlans = [];
  // Add free plans
  plans.data.raw.plans.forEach(plan => {
    if (plan.interval === 'Week') {
      sortedPlans.push(plan);
    }
  });
  // Add yearly plans
  plans.data.raw.plans.forEach(plan => {
    if (plan.price > 0 && plan.interval === 'Year') {
      sortedPlans.push(plan);
    }
  });
  // Add monthly plans
  plans.data.raw.plans.forEach(plan => {
    if (plan.price > 0 && plan.interval !== 'Year') {
      sortedPlans.push(plan);
    }
  });

  return (
    <motion.div
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      transition={{duration: 0.8}}
      className="relative"
    >
      <motion.h1
        initial={{y: -20, opacity: 0}}
        animate={{y: 0, opacity: 1}}
        className="text-5xl font-black text-center mb-8 bg-gradient-to-r from-[#6145f7] to-[#8b75ff] bg-clip-text text-transparent"
      >
        Start Your Success Journey Today
      </motion.h1>

      <SocialProof />

      <div className="mb-20">
        <motion.div 
          className="col-span-4 text-2xl leading-relaxed text-gray-800 font-light text-center mb-8"
          initial={{x: -20}}
          animate={{x: 0}}
          transition={{duration: 0.8}}
        >
          Join thousands of successful entrepreneurs who have already transformed their business.
          <div className="text-lg text-gray-600 mt-4">
            30-day money-back guarantee • Cancel anytime • Instant access
          </div>
        </motion.div>
        <div className="flex justify-center">
          <CountDown date={lastDayOfMonth} renderer={renderer} />
        </div>
      </div>

      <motion.section 
        cols={plans?.data?.raw?.plans.length} 
        className="grid grid-cols-3 gap-10 relative mb-20 mt-20"
      >
        {sortedPlans.map((plan, index) => (
          <motion.div
            key={plan.id}
            initial={{y: 50, opacity: 0}}
            animate={{y: 0, opacity: 1}}
            transition={{delay: index * 0.2, type: "spring", stiffness: 100}}
            whileHover={{
              y: -20,
              scale: 1.02,
              transition: {type: "spring", stiffness: 400}
            }}
            onHoverStart={() => setHoveredPlan(plan.id)}
            onHoverEnd={() => setHoveredPlan(null)}
            className={plan.interval === 'Year' ? "z-10 transform scale-105" : ""}
          >
            <Card 
              shadow 
              rounded 
              className={`p-10 transition-all duration-500 backdrop-blur-sm ${
                plan.interval === 'Year'
                  ? 'bg-gradient-to-br from-[#6145f7]/10 via-white/95 to-[#6145f7]/5 border border-[#6145f7]/30 backdrop-blur-lg'
                  : 'bg-gradient-to-br from-white/90 to-[#6145f7]/5'
              } ${
                hoveredPlan === plan.id 
                  ? 'shadow-[0_20px_60px_-15px_rgba(97,69,247,0.3)] scale-105' 
                  : 'shadow-[0_10px_30px_-15px_rgba(97,69,247,0.2)]'
              }`}
            >
              {plan.interval === 'Year' && (
                <motion.div 
                  initial={{y: -10, opacity: 0}}
                  animate={{y: 0, opacity: 1}}
                  transition={{delay: 0.5}}
                  className="absolute -top-4 left-1/4 transform -translate-x-1/2"
                >
                  <span className="bg-gradient-to-r from-[#6145f7] to-[#8b75ff] text-white px-4 py-2 rounded-full text-sm font-bold shadow-lg">
                    <Icon image="star" size="16" className="inline mr-2" />
                    BEST VALUE (SAVE 50%)
                  </span>
                </motion.div>
              )}
              
              <motion.h2 
                className="text-xl font-black mb-6 flex items-center gap-3"
                animate={{scale: hoveredPlan === plan.id ? 1.05 : 1}}
                transition={{type: "spring", stiffness: 300}}
              >
                <Icon image={plan.price === 0 ? 'rocket' : plan.interval === 'Year' ? 'diamond' : 'star'} size="32" color="[#6145f7]" />
                <span className="bg-gradient-to-r from-[#6145f7] to-[#8b75ff] bg-clip-text text-transparent">
                  {plan.name}
                </span>
              </motion.h2>

              <div className="flex flex-col items-center mb-8">
                {plan.interval === 'Year' ? (
                  <>
                    <div className="flex items-center">
                      <span className="text-6xl font-black text-[#6145f7]">
                        {plan?.currency?.symbol}{Math.round(plan.price / 12)}
                      </span>
                      <div className="text-xl text-gray-600/80 ml-3 font-medium">
                        /month
                      </div>
                    </div>
                    <div className="text-sm text-gray-500 mt-2">
                      (billed annually at {plan?.currency?.symbol}{plan.price} - Save 50%)
                    </div>
                  </>
                ) : (
                  <>
                    <span className="text-6xl font-black text-[#6145f7]">
                      {plan?.currency?.symbol}{plan.price}
                    </span>
                    <span className="text-xl text-gray-600/80 ml-3 font-medium">
                      {plan.interval && '/'}{plan.interval}
                    </span>
                  </>
                )}
              </div>

              <div className="text-gray-400/70 line-through mb-8 text-xl">
                Regular price: {plan?.currency?.symbol}{plan.price * 2}
                {plan.interval && '/'}{plan.interval}
              </div>

              <CheckList 
                items={plan.features?.map(feature => ({
                  ...feature,
                  icon: 'check-circle',
                  iconColor: hoveredPlan === plan.id ? '[#6145f7]' : 'gray'
                }))}
                className="space-y-4 mb-10 text-gray-700 text-lg"
              />

              <Button
                text={
                  <>
                    <Icon image={plan.price === 0 ? 'rocket' : plan.interval === 'Year' ? 'diamond' : 'star'} size="20" className="inline mr-2" />
                    {plan.interval === 'Year' ? "Get 50% Off Now!" : "Start Free Trial"}
                  </>
                }
                color={plan.interval === 'Year' ? "[#6145f7]" : "[#8b75ff]"}
                fullWidth
                className={`mt-4 py-5 text-lg font-bold text-white uppercase transform transition-all duration-500 hover:scale-105 hover:shadow-2xl rounded-xl ${
                  plan.interval === 'Year'
                    ? 'bg-gradient-to-r from-[#6145f7] via-[#7055ff] to-[#8b75ff] shadow-[#6145f7]/20'
                    : 'bg-gradient-to-r from-[#8b75ff] via-[#9585ff] to-[#a594ff] shadow-[#8b75ff]/20'
                }`}
                action={() => {
                  navigate('/signup');
                  ReactGA.event('money', 'pricing click', plan.name);
                  tagManager.track('money', {plan: plan.name});
                }}
              />
              <div className="text-center mt-4 text-sm text-gray-500">
                30-day money-back guarantee
              </div>
            </Card>
          </motion.div>
        ))}
      </motion.section>

    </motion.div>
  );
}