import Canvas from 'components/Canvas/Canvas'
import { ViewContext } from 'components/lib'
import React from 'react'
import { useContext } from 'react'
import { CSSTransition } from 'react-transition-group'

const KickStartHypothsis = (props) => {
    const context = useContext(ViewContext)
    return (
        <CSSTransition in appear timeout={0} classNames="modal">
            <div className="modal" onClick={(e) => e.target === e.currentTarget && context.modal.hide(true)}>
                <div className="modal-content-canvas overflow-auto">
                    <div>
                        <Canvas callBack={props.callBack} data={props?.data} cancell={() => {
                            props.callBack('cancel')
                            context.modal.hide(true)
                        }} />
                    </div>
                </div>
            </div>
        </CSSTransition>
    )
}

export default KickStartHypothsis