import axios from 'axios';
import Wrapper from 'components/MyStepwizard';
import { Button, Grid, Icon, Loader, ViewContext, useLocation } from 'components/lib';
import Spacer from 'components/spacer/spacer';
import React, { useState } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';

const steps = [
    { id: 1, title: 'Business case', icon: 'briefcase' },
    { id: 2, title: 'Vectorize Documents', icon: 'file-text' },
    { id: 3, title: 'Questions', icon: 'help-circle' },
    { id: 4, title: 'Hypotheses', icon: 'align-justify' },
];

const video = {
    title: "Fearless derisking.",
    description: "Consciously realizing on which assumptions your business idea relying on is crucial.",
    video: "/video/course/deriskingvsbuilding.mp4",
    poster: 'video/course/fearlessderisking.png',
    free: true
}

const FocusComponent = () => {
    const context = useContext(ViewContext)
    const [currentStep, setCurrentStep] = useState(1);
    const [question, setQuestion] = useState('');
    const [questionError, setQuestionError] = useState('');
    const user = JSON.parse(localStorage.getItem('user'))
    const router = useLocation()
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [documents, setDocuments] = useState([])
    const [responnseSuccess, setResponnseSuccess] = useState(false)
    const [selectedDocumentIds, setSelectedDocumentIds] = useState();
    const [selectedBusinesscaseIds, setSelectedBusinesscaseIds] = useState();
    const [modal, setModal] = useState(false);
    const [modelResponse, setModelResponse] = useState('')
    const [businessCase, setBusinessCase] = useState([])

    const segments = [
        'hypothesis',
        'cases',
        'business-model',
        'singleproject',
        'draganddrop',
        'insight',
        'integration',
        'documents',
    ];

    const matchedSegment = segments.find((segment) => router.pathname.includes(segment)) || 'documents';
    const product_id = router.pathname.replace(`/${matchedSegment}/`, '');


    useEffect(() => {
        getBusinessCase()
        getDocuments()
    }, [])


    const handleDocumentClick = (document) => {
        setCurrentStep(3)
        toggleModal()
        setResponse('')
        setQuestionError('')
        setQuestion('')
        setSelectedDocumentIds(document?.id)

    };


    const getBusinessCase = async () => {
        setLoading(true)
        await axios({
            method: 'get',
            url: `/api/all_business_case/${product_id}`
        })
            .then((res) => {
                setLoading(false)
                if (res.data) {
                    setBusinessCase(res.data.data)
                }
            })
            .catch((err) => {
                setLoading(false)
                console.log(err)
            })
    }

    const getDocuments = async () => {
        if (product_id) {
            setLoading(true);
            try {
                const res = await axios.get(`/api/document/all/${product_id}?isVectorize=${true}`);
                if (res.data) {

                    if (res.data?.data?.length)
                        setDocuments(res.data?.data);
                }
            } catch (err) {
                console.error('Error fetching documents', err);
            } finally {
                setLoading(false);
            }
        }
    };


    const handleQuestionSubmit = async () => {
        setLoading(true);
        setResponnseSuccess(false)
        try {
            if (question?.trim()?.length < 3) {
                setQuestionError('Please type question first')
                return
            }
            if (!selectedDocumentIds) {
                alert('Please select document first')
            }
            setQuestionError('')
            setResponse('')
            const res = await axios.post('/api/document_query', {
                "user_id": user?.user_id,
                "doc_id": selectedDocumentIds,
                "team_id": product_id,
                "question": question,
                "k": 20
            }).then((res) => {
                if (res?.data?.data?.answer) {
                    setResponnseSuccess(true)
                }
                setResponse(res?.data?.data?.answer || 'No response received.');
                console.log('====res', res.data, res?.data?.data?.answer)
            });
            // const data = await res.json();

            console.log('====res', res)

        } catch (error) {
            console.error('Error fetching response:', error);
            setResponse('An error occurred while fetching the response.');
        } finally {
            setLoading(false);
        }
    };


    function toggleLoading() {
        setLoading(!loading);
    }

    function unLoading() {
        setLoading(false);
    }

    function toggleModal() {
        setModal(!modal);
    }

    function BusinessCanvasModel(value) {
        context.modal.show(
            {
                isFullPage: true,
                parent: 'kickStart',
                callBack: (e) => {
                    if (e == 'cancel') {
                        setCurrentStep(1)
                        context.modal.hide(true)
                    } else {
                        setCurrentStep(1)
                        context.modal.hide(true)
                        context.notification.show('Hypothesis added successfully', 'success', true)
                    }
                    // getAllHypothesis()
                },
                update: value ? value : undefined
            },
            () => {
                setCurrentStep(1)
                context.modal.hide(true)
                // getAllHypothesis()
            }
        )
    }

    return (
        <div className="flex flex-col md:flex-row  gap-6">
            {loading && <Loader />}
            {/* Sidebar */}
            {/* h-screen */}
            <Wrapper
                className='sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/3 2xl:w-1/4'
                case_id={selectedBusinesscaseIds}
                responseData={(e) => {
                    setModelResponse(e)
                    BusinessCanvasModel(e)
                    setCurrentStep(4)
                }}
                modal={modal} unLoading={unLoading} toggleLoading={toggleLoading}
                doc_id={selectedDocumentIds}
                close={(e) => {
                    if (e) {
                        setModelResponse(e)
                        setCurrentStep(4)
                        BusinessCanvasModel(e)
                        toggleModal()

                    } else {
                        toggleModal()
                        setCurrentStep(1)
                    }
                }}
                product_id={product_id} />

            <div className="bg-white md:w-1/4 p-4 relative border-2 border-gray-300">
                <h2 className="text-xl font-bold mb-6">Progress</h2>
                <ul className="space-y-8 relative">
                    {steps.map((step, index) => (
                        <li
                            key={step.id}
                            className="flex items-center cursor-pointer relative"
                            onClick={() => {
                                if (step.id == 4) {
                                    setCurrentStep(2)
                                    // BusinessCanvasModel()
                                } else {
                                    if (step.id == 3 && !selectedDocumentIds) {
                                        setCurrentStep(2)

                                    } else
                                        setCurrentStep(step.id)
                                }


                            }}
                        >
                            {index < steps.length - 1 && (
                                <div
                                    className={`absolute left-4 top-[32px] transform w-0.5 h-full ${currentStep >= steps[index + 1].id ? 'bg-brand-400' : 'bg-gray-300'
                                        }`}
                                ></div>
                            )}
                            <span
                                className={`h-8 w-8 flex items-center justify-center rounded-full mr-3 ${currentStep === step.id ? 'bg-brand-400 text-white' : 'bg-gray-300 text-gray-700'
                                    }`}
                            >
                                <Icon image={step.icon} color={'white'} className="h-[1rem] w-[1rem] m-1" alt='' />
                            </span>
                            <span className={currentStep === step.id ? 'text-gray-900 font-semibold' : 'text-gray-700'}>
                                {index + 1} . {step.title}
                            </span>
                        </li>
                    ))}
                </ul>
            </div>

            {currentStep == 1 ?
                <div className="flex-1 flex flex-col gap-2">
                    <div className="px-6 py-2 items-center border-2 border-[#CFD4DA] flex flex-col md:flex-row gap-4 shadow-[0_4px_9px_-4px_#b7beca]">
                        <div className="mb-4">
                            <h1 className="text-2xl font-bold mb-4 text-brand-400">Unleash Data-driven portfolio decisions.</h1>
                            <p className="mb-8 text-brand-400 opacity-70">
                                Upload product documents & establish actionable innovation metrics. Measure & visualize your impact,
                                derisk with smart experiments, make data-driven portfolio decisions & save millions. Invest like a venture
                                capitalist.
                            </p>
                        </div>

                        <div className="bg-brand-400 text-white rounded ">
                            <div className="flex items-center justify-center h-[10rem]">
                                <video
                                    id={video.title}
                                    className="rounded-md h-full object-cover  w-[484px]"
                                    src={video.video}
                                    poster={video.poster}
                                    controlsList="nodownload"
                                    playsInline
                                    controls
                                />
                            </div>
                        </div>
                    </div>

                    <Spacer height="h-[0.5rem]" />

                    <div className="flex-1 p-8 border-2 bg-white h-[600px]">
                        <h1 className='text-xl font-bold'>Select business case to proceed</h1>
                        <hr />
                        &nbsp;
                        &nbsp; &nbsp;
                        &nbsp;
                        <Grid cols="6">
                            <Grid className="bg-blue-600 text-white p-4 rounded-t-md">

                                <p className="text-xl font-bold">Case</p>
                                <p className="text-xl font-bold">Description</p>
                                <p className="text-xl font-bold">Cost structure</p>
                                <p className="text-xl font-bold">Date</p>
                                <p className="text-xl font-bold">Revenue Potential</p>
                                <p className="text-xl font-bold">Expected Return</p>
                                <p className="text-xl font-bold">Ebitda</p>
                                <p className="text-xl font-bold">Expected NPV</p>
                                <p className="text-xl font-bold">Planned capex</p>
                                <p className="text-xl font-bold"></p>
                                <p className="text-xl font-bold"></p>
                            </Grid>
                            <div className="flex" style={{
                                whiteSpace: 'nowrap',

                            }}>
                                {businessCase?.length ? businessCase?.map((e, index) => {
                                    const isEven = index % 2 === 0;
                                    return (
                                        <div className={`flex rounded p-3 w-[17.5rem] mr-4  hover:bg-gray-200 ${selectedBusinesscaseIds == e?.id ? 'border-derisky-rose bg-rose-100 text-derisky-rose ' : 'bg-white'}`} key={e.id}
                                            onClick={() => {
                                                setSelectedBusinesscaseIds(e.id)
                                                setCurrentStep(2)
                                            }}>
                                            <Grid className="p-4">
                                                <h1 className="text-xl text-black font-bold" title={e?.name}>{e?.name}</h1>
                                                <div className="description-container">
                                                    <p className="description text-black text-xs">
                                                        {e?.description}
                                                    </p>
                                                </div>
                                                <p className="text-md text-black font-normal">{parseFloat(e?.coststructure)?.toLocaleString()}</p>
                                                <p className="text-md text-black font-normal">{e?.date}</p>
                                                <p className="text-md text-black font-normal">{parseFloat(e?.potential)?.toLocaleString()}</p>
                                                <p className="text-md text-black font-normal">{parseFloat(e?.expectedReturn)?.toLocaleString()}</p>
                                                <p className="text-md text-black font-normal">{parseFloat(e?.ebitda)?.toLocaleString()}</p>
                                                <p className="text-md text-black font-normal">{parseFloat(e?.NPV)?.toLocaleString()}</p>
                                                <p className="text-md text-black font-normal">{parseFloat(e?.planned_cpex)?.toLocaleString()}</p>
                                                <p className="text-md text-black font-normal">{e?.risk ? parseFloat(e?.risk).toFixed(2) : 0} %</p>

                                                {e?.promising_case ? (
                                                    <p className="bg-gray-400 py-1 text-center uppercase text-sm rounded-md text-white flex items-center justify-center">
                                                        <div className='flex items-center'>
                                                            <Icon image='award' />
                                                            <span className="ml-2">Most promising</span>
                                                        </div>
                                                    </p>
                                                ) : (
                                                    <></>
                                                )}

                                            </Grid>
                                            {/* {product?.isTeamLead && <DropdownMenu onEdit={() => caseModal(e)} onDelete={() => deleteBusinessCaseModal(e.id)} editTitle={'Edit'} deleteTitle={'Delete'} parent={'cases'} />} */}

                                        </div>
                                    );
                                }) : <div>
                                    No business case found
                                </div>}
                            </div>
                        </Grid>
                    </div>
                </div> : currentStep === 2 ? <div className="flex-1 flex flex-col gap-2">
                    <div className="flex-1 p-8 border-2 bg-white h-[454px]">
                        <h1>Select documents to proceed</h1>
                        <hr />
                        &nbsp;
                        &nbsp; &nbsp;
                        &nbsp;

                        {documents?.length ?
                            documents.map((document, index) => (
                                <div
                                    className={`cursor-pointer flex items-center justify-between p-2 border-1 border-2 rounded-md transition duration-200 mb-2 shadow-sm hover:border-derisky-rose hover:bg-rose-100 hover:text-derisky-rose ${selectedDocumentIds == document?.id ? 'border-derisky-rose bg-rose-100 text-derisky-rose ' : 'bg-white'}`}
                                    key={index}
                                    onClick={() => handleDocumentClick(document)}
                                >
                                    <div className='flex items-center'>
                                        {selectedDocumentIds == (document?.id) ?
                                            <Icon image="check-square" size={20} className=' mr-3' /> :
                                            <Icon image="file" size={20} className=' mr-3' />
                                        }
                                        <h2 className='font-medium '>{document?.name ? document?.name : document?.aws_object.key}</h2>
                                        <a target='_blank' href={document?.aws_object?.Location} rel="noopener noreferrer">
                                            <Icon image="maximize" size={14} className=' ml-3' />
                                        </a>
                                    </div>

                                    <p className='text-gray-600'>{document?.description}</p>

                                </div>
                            )) : <div>
                                No document found, please vectorize document first.
                            </div>}
                    </div> </div> : currentStep === 4 ? <div className="flex-1 flex flex-col gap-2">

                        <div className="flex-1 p-8 border-2 bg-white h-[454px]"></div></div> : <></>


            }

            {currentStep === 3 && (
                <div className="flex-1 flex flex-col gap-2">
                    {/* <div className="flex-1 p-8 border-2 bg-white h-[454px]">
                        <h2 className="text-xl font-bold mb-4">Ask a Question</h2>
                        <div className="mb-4">
                            <textarea
                                className="w-full p-2 border-2 border-gray-300 rounded-md"
                                rows="5"
                                placeholder="Type your question here..."
                                value={question}
                                onChange={(e) => {
                                    setQuestionError('')
                                    setQuestion(e.target.value)
                                }}
                            />
                            {questionError ? <span className='text-red'>{questionError}</span> : ""}
                        </div>
                        <button
                            onClick={handleQuestionSubmit}
                            className="bg-brand-400 text-white px-4 py-2 rounded-md"
                            disabled={loading}
                        >
                            {loading ? 'Submitting...' : 'Submit Question'}
                        </button>
                        {response && (
                            <div className="mt-4 p-4 bg-gray-100 border-2 border-gray-300 rounded-md">
                                <h3 className="text-lg font-bold mb-2">Response:</h3>
                                <p>{response}</p>


                            </div>
                        )}

                        {response && responnseSuccess && <div className="mt-4">
                            <button
                                onClick={() => setCurrentStep(4)}
                                className="bg-brand-400 text-white px-4 py-2 rounded-md"
                                disabled={loading}
                            >
                                {'Proceed with this answer'}
                            </button>
                        </div>}
                    </div> */}
                </div>
            )}
        </div>
    );
};

export default FocusComponent;
